import React, { FC } from 'react'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Typography, TypographyVariant } from '@material-ui/core'

type Props = CheckboxProps & {
  permission: {
    id: string
    name: string
  }
  variant?: TypographyVariant
}

const PermissionItem: FC<Props> = ({ permission, variant, ...rest }) => {
  return (
    <FormControlLabel
      control={<Checkbox color="primary" {...rest} />}
      label={<Typography variant={variant}>{permission.name}</Typography>}
    />
  )
}

export default PermissionItem
