import { useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import React, { FC, useEffect } from 'react'
import BooleanStatusField from '../../components/BooleanStatusField'
import { QUERY_GET_PRODUCT_STOCK_AVAILABILITY } from '../../queries'

type Props = {
  salesPointId?: string | null
  productId?: string
}

const ProductStockAvailabilityField: FC<Props> = ({ salesPointId, productId }) => {
  const { loading, data, startPolling, stopPolling } = useQuery(QUERY_GET_PRODUCT_STOCK_AVAILABILITY, {
    variables: {
      salesPointId,
      productId,
    },
    skip: !productId,
  })

  useEffect(() => {
    startPolling(10000)

    return (): void => stopPolling()
  }, [])

  if (loading) {
    return <div>loading</div>
  }

  return (
    <Box display="inline-flex" alignItems="center" justifyContent="flex-start">
      {data?.productStocks?.data?.length &&
      data.productStocks.data.length > 0 &&
      data.productStocks.data[0] &&
      data.productStocks.data[0].stock ? (
        <>
          <BooleanStatusField
            value={data.productStocks.data[0].stock - (data.productReservationsCount || 0) > 0}
            trueValue={`${
              data.productStocks.data[0].stock - (data.productReservationsCount || 0)
            } pezzi disponibili in giacenza`}
            falseValue="Prodotto non disponibile"
          />

          {/*    <div
            style={{
              width: 8,
              height: 8,
              backgroundColor:
                data.productStocks.data[0].stock <= 0
                  ? 'gray'
                  : data.productStocks.data[0].stock < 5
                  ? 'orange'
                  : 'green',
            }}
          />
          {data.productStocks.data[0].stock <= 0
            ? 'Terminato'
            : `${data.productStocks.data[0].stock} pezzi disponibili in giacenza`}
          */}
        </>
      ) : (
        <>
          <BooleanStatusField
            value={false}
            trueValue="Prodotto non disponibile"
            falseValue="Prodotto non disponibile"
          />
        </>
      )}
    </Box>
  )
}

export default ProductStockAvailabilityField
