import React, { FC, useCallback, useMemo, useState } from 'react'
// import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { usePermissions } from 'ra-core'
import { hasPermission } from '../utils/hasPermission'
// import Dialog from '@material-ui/core/Dialog'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import DialogContent from '@material-ui/core/DialogContent'
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
// import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Button, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

type Props = {
  //
}

const DummyWarning: FC<Props> = () => {
  const classes = useStyles()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isClosed, setIsClosed] = useState<boolean>(true)

  const handleDialogClose = useCallback(() => setIsDialogOpen(false), [])
  const handleDialogOpen = useCallback(() => setIsDialogOpen(true), [])

  return /* && !hasPermission(permissions)*/ !isClosed ? (
    <>
      {/* <Alert
        className={classes.alert}
        variant="filled"
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={(): void => {
              setIsClosed(true)
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>
          Attenzione! Nella giornata di <strong>Giovedì 18 Novembre</strong> dalle ore: <strong>12:00</strong> alle ore:{' '}
          <strong>16:00</strong>, verrà effettuata una manutenzione straordinaria sulla piattaforma ToSco.
        </AlertTitle>
        In tale periodo la piattaforma potrebbe risultare non funzionante o non raggiungibile. <br />
        Nel caso la manutenzione venga completata correttamente prima delle 16:00, l'applicazione mostrerà un avviso
        verde indicando che è tornata operativa.
      </Alert> */}
      <Alert
        className={classes.alert}
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={(): void => {
              setIsClosed(true)
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>Attenzione! Operazioni sull'infrastruttura in corso.</AlertTitle>
        Sono in corso delle operazioni di manutenzione sull'infrastruttura di ToSco. Termine previsto: 5/10/2022 08:15.
      </Alert>

      {/* <Paper className={classes.dummyWarning} onClick={handleDialogOpen}>
        <Typography variant="h5">
          {/* {`Si ricorda che nelle prossime giornate potrete continuare a inserire nuove prenotazioni ma dovete
      consegnare i prodotti solo ai clienti che hanno ricevuto l’sms e il cui stato nella prenotazione è: “SMS
      Inviato”. La sezione delle giacenze sarà quindi non visibile.  `} */}
      {/* <Typography variant="h5" className={classes.underline}>
            Per ulteriori informazioni clicca qui.
          </Typography> */}
      {/* </Typography>
      </Paper>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          <Typography variant="h2">Altri due punti di attenzione:</Typography>
        </DialogTitle>
        <DialogContent>  */}
      {/* <Typography>
            Nelle prossime giornate, lo strumento non invierà gli SMS agli utenti ma permetterà di registrare le
            prenotazioni dei clienti che vengono a richiedere i prodotti.
          </Typography>
          <Typography>
            Lo strumento vi permetterà anche di consegnare i prodotti a quei clienti che si presenteranno se e solo se
            hanno ricevuto l’SMS e la riga corrispettiva nella prenotazione risulta in stato “SMS Inviato”.
          </Typography> */}
      {/* <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Se si dovesse verificare una rottura di stock per cui non avete il prodotto a negozio e la riga
                  risulta in “SMS inviato” vi chiediamo di aggiungere una nota nella prenotazione e di dire al cliente
                  che verrà informato quando il prodotto sarà disponibile. Per inserire la nota:
                  <div>
                    <ol>
                      <li>dalla lista prenotazioni cliccare sui tre puntini sulla destra</li>
                    </ol>
                  </div>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                Nel caso di arrivo di nuova merce da magazzino, visto il problema nell’invio degli SMS e dato che
                l’iniziativa si conclude nell’arco di pochi giorni,{' '}
                <Typography className={classes.bold}>
                  DOVETE ESPORLA TUTTA IN AREA VENDITA E NON APPARTARLA PER LE PRENOTAZIONE APERTE
                </Typography>{' '}
                (
                <Typography className={classes.underline}>
                  di conseguenza, non dovrete caricarla nelle giacenze
                </Typography>
                ).
              </ListItemText>
            </ListItem>
          </List> */}
      {/* <div>
            Nelle prossime giornate, lo strumento non invierà gli SMS agli utenti ma permetterà di registrare le
            prenotazioni dei clienti che vengono a richiedere i prodotti. Lo strumento vi permetterà anche di consegnare
            i prodotti a quei clienti che si presenteranno se e solo se hanno ricevuto l’SMS e la riga corrispettiva
            nella prenotazione risulta in stato “SMS Inviato”.
            <br />
            <ul>
              <li>
                Se si dovesse verificare una rottura di stock per cui non avete il prodotto a negozio e la riga risulta
                in “SMS inviato” vi chiediamo di aggiungere una nota nella prenotazione e di dire al cliente che verrà
                informato quando il prodotto sarà disponibile
              </li>
              <br />

              <ul>
                <li>Per inserire la nota:</li>
                <ol>
                  <li>dalla lista prenotazioni cliccare sui tre puntini sulla destra</li>
                  <li>cliccare: “Modifica”</li>
                  <li>
                    scrivere nel campo “note” presente nella sezione “Prenotazione”: “per la riga XY SMS da inviare”
                  </li>
                  <li>cliccare su: “Procedi”</li>
                  <li>cliccare su: “Salva”</li>
                </ol>
                <br />

                <li>
                  In questo modo avremo la possibilità di aggiornare da back salesPoint lo stato di quella riga e inviare
                  nuovamente l’sms agli utenti una volta che verranno aggiornate nuovamente le giacenze e gli sms
                  saranno tornali attivi.
                </li>
              </ul>
              <br />

              <li>
                Se il cliente dovesse aver ricevuto l’sms ma la riga della prenotazione risultasse in stato “in attesa”
                bisognerà informare il cliente che l’sms è stato ricevuto per errore e che verrà nuovamente informato
                appena il prodotto sarà disponibile.
              </li>
            </ul>
            <br />
            Vi ricordiamo di continuare a non spostare i prodotti che avete registrato come giacenze nello strumento.
            Devono essere infatti riservati alle prenotazioni registrate nel Tool Short Collection.
          </div>
        </DialogContent>
      </Dialog> */}
    </>
  ) : null
}

const useStyles = makeStyles((theme: any) => ({
  alert: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  dummyWarning: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  underline: {
    display: 'inline',
    textDecoration: 'underline',
  },
  bold: {
    display: 'inline',
    fontWeight: 'bold',
  },
}))

export default DummyWarning
