import React, {
  Component,
  createElement,
  useEffect,
  useRef,
  useState,
  ErrorInfo,
  ComponentType,
  HtmlHTMLAttributes,
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { createMuiTheme, withStyles, createStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { ThemeOptions } from '@material-ui/core'
import { ComponentPropType, CustomRoutes, DashboardComponent, usePermissions } from 'ra-core'
import compose from 'lodash/flowRight'

import DefaultAppBar from './AppBar'
import DefaultMenu from './Menu'
import DefaultSidebar from './Sidebar'
import {
  // Sidebar as DefaultSidebar,
  // Menu as DefaultMenu,
  Notification as DefaultNotification,
  Error as DefaultError,
} from 'react-admin'
import defaultTheme from '../../theme'
import GlobalStyles from '../GlobalStyles'
import ErrorBox from '../PageNotFound'
import DummyWarning from '../DummyWarning'
import { ShortCollectionWarning } from '../ShortCollectionWarning'

const styles = (theme: any): any =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100vh',
      overflow: 'hidden',
      width: '100%',
    },
    wrapper: {
      width: 'calc(100% - 50px)',
      // maxWidth: 'calc(100% - 50px)',
      overflowX: 'hidden',
      paddingBottom: theme.spacing(10),
    },
    '@media screen and (min-width: 1024px)': {
      wrapper: {
        paddingRight: theme.spacing(10),
      },
    },
    '@media print': {
      wrapper: {
        paddingTop: 0,
      },
    },
  })

const sanitizeRestProps: any = ({ staticContext, history, location, match, ...props }: RestProps) => props

class Layout extends Component<LayoutProps, LayoutState> {
  state: any = { hasError: false, errorMessage: null, errorInfo: null }

  constructor(props: any) {
    super(props)
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false })
      }
    })
  }

  componentDidCatch(errorMessage: any, errorInfo: any): void {
    this.setState({ hasError: true, errorMessage, errorInfo })
  }

  render(): any {
    const {
      appBar,
      children,
      classes,
      className,
      customRoutes,
      error,
      dashboard,
      logout,
      menu,
      notification,
      open,
      sidebar,
      title,
      ...props
    } = this.props
    const { hasError, errorMessage, errorInfo } = this.state
    return (
      <>
        <GlobalStyles />
        <div className={classnames('layout', classes.root, className)} {...sanitizeRestProps(props)}>
          {createElement(appBar as any, { title, open, logout })}
          {createElement(sidebar as any, {
            children: createElement(menu as any, {
              logout,
              hasDashboard: !!dashboard,
            }),
          })}
          <div className={classes.wrapper}>
            {hasError
              ? createElement(error as any, {
                  error: errorMessage,
                  errorInfo,
                  title,
                })
              : children}
          </div>
        </div>
        {createElement(notification as any)}
      </>
    )
  }

  static propTypes = {
    appBar: ComponentPropType,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    classes: PropTypes.object,
    className: PropTypes.string,
    customRoutes: PropTypes.array,
    dashboard: ComponentPropType,
    error: ComponentPropType,
    history: PropTypes.object.isRequired,
    logout: PropTypes.element,
    menu: ComponentPropType,
    notification: ComponentPropType,
    open: PropTypes.bool,
    sidebar: ComponentPropType,
    title: PropTypes.node.isRequired,
  }

  static defaultProps = {
    appBar: DefaultAppBar,
    error: ErrorBox,
    menu: DefaultMenu,
    notification: DefaultNotification,
    sidebar: DefaultSidebar,
  }
}

export interface LayoutProps extends RouteComponentProps, HtmlHTMLAttributes<HTMLDivElement> {
  className?: string
  classes?: any
  customRoutes?: CustomRoutes
  appBar?: ComponentType<{
    title?: string
    open?: boolean
    logout?: JSX.Element
  }>
  sidebar?: ComponentType<{ children: JSX.Element }>
  menu?: ComponentType<{ logout?: JSX.Element; hasDashboard?: boolean }>
  error?: ComponentType<{
    error?: string
    errorInfo?: React.ErrorInfo
    title?: string
  }>
  dashboard?: DashboardComponent
  notification?: ComponentType
  logout?: JSX.Element
  title?: string
  open?: boolean
}

export type RestProps = Omit<
  LayoutProps,
  | 'appBar'
  | 'children'
  | 'classes'
  | 'className'
  | 'customRoutes'
  | 'error'
  | 'dashboard'
  | 'logout'
  | 'menu'
  | 'notification'
  | 'open'
  | 'sidebar'
  | 'title'
>

export interface LayoutState {
  hasError: boolean
  errorMessage: string
  errorInfo: ErrorInfo
}

const mapStateToProps = (state: any): any => ({
  open: state.admin.ui.sidebarOpen,
})

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {} // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles, { name: 'RaLayout' })
)(Layout)

const LayoutWithTheme = ({ theme: themeOverride, ...props }: LayoutWithThemeProps): JSX.Element => {
  const themeProp = useRef(themeOverride)
  const [theme, setTheme] = useState(createMuiTheme(themeOverride))
  const { permissions } = usePermissions()

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride
      setTheme(createMuiTheme(themeOverride))
    }
  }, [themeOverride, themeProp, theme, setTheme])

  return (
    <ThemeProvider theme={theme}>
      {permissions?.params?.limitedToOwnSalesPoint && <ShortCollectionWarning />}
      <EnhancedLayout {...props} error={''} />
    </ThemeProvider>
  )
}

LayoutWithTheme.propTypes = {
  theme: PropTypes.object,
}

LayoutWithTheme.defaultProps = {
  theme: defaultTheme,
}

interface LayoutWithThemeProps extends LayoutProps {
  theme?: ThemeOptions
}

export default LayoutWithTheme
