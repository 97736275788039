import { gql } from 'apollo-boost'
import { client } from './apolloClient'
import { QUERY_GET_USER_ME } from './queries'

const getRoleParams = (userMe: any): any => {
  if (userMe && userMe.roles && userMe.roles.length > 0) {
    return userMe.roles[0].params
  }

  return {}
}

export default {
  login: (data: any): Promise<any> => {
    return client
      .mutate({
        mutation: gql`
          mutation Login($credentials: CredentialsInput!) {
            login(credentials: $credentials) {
              id
              firstName
              lastName
              email
            }
          }
        `,
        variables: {
          credentials: {
            email: data.username,
            password: data.password,
            rememberMe: true,
          },
        },
      })
      .then((data: any) => {
        localStorage.setItem('loggedIn', 'true')
        ;(window as any).location = '/'
      })

    // // console.log(result?.data?.login)
    // // // userManager.signinRedirect()
    // // // return result?.data?.login
    // // Promise.resolve()
    // return Promise.resolve()
  },

  logout: async (params: any): Promise<any> => {
    // const user = await userManager.getUser()
    // if (user) {
    //   // userManager.signoutRedirect()
    // }
    await client.mutate({
      mutation: gql`
        mutation Logout {
          logout
        }
      `,
    })

    localStorage.removeItem('loggedIn')

    return '/login'
  },

  checkError: (error: any): any => {
    console.log('checkError()', error)

    if (error.graphQLErrors) {
      error.graphQLErrors.forEach(({ message, locations, path, extensions }: any) => {
        if (extensions && (extensions.code === 'UNAUTHENTICATED' || extensions.code === 'FORBIDDEN')) {
          return Promise.reject()
          // userManager.signoutRedirect()
        }
      })
    }

    return Promise.resolve()
  },

  checkAuth: async (): Promise<any> => {
    return localStorage.getItem('loggedIn') ? Promise.resolve() : Promise.reject()
  },

  getIdentity: async (): Promise<any> => {
    try {
      const result = await client.query({
        query: QUERY_GET_USER_ME,
        fetchPolicy: 'no-cache',
      })
      if (result && result.data && result.data.userMe) {
        return Promise.resolve({ ...result.data.userMe, fullName: result.data.lastName })
      } else {
        return Promise.reject(new Error('User not found'))
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  getPermissions: async (): Promise<any> => {
    try {
      const result = await client.query({
        query: QUERY_GET_USER_ME,
        fetchPolicy: 'no-cache',
      })
      return result && result.data && result.data.userMe
        ? Promise.resolve({
            user: result.data.userMe,
            roles: result.data.userMe.roles || [],
            params: getRoleParams(result.data.userMe),
          })
        : Promise.reject({ params: {} })
    } catch (e) {
      Promise.reject({ params: {} })
    }
  },
}
