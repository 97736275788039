import React, { PureComponent } from 'react'
import JsPdf from 'jspdf'
import html2canvas from 'html2canvas'

type Props = {
  filename?: string
  x?: number
  y?: number
  options?: Record<string, any>
  scale?: number
  children: (data: any) => React.ReactNode
  onComplete?: () => void
  targetRef?: any
}

class ReactToPdf extends PureComponent<Props, any> {
  targetRef: any

  static defaultProps = {
    filename: 'download.pdf',
    options: undefined,
    x: 0,
    y: 0,
    scale: 1,
    onComplete: undefined,
    targetRef: undefined,
  }

  constructor(props: Props) {
    super(props)
    this.toPdf = this.toPdf.bind(this)
    this.targetRef = React.createRef()
  }

  async toPdf(): Promise<void> {
    const { targetRef, filename, x, y, options, onComplete } = this.props
    const source = targetRef || this.targetRef
    const targetComponent = source.current || source
    if (!targetComponent) {
      throw new Error('Target ref must be used or informed. See https://github.com/ivmarcos/react-to-pdf#usage.')
    }
    html2canvas(targetComponent, {
      logging: true,
      useCORS: true,
      allowTaint: true,
      scale: 1.5,
    }).then((canvas) => {
      const imgData = canvas.toDataURL()
      const pdf = new JsPdf(options)
      pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width / 5.72, canvas.height / 5.72)
      pdf.save(filename)
      if (onComplete) onComplete()
    })
  }

  render(): React.ReactNode {
    const { children } = this.props
    return (children as any)({ toPdf: this.toPdf, targetRef: this.targetRef })
  }
}

export default ReactToPdf
