import React, { FC, useEffect, useMemo, useState } from 'react'
import { useShowController } from 'ra-core/esm/controller/details/useShowController'
import { TextField, ReferenceField, ReferenceManyField } from 'react-admin'
import ShowView from '../../components/details/ShowView'
import Barcode from 'react-barcode'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Empty from '../../components/list/Empty'
import { Pagination } from '../../components/list/pagination'
import Datagrid from '../../components/Datagrid'
import moment from 'moment'
import ChipField from '../../components/ChipField'
import ProductReservationStatusFieldMenu from '../../components/ProductReservationStatusFieldMenu'
import { useDataProvider } from 'ra-core'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'
import ReservationPrint from './ReservationPrint'
import { FaFileAlt } from 'react-icons/fa'
import { useApolloClient } from '@apollo/client'
import { QUERY_GET_SHORT_COLLECTION_RULES_BY_PERIMETER } from '../../queries'
import { SingleMechanicField } from '../../components/SIngleMechanicField'
import { ContributionMechanicField } from '../../components/ContributionMechanicField'

type Props = {
  //
}

const ReservationShow: FC<Props> = (props) => {
  const showProps = useShowController(props)
  const dataProvider = useDataProvider()
  const client = useApolloClient()
  const [shortCollection, setShortCollection] = useState<Record<string, any>>({})

  useEffect(() => {
    async function getShortCollection(): Promise<void> {
      if (showProps && showProps.record) {
        const { data } = await dataProvider.getList('ShortCollection', {
          filter: { ids: [showProps.record.shortCollectionId] },
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
        })

        if (data && data.length > 0) {
          let newShortCollection = data[0]
          try {
            const { data: rulesData } = await client.query({
              query: QUERY_GET_SHORT_COLLECTION_RULES_BY_PERIMETER,
              variables: {
                shortCollectionId: newShortCollection.id,
                reservationId: showProps.record?.id,
              },
            })

            if (rulesData?.perimeterAwareShortCollectionRule) {
              newShortCollection = { ...newShortCollection, rules: rulesData.perimeterAwareShortCollectionRule }
            }
          } catch (error) {
            console.error('Error retrieving rules', error)
          }
          setShortCollection(newShortCollection)
        }
      }
    }

    if (Object.keys(shortCollection).length === 0) {
      getShortCollection()
    }
  }, [showProps])

  return (
    <ShowView
      {...showProps}
      {...props}
      additionalActions={
        showProps && showProps.record && Object.keys(shortCollection).length > 0 ? (
          <ReservationPrint {...showProps} shortCollection={shortCollection} />
        ) : undefined
      }
      titleBar={
        <>
          <ReferenceField {...showProps} {...props} source="salesPointId" reference="SalesPoint" link={false}>
            <ChipField source="name" />
          </ReferenceField>
          <ReferenceField {...showProps} {...props} source="shortCollectionId" reference="ShortCollection" link={false}>
            <ChipField source="name" color="primary" />
          </ReferenceField>
        </>
      }
    >
      <>
        <Box display="flex" position="relative">
          <ReservationShowCard {...showProps} />
          {showProps.loaded && Object.keys(shortCollection).length > 0 && (
            <ReservationTable {...showProps} shortCollection={shortCollection} />
          )}
        </Box>
      </>
    </ShowView>
  )
}

const ReservationShowCard: FC<any> = (props): any => {
  const { record } = props
  const classes = useStyles()

  return (
    <Box pr={3}>
      <InfoCardWrapper>
        <InfoCard>
          <InfoCardItem flexDirection="row" padding={0} className={classes.barcode}>
            <Barcode value={'r' + record.id} displayValue={false} height={50} width={1} />
          </InfoCardItem>
        </InfoCard>
        <Box mb={2} />

        <InfoCard>
          <InfoCardItem direction="row" alignItems="flex-start">
            <InfoCardAvatar>
              <FaFileAlt size={20} />
            </InfoCardAvatar>
            <Box display="flex" flexDirection="column" justifySelf="flex-start">
              <InfoCardTypography variant="title">{`${record.clientLastName} ${record.clientFirstName}`}</InfoCardTypography>
              {record.clientEan && <InfoCardTypography variant="label">EAN: {record.clientEan}</InfoCardTypography>}
            </Box>
          </InfoCardItem>

          <InfoCardItem>
            <InfoCardTypography variant="label">Email:</InfoCardTypography>
            <InfoCardTypography variant="value">{record.clientEmail}</InfoCardTypography>
          </InfoCardItem>
          <InfoCardItem>
            <InfoCardTypography variant="label">Telefono Cellulare:</InfoCardTypography>
            <InfoCardTypography variant="value">{record.clientPhone}</InfoCardTypography>
          </InfoCardItem>

          <InfoCardItem>
            <InfoCardTypography variant="label">Data Creazione:</InfoCardTypography>
            <InfoCardTypography variant="value"> {moment(record.createdAt).format('L LT')}</InfoCardTypography>
          </InfoCardItem>
        </InfoCard>
        <Box mb={2} />

        <InfoCard>
          <InfoCardItem>
            <InfoCardTypography variant="label">Note:</InfoCardTypography>
            <InfoCardTypography variant="caption">{record.note || 'Nessuna nota presente'}</InfoCardTypography>
          </InfoCardItem>
        </InfoCard>
      </InfoCardWrapper>
    </Box>
  )
}

const ReservationTable: FC<any> = (props) => {
  const classes = useStyles()
  const rules = useMemo(() => props.shortCollection?.rules || {}, [props.shortCollection?.rules])

  return (
    <Box className={classes.customerReservations}>
      <ReferenceManyField
        {...props}
        pagination={<Pagination />}
        record={props.record}
        reference="ProductReservation"
        target="reservationId"
      >
        <Datagrid emptyComponent={<Empty buttonComponent={<></>} />}>
          <TextField source="id" label="ID Riga" />
          <ReferenceField {...props} source="productId" reference="Product" link={false} label="Nome">
            <TextField source="name" />
          </ReferenceField>

          {/** Bollini */}
          {rules.stamps && (
            <ReferenceField {...props} source="productId" reference="Product" link={false} label="Mecc. Bollini">
              <SingleMechanicField source="numStampsStamps" />
            </ReferenceField>
          )}

          {/** Bollini + Contributo */}
          {rules.stampsContribution && (
            <ReferenceField {...props} source="productId" reference="Product" link={false} label="Mecc. Bol + Contr.">
              <ContributionMechanicField
                stampsQuantitySource="numStampsStampsContribution"
                contributionSource="contributionStampsContribution"
              />
            </ReferenceField>
          )}

          {/** Punti */}
          {rules.points && (
            <ReferenceField {...props} source="productId" reference="Product" link={false} label="Mecc. Pt">
              <SingleMechanicField appendix="pt" source="numPointsPoints" />
            </ReferenceField>
          )}

          {/** Punti + Contributo */}
          {rules.pointsContribution && (
            <ReferenceField {...props} source="productId" reference="Product" link={false} label="Mecc. Pt + Contrib.">
              <ContributionMechanicField
                pointsQuantitySource="numPointsPointsContribution"
                contributionSource="contributionPointsContribution"
              />
            </ReferenceField>
          )}

          {/** Contributo */}
          {rules.contribution && (
            <ReferenceField {...props} source="productId" reference="Product" link={false} label="Mecc. Contr.">
              <SingleMechanicField source="contributionContribution" type="currency" appendix="€" />
            </ReferenceField>
          )}

          {rules.stampsPointsContribution && (
            <ReferenceField
              {...props}
              source="productId"
              reference="Product"
              link={false}
              label="Mecc. Bol + Pt + Contrib."
            >
              <ContributionMechanicField
                stampsQuantitySource="numStampsStampsPointsContribution"
                pointsQuantitySource="numPointsStampsPointsContribution"
                contributionSource="contributionStampsPointsContribution"
              />
            </ReferenceField>
          )}

          <ProductReservationStatusFieldMenu {...props} />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  customerReservations: {
    // backgroundColor: theme.palette.background.paper,
    alignSelf: 'flex-start',
    width: 'calc(100% - 315px)',
    maxWidth: 'calc(100% - 315px)',
  },
  stickyColumn: {
    position: 'sticky',
    top: theme.spacing(3),
  },
  barcode: {
    position: 'relative',
    '& > svg': {
      backgroundColor: 'transparent',
      padding: 0,
      overflow: 'hidden',
    },
    '& > svg > rect': {
      fill: 'transparent !important',
    },
    '& > svg > g': {},
  },
}))

export default ReservationShow
