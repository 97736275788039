import { useCallback, useMemo, useState } from 'react'
import Fuse from 'fuse.js'

function fuzzySearch<TData>({
  fuse,
  data,
  term,
}: {
  fuse: Fuse<TData>
  data: TData[]
  term: string
}): Fuse.FuseResult<TData>[] {
  const result = fuse.search(`${term}`)
  return term
    ? result
    : data.map((item) => ({
        item,
        refIndex: 0,
      }))
}

/**
 *
 * @param {*} param0
 *
 * A custom React Hook to do a in-memory fuzzy text search
 * using Fuse.js.
 */
function useFuse<TData>({
  data,
  options,
}: {
  data: readonly TData[]
  options: Fuse.IFuseOptions<unknown> | undefined
}): {
  term: string
  search: (term: string) => void
  result: Fuse.FuseResult<TData>[]
  reset: () => void
} {
  const [term, setTerm] = useState('')

  const fuseOptions = useMemo(
    () => ({
      threshold: 0.2,
      ...(options || {}),
    }),
    [options]
  )

  const fuse = useMemo(() => new Fuse<TData>(data, fuseOptions), [data, fuseOptions])

  const result = useMemo(() => fuzzySearch({ data: data as any, term, fuse }), [data, term, fuse])

  const reset = useCallback(() => {
    setTerm('')
  }, [])

  return { result, search: setTerm, term, reset }
}

export default useFuse
