import { gql } from '@apollo/client'
import buildGraphQLProvider, { buildQuery } from 'ra-hyperting-graphql'

export const myBuildQuery =
  (introspection: any) =>
  (fetchType: any, resource: any, params: any): any => {
    const builtQuery = buildQuery(introspection)(fetchType, resource, params)

    if (resource === 'ProductReservation' && fetchType === 'GET_LIST') {
      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
          query CustomProductReservationsList(
            $filters: ProductReservationFilterInput
            $sort: ProductReservationSortInput
            $pagination: PaginationInputType
          ) {
            items: productReservations(filters: $filters, sort: $sort, pagination: $pagination) {
              offset
              total
              isPaginated
              data {
                id
                reservationId
                productId
                productReservationStatus
                product {
                  id
                  name
                }
                reservation {
                  id
                  clientFirstName
                  clientLastName
                  clientPhone
                  shortCollection {
                    id
                    name
                  }
                  createdAt
                }
                createdAt
                updatedAt
              }
            }
          }
        `,
      }
    }

    return builtQuery
  }
