import { makeStyles } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import { required, useDataProvider, useInput } from 'ra-core'
import React, { FC, useCallback, useState } from 'react'
import FormTextInput from '../../components/FormTextInput'
import SearchIcon from '@material-ui/icons/Search'
import { useFormState, useForm } from 'react-final-form'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import BarcodeReader from 'react-barcode-reader'
import * as Sentry from '@sentry/browser'
import { MaskedInput } from '../../components/MaskedInput'
import { DEFAULT_MASK_RULES } from 'react-hook-mask'

// const maskGenerator = createDefaultMaskGenerator('999 999 9999')

const maskGenerator = {
  rules: DEFAULT_MASK_RULES,
  generateMask: (value: string): string => {
    return value?.startsWith('+') ? '+99 999 999 99999' : '999 999 99999'
  },
  transform: (value: string): string => {
    return value
  },
}

// const maskGenerator = createDynamicNumberMaskGenerator('999 999 99999', '+99 999 999 99999')

const useStyles = makeStyles((theme) => ({
  root: {},
  reservationCustomerNewCustomerLabel: {
    marginBottom: theme.spacing(3),
  },
  inputWrapper: {
    width: '50%',
    alignSelf: 'stretch',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  helper: {
    width: '50%',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
  },
  helperSuccess: {
    width: '50%',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
    color: 'green',
  },
  helperFailure: {
    width: '50%',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
    color: 'red',
  },
  input: {
    width: '100%',
  },
}))

type Props = {
  record?: any
}

const ReservationCustomerFormSection: FC<Props> = (props) => {
  const classes = useStyles(props)
  const form = useForm()
  const { values } = useFormState()
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [isMatch, setIsMatch] = useState<boolean>(false)
  const [matchedEan, setMatchedEan] = useState<string>('')

  const handleScan = useCallback(
    (data: string) => {
      if (data) {
        if (data.length === 12) {
          form.change('ean', `0${data}`)
          getCustomerByEan(`0${data}`)
        } else {
          form.change('ean', data)
          getCustomerByEan(data)
        }
      }
    },
    [form]
  )

  const handleScanError = (error: any): void => console.log('scan error: ', error)

  const handleEanChange = useCallback(
    (event) => {
      const id = event.target.value
      if (isMatch && id !== matchedEan) {
        setLoaded(false)
        setIsMatch(false)
        resetForm()
      }
      if (!id || id.length !== 13) return
      getCustomerByEan(id)
    },
    [matchedEan, isMatch, values?.ean]
  )

  const getCustomerByEan = useCallback(
    async (id: string): Promise<any> => {
      try {
        setLoaded(false)
        setLoading(true)
        const result = await dataProvider.getList('CoopCustomer', {
          filter: { id },
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: {
            field: 'id',
            order: 'ASC',
          },
        })
        if (result.data && result.data.length > 0) {
          setIsMatch(true)
          setMatchedEan(id)
          form.change('clientFirstName', result.data[0].firstName)
          form.change('clientLastName', result.data[0].lastName)
          form.change('clientEmail', result.data[0].email)
          const newPhoneNumber =
            result?.data?.[0]?.phone && !result?.data?.[0]?.phone?.startsWith('+39')
              ? `+39${result?.data?.[0]?.phone}`
              : result?.data?.[0]?.phone
              ? result?.data?.[0]?.phone
              : '+39'

          form.change('clientPhone', newPhoneNumber as string)

          Sentry.captureEvent({
            message: 'Customer found by EAN',
            extra: {
              ean: id,
              customer: result.data[0],
              newPhoneNumber,
              originalPhoneNumber: result?.data?.[0]?.phone,
            },
          })
        } else {
          setIsMatch(false)
          resetForm()
        }
      } catch (err) {
        console.log('getCustomerByEan error: ', err)
      } finally {
        setLoaded(true)
        setLoading(false)
      }
    },
    [form]
  )

  const resetForm = useCallback(() => {
    form.change('clientFirstName', '')
    form.change('clientLastName', '')
    form.change('clientEmail', '')
    form.change('clientPhone', '')
  }, [])

  return (
    <>
      <BarcodeReader onScan={handleScan} onError={handleScanError} />
      <EanInput
        onChange={handleEanChange}
        loading={loading}
        id="ean"
        name="ean"
        helperText="Inserisci o scannerizza EAN del cliente"
        success={loaded ? (isMatch ? true : false) : undefined}
        defaultValue="0"
      />
      <Typography className={classes.reservationCustomerNewCustomerLabel} variant="body2">
        Oppure inserisci dati del nuovo cliente
      </Typography>
      <FormTextInput
        {...props}
        label="resources.Customer.fields.firstName"
        source="clientFirstName"
        autoComplete="off"
        validate={required()}
        helperText="Inserisci nome cliente destinatario"
        disabled={isMatch}
      />
      <FormTextInput
        {...props}
        label="resources.Customer.fields.lastName"
        source="clientLastName"
        autoComplete="off"
        validate={required()}
        helperText="Inserisci cognome cliente destinatario"
        disabled={isMatch}
      />
      <FormTextInput
        {...props}
        label="resources.Customer.fields.email"
        source="clientEmail"
        autoComplete="off"
        helperText="Inserisci email cliente destinatario"
      />

      {/* <FormTextInput
        {...props}
        label="resources.Customer.fields.phone"
        source="clientPhone"
        autoComplete="off"
        inputProps={{
          pattern: '[0-9]*',
        }}
        helperText="Inserisci email cliente destinatario"
      /> */}

      {/* <FormPhoneNumberInput
        source="clientPhone"
        helperText="Inserisci numero di cellulare compreso di prefisso internazionale (esempio: +39 333 1234567)"
      /> */}
      <MaskedInput
        maskGenerator={maskGenerator}
        source="clientPhone"
        helperText="Inserisci numero di cellulare compreso di prefisso internazionale (esempio: +39 333 1234567)"
      />
    </>
  )
}

const EanInput: FC<any> = (props) => {
  const {
    input: { name, onChange, ...rest },
  } = useInput(props)
  const classes = useStyles()
  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputWrapper}>
        <TextField
          // {...props}
          name={name}
          onChange={onChange}
          helperText={undefined}
          variant="outlined"
          margin="dense"
          className={classes.input}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {props.loading ? <CircularProgress size={20} /> : <SearchIcon />}
              </InputAdornment>
            ),
          }}
          {...rest}
        />
      </div>
      {props.success !== undefined ? (
        <Typography className={props.success ? classes.helperSuccess : classes.helperFailure} variant="body2">
          {props.success ? 'Codice EAN riconosciuto' : 'Nessun risultato trovato'}
        </Typography>
      ) : (
        <Typography className={classes.helper} variant="body2">
          {props.helperText}
        </Typography>
      )}
    </div>
  )
}

export default ReservationCustomerFormSection
