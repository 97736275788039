import { ToggleButton } from '@material-ui/lab'
import React, { FC } from 'react'
import { useField } from 'react-final-form'

type Props = {
  source: string
  value: any
}

export const ToggleButtonInput: FC<Props> = ({ source, children, value }) => {
  const { input, meta } = useField(source)

  return (
    <ToggleButton
      onChange={(): void => {
        input.onChange(!input.value)
      }}
      value={Boolean(value)}
      selected={Boolean(input.value)}
    >
      {children}
    </ToggleButton>
  )
}
