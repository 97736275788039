import React, { FC, useCallback, useState } from 'react'
import { FormWithRedirect, useGetOne, useNotify, useRedirect, useRefresh } from 'ra-core'
import SaveButton from '../../components/button/SaveButton'
import QuantityChooserInput from '../../components/QuantityChooserInput'
import Loading from '../../components/Loading'
import Typography from '@material-ui/core/Typography'
import { useMutation } from '@apollo/client'
import { MUTATION_UPDATE_PRODUCT_STOCK } from '../../queries'
import { Box } from '@material-ui/core'
import { ReferenceInput } from 'react-admin'
import { SelectInput } from 'react-admin'
import ProductStockEditConfrimModal from './ProductStockEditConfrimModal'

type Props = {
  [x: string]: any
}

const ProductStockEdit: FC<Props> = ({ id, ...props }) => {
  const [updateProductStock, { loading: saving, data: updateStockResult, error: updateError }] = useMutation(
    MUTATION_UPDATE_PRODUCT_STOCK
  )
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const { loading, loaded, error, data } = useGetOne('ProductStock', id)

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const result = await updateProductStock({
          variables: {
            data: {
              salesPointId: values.salesPointId,
              productId: values.productId,
              shortCollectionId: values.shortCollectionId,
              quantity: parseInt(values.stock),
            },
          },
        })
        refresh()
        redirect('/ProductStock')
        notify('Giacenza aggiornata correttamente')
      } catch (e) {
        notify('ra.message.error', 'warning')
      }
    },
    [data]
  )

  const handleShowConfirmModal = useCallback(() => {
    setShowConfirm(true)
  }, [])

  const handleCloseModal = useCallback((): void => {
    setShowConfirm(false)
  }, [])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <Typography color="error">Si è verificato un errore</Typography>
  }

  return (
    <>
      <FormWithRedirect
        {...props}
        record={data}
        resource="ProductStock"
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, pristine, saving }: any): any => (
          <>
            <Box display="flex" flexDirection="column">
              <ReferenceInput
                {...props}
                record={data}
                resource="ProductStock"
                source="productId"
                reference="Product"
                link={false}
              >
                <SelectInput optionText="name" variant="outlined" disabled />
              </ReferenceInput>
              <ReferenceInput
                {...props}
                record={data}
                resource="ProductStock"
                source="salesPointId"
                reference="SalesPoint"
                link={false}
              >
                <SelectInput optionText="name" variant="outlined" disabled />
              </ReferenceInput>
              <ReferenceInput
                {...props}
                record={data}
                resource="ProductStock"
                source="shortCollectionId"
                reference="ShortCollection"
                label="Short Collection"
                link={false}
              >
                <SelectInput optionText="name" variant="outlined" disabled />
              </ReferenceInput>
              <QuantityChooserInput source="stock" />
              <Box mt={3}>
                <SaveButton
                  size="large"
                  handleSubmitWithRedirect={handleShowConfirmModal}
                  pristine={pristine}
                  saving={saving}
                  disabled={pristine}
                />
              </Box>
            </Box>
            <ProductStockEditConfrimModal
              open={showConfirm}
              record={data}
              onClose={handleCloseModal}
              onConfirm={handleSubmitWithRedirect}
            />
          </>
        )}
      />
    </>
  )
}

export default ProductStockEdit
