import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

type Props = {
  //
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 18,
    fontWeight: 'normal',
    marginTop: theme.spacing(1),
  },
}))

const TitleBarSubtitle: FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <Typography color="textSecondary" variant="h3" className={classes.root}>
      {props.children}
    </Typography>
  )
}

export default TitleBarSubtitle
