import React, { FC, useCallback, useState } from 'react'
import RAList from '../../components/list/List'
import { TextField, ReferenceField, ReferenceInput, downloadCSV } from 'react-admin'

import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import { FaBox } from 'react-icons/fa'
import { FunctionField } from 'react-admin'
import { makeStyles, Typography } from '@material-ui/core'
import ProductStockEdit from './ProductStockEdit'
import { Route, useHistory } from 'react-router-dom'
import Drawer from '../../components/Drawer'
import DrawerHeader from '../../components/DrawerHeader'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import Filter from '../../components/list/filter/Filter'
import SearchAutoCompleteInput from '../../components/SearchAutoCompleteInput'
import { useDataProvider, useNotify, usePermissions } from 'ra-core'
import { hasPermission } from '../../utils/hasPermission'
import AlreadyAssignedProductsField from '../../components/AlreadyAssignedProductsField'
import jsonExport from 'jsonexport/dist'
import ExportButton from '../../components/button/ExportButtont'
import { QUERY_ALREADY_ASSIGNED_PRODUCTS_TOTAL, QUERY_GET_PRODUCTSTOCKGROUPED_CSV_TOTAL } from '../../queries'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import LinearProgress from '@material-ui/core/LinearProgress'
import { parse } from 'query-string'
import { useApolloClient } from '@apollo/client'

type Props = {
  [x: string]: any
}

const limit = 200

const autocompleteQuery = (name: string): Record<string, any> => ({ name })
const sort = { field: 'name', order: 'ASC' }

const ProductStockFilters: FC<any> = (props) => {
  const { loaded, permissions } = usePermissions()

  return (
    <Filter {...props}>
      <ReferenceInput
        label="Prodotto"
        source="productId"
        reference="Product"
        link={false}
        alwaysOn
        filterToQuery={autocompleteQuery}
        sort={sort}
      >
        <SearchAutoCompleteInput />

        {/* <AutocompleteInputInDrawer optionText={'name'} fullWidth /> */}
      </ReferenceInput>
      <ReferenceInput
        label="Short Collection"
        source="shortCollectionId"
        reference="ShortCollection"
        link={false}
        filterToQuery={autocompleteQuery}
        sort={sort}
      >
        <AutocompleteInputInDrawer optionText={'name'} fullWidth />
      </ReferenceInput>
      {loaded && hasPermission(permissions) && (
        <ReferenceInput
          sort={sort}
          filterToQuery={autocompleteQuery}
          label="Punto vendita"
          source="salesPointId"
          reference="SalesPoint"
          fullWidth
        >
          <AutocompleteInputInDrawer optionText={'name'} fullWidth />
        </ReferenceInput>
      )}
    </Filter>
  )
}

const ProductStockList: FC<Props> = (props) => {
  const classes = useStyles(props)
  const history = useHistory()
  const client = useApolloClient()
  const { filter } = parse(props.location.search)
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [totalRows, setTotalRows] = useState<number>(0)
  // const [progress, setProgress] = useState<number>(0)

  const handleDrawerClose = useCallback(() => {
    history.push('/ProductStock')
  }, [])

  const handleDialogClose = useCallback(() => {
    if (!exportLoading) setIsDialogOpen(false)
  }, [exportLoading])

  const exporter = useCallback(async (): Promise<any> => {
    setIsDialogOpen(true)
    try {
      setExportLoading(true)

      // console.log('filter before: ', filter, typeof filter)

      const filters = filter === null || !filter || typeof filter === 'object' ? undefined : JSON.parse(filter)

      // console.log('filters after: ', filters)

      const variables =
        !filters || filters === null
          ? {
              pagination: {
                limit: 1,
                offset: 0,
              },
            }
          : {
              pagination: {
                limit: 1,
                offset: 0,
              },
              filters,
            }

      const { data: total } = await client.query({
        query: QUERY_GET_PRODUCTSTOCKGROUPED_CSV_TOTAL,
        variables,
        // variables: {
        //   pagination: {
        //     limit: 1,
        //     offset: 0,
        //   },
        //   filters,
        // },
      })

      if (!total || !total.productStocks || !total.productStocks.total) {
        throw new Error('Cannot get number of total results')
      }

      const realTotal = total.productStocks.total
      setTotalRows(realTotal)
      const totalPages = Math.ceil(realTotal / limit)
      let realResults: any[] = []

      for (let i = 1; i <= totalPages; i++) {
        const queryVar =
          !filters || filters === null
            ? {
                pagination: {
                  page: i,
                  perPage: limit,
                },
                sort: {
                  field: 'id',
                  order: 'ASC',
                },
                filter: {},
              }
            : {
                pagination: {
                  page: i,
                  perPage: limit,
                },
                sort: {
                  field: 'id',
                  order: 'ASC',
                },
                filter: filters,
              }

        // const { data } = await dataProvider.getList('ProductStock', {
        //   pagination: {
        //     page: i,
        //     perPage: limit,
        //   },
        //   sort: {
        //     field: 'id',
        //     order: 'ASC',
        //   },
        //   filter: filters,
        // })

        const { data } = await dataProvider.getList('ProductStock', queryVar)

        const salesPointsIds = data.map((item: any) => item.salesPointId)
        const productsIds = data.map((item: any) => item.productId)
        const shortCollectionIds = data.map((item: any) => item.shortCollectionId)

        const { data: salesPointsArr } = await dataProvider.getMany('SalesPoint', {
          ids: salesPointsIds,
        })
        const salesPoints: Record<string, any> = {}
        salesPointsArr.map((salesPoint: any) => (salesPoints[salesPoint.id] = salesPoint))

        const { data: productsArr } = await dataProvider.getMany('Product', {
          ids: productsIds,
        })
        const products: Record<string, any> = {}
        productsArr.map((prod: any) => (products[prod.id] = prod))

        const { data: categoriesArr } = await dataProvider.getMany('ShortCollection', {
          ids: shortCollectionIds,
        })

        const categories: Record<string, any> = categoriesArr.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: item,
          }
        }, {})

        console.log('categories: ', categories)
        const semiFinalData = data.map((record: any) => ({
          id: record.id,
          salesPointId: record.salesPointId,
          productId: record.productId,
          'Punto Vendita': salesPoints[record.salesPointId]?.name,
          Prodotto: products[record.productId]?.name,
          'Short Collection': categories[record.shortCollectionId]?.name,
          Stock: record.stock,
        }))

        const newArr = realResults.concat(semiFinalData)
        realResults = [...newArr]
      }

      for (let i = 0; i < realResults.length; i++) {
        const { data } = await client.query({
          query: QUERY_ALREADY_ASSIGNED_PRODUCTS_TOTAL,
          variables: {
            filters: {
              salesPointId: realResults[i].salesPointId,
              productId: realResults[i].productId,
              productReservationStatus: ['CUSTOMER_NOTIFIED', 'WAITING_FOR_AVAILABLE_NOTIFICATION'],
            },
          },
          fetchPolicy: 'network-only',
        })
        realResults[i]['Già assegnati'] = `${data.productReservationsCount}`
      }

      const finalData = realResults.map((record: any) => {
        delete record.salesPointId
        delete record.productId
        return record
      })

      jsonExport(finalData, { rowDelimiter: ';' }, (err: any, csv: any) => {
        if (err) throw new Error(err)
        downloadCSV(csv, 'giacenze')
      })
    } catch (error) {
      console.log('Export error: ', error)
      notify('Errore nella generazione del file CSV', 'error')
    } finally {
      setExportLoading(false)
      setIsDialogOpen(false)
      // setProgress(0)
    }
  }, [filter])

  return (
    <>
      <RAList
        {...props}
        title="Giacenze"
        titleIcon={<FaBox />}
        actions={
          <div>
            <CreateButton basePath={props.basePath} label="Nuova Consegna" />
            <ExportButton {...props} className={classes.export} loading={exportLoading} onClick={exporter} />
          </div>
        }
        filters={<ProductStockFilters variant="outlined" />}
        bulkActionButtons={false}
      >
        <Datagrid rowClick="edit">
          {/* <FunctionField
            resource="Product"
            source="galleryId"
            render={(record: any): React.ReactNode => (
              <ProductGalleryField {...record} resource="Product" source="galleryId" />
            )}
          /> */}

          <ReferenceField label="Prodotto" source="productId" reference="Product" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Collection" source="shortCollectionId" reference="ShortCollection" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Punto Vendita" source="salesPointId" reference="SalesPoint" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            source="stock"
            render={(record: any): React.ReactNode => (
              <Typography style={{ fontWeight: 'bold' }} variant="body2">{`${record.stock} pezzi`}</Typography>
            )}
          />
          <AlreadyAssignedProductsField label="Già assegnati" />
          <GenericMoreMenu disableDelete />
        </Datagrid>
      </RAList>

      <Route path="/ProductStock/:id">
        {({ match }: any): React.ReactNode => {
          const isMatch = match && match.params && match.params.id !== 'create'

          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {isMatch ? (
                <>
                  <DrawerHeader buttonOnClick={handleDrawerClose} title="Modifica Giacenza" />
                  <ProductStockEdit
                    id={isMatch && match && match.params ? match.params.id : undefined}
                    onCancel={handleDrawerClose}
                    {...props}
                  />
                </>
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          )
        }}
      </Route>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant="h2">
            {`Generazione CSV in corso ${totalRows > 0 ? `(${totalRows} righe)` : ''}`}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Esportazione in corso, non chiudere o ricaricare la finestra del browser
          </DialogContentText>
        </DialogContent>
        <LinearProgress />
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme: any) => ({
  export: {
    marginLeft: theme.spacing(3),
  },
  dialogContent: {
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  dialogTitle: {
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  drawerContent: {
    width: '26vw',
    minWidth: 260,
    // eslint-disable-next-line quotes
    content: "''",
  },
}))

export default ProductStockList
