import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button, Drawer, makeStyles, Typography } from '@material-ui/core'
import BaseButton from '../../../components/button/BaseButton'
import { CoopAreaSelectabeListInput } from './CoopAreaSelectabeListInput'
import { useFormState, useForm } from 'react-final-form'
import get from 'lodash/get'
import { CoopZoneSelectabeListInput } from './CoopZoneSelectabeListInput'
import { CoopSalesPointSelectabeListInput } from './CoopSalesPointSelectabeListInput'
import { PerimeterAreaSummary } from './PerimeterAreaSummary'
import { PerimeterZoneSummary } from './PerimeterZoneSummary'
import { PerimeterSalesPointSummary } from './PerimeterSalesPointSummary'
import delve from 'dlv'

type Props = {
  source: string
  defaultValue?: any
  alreadyTakenSalesPointIds: string[]
}

export const CollectionPerimeterInput: FC<Props> = ({ source, defaultValue, alreadyTakenSalesPointIds }) => {
  const classes = useStyles()
  const { change } = useForm()
  const { values } = useFormState()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [enabledAccordion, setEnabledAccordion] = useState<'area' | 'zone' | 'salesPoint'>('area')

  const areaIdsParsedSource = useMemo(() => `${source}.areaIds`.replaceAll('[', '.').replaceAll(']', ''), [source])
  const zoneIdsParsedSource = useMemo(() => `${source}.zoneIds`.replaceAll('[', '.').replaceAll(']', ''), [source])
  const salesPointIdsParsedSource = useMemo(() => `${source}.salesPointIds`.replaceAll('[', '.').replaceAll(']', ''), [
    source,
  ])

  const areaIds = useMemo(() => delve(values, areaIdsParsedSource), [areaIdsParsedSource, values])
  const zoneIds = useMemo(() => delve(values, zoneIdsParsedSource), [zoneIdsParsedSource, values])
  const salesPointIds = useMemo(() => delve(values, salesPointIdsParsedSource), [salesPointIdsParsedSource, values])

  const handleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(true)
    if (zoneIds && zoneIds.length > 0) {
      setEnabledAccordion('salesPoint')
      return
    }

    if (areaIds && areaIds.length > 0) {
      setEnabledAccordion('zone')
      return
    }

    setEnabledAccordion('area')
  }, [areaIds, zoneIds, salesPointIds])

  const handleDrawerClose = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  const handleOpenAreasSelector = useCallback(() => {
    setEnabledAccordion('area')
  }, [])

  const handleOpenZonesSelector = useCallback(() => {
    setEnabledAccordion('zone')
  }, [])

  const handleOpenSalesPointsSelector = useCallback(() => {
    setEnabledAccordion('salesPoint')
  }, [])

  const handlePerimeterReset = useCallback(() => {
    if (change) {
      change(`${source}.areaIds`, [])
      change(`${source}.zoneIds`, [])
      change(`${source}.salesPointIds`, [])
    }
  }, [change])

  return (
    <div className={classes.root}>
      <div className={classes.overviewContainer}>
        <PerimeterAreaSummary source={`${source}.areaIds`} />
        <PerimeterZoneSummary source={`${source}.zoneIds`} areaSource={`${source}.areaIds`} />
        <PerimeterSalesPointSummary source={`${source}.salesPointIds`} />
      </div>
      <div className={classes.perimeterActionsContainer}>
        <Button className={classes.selectPerimeterButton} variant="outlined" onClick={handleDrawerOpen}>
          {salesPointIds && salesPointIds.length > 0 ? 'Modifica' : 'Seleziona'} Perimetro
        </Button>
        {((areaIds && areaIds.length > 0) ||
          (zoneIds && zoneIds.length > 0) ||
          (salesPointIds && salesPointIds.length > 0)) && (
          <Button
            className={classes.selectPerimeterButton}
            variant="outlined"
            color="primary"
            onClick={handlePerimeterReset}
          >
            Reset Perimetro
          </Button>
        )}
      </div>
      <Drawer onClose={handleDrawerClose} open={isDrawerOpen} anchor="right" className={classes.drawer}>
        <div className={classes.drawerContent}>
          <div className={classes.drawerHeader}>
            <Typography variant="h2">Seleziona Perimetro</Typography>
            <BaseButton onClick={handleDrawerClose}>Chiudi</BaseButton>
          </div>
          <CoopAreaSelectabeListInput
            source={`${source}.areaIds`}
            zoneIdsSource={`${source}.zoneIds`}
            salesPointIdsSource={`${source}.salesPointIds`}
            defaultValue={get('areaIds', defaultValue)}
            expanded={enabledAccordion === 'area'}
            title="Seleziona Aree"
            onGoToNextStep={handleOpenZonesSelector}
            onChange={handleOpenAreasSelector}
            alreadyTakenSalesPointIds={alreadyTakenSalesPointIds}
          />
          <CoopZoneSelectabeListInput
            source={`${source}.zoneIds`}
            areaIdsSource={`${source}.areaIds`}
            salesPointIdsSource={`${source}.salesPointIds`}
            defaultValue={get('zoneIds', defaultValue)}
            expanded={enabledAccordion === 'zone'}
            title="Seleziona Zone"
            onChange={handleOpenZonesSelector}
            onGoToNextStep={handleOpenSalesPointsSelector}
            alreadyTakenSalesPointIds={alreadyTakenSalesPointIds}
          />
          <CoopSalesPointSelectabeListInput
            source={`${source}.salesPointIds`}
            areaIdsSource={`${source}.areaIds`}
            zoneIdsSource={`${source}.zoneIds`}
            defaultValue={get('zoneIds', defaultValue)}
            expanded={enabledAccordion === 'salesPoint'}
            title="Seleziona Punti Vendita"
            onChange={handleOpenSalesPointsSelector}
            alreadyTakenSalesPointIds={alreadyTakenSalesPointIds}
          />
        </div>
      </Drawer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  overviewContainer: {},
  drawer: {
    maxWidth: '450px',
  },
  drawerContent: {
    width: '450px',
    minWidth: '450px',
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(5),
  },
  perimeterActionsContainer: {
    marginTop: theme.spacing(8),
    display: 'flex',
  },
  selectPerimeterButton: {
    marginRight: theme.spacing(2),
  },
}))
