import React, { FC } from 'react'
import { Image, Text, chakra } from '@chakra-ui/react'
import coopItalia from '../../assets/images/coop-italia.png'

type Props = {
  //
}

export const TutorialCard: FC<Props> = () => {
  return (
    <chakra.div w="100%" h="825px" maxH="825px" bgColor="white" borderRadius="4px" mb="37px">
      <chakra.div
        borderTopRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="100%"
        h="293px"
        bgColor="red.200"
      >
        <Image src={coopItalia} />
      </chakra.div>

      <chakra.div display="flex" flexDir="column" flex="1" mx="59px" justifyContent="space-between" h="532px">
        <chakra.div alignItems="center">
          <Text mt={10} fontSize="4xl" fontWeight="800" lineHeight="40px">
            TOSCO - TOol Short COllection
          </Text>
          <Text mt={4} fontSize="xl" fontWeight="thin">
            Gestione Short Collection di Coop Alleanza 3.0
          </Text>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  )
}
