import React, { FC, useMemo } from 'react'
import Show from '../../components/details/Show'
import { Box, Chip, LinearProgress, makeStyles } from '@material-ui/core'
import BooleanStatusField from '../../components/BooleanStatusField'
import ToggleStatusButton from '../../components/button/ToggleStatusButton'
import { ReferenceManyField, TextField, DateField, ReferenceField } from 'react-admin'
import { Pagination } from '../../components/list/pagination'
import { FaGifts } from 'react-icons/fa'
import Datagrid from '../../components/Datagrid'
import Ean13ModeField from '../../components/Ean13ModeField'
import Empty from '../../components/list/Empty'
import { usePermissions, useQueryWithStore, useShowController } from 'ra-core'
import AvatarField from '../../components/AvatarField'
import BarcodeField from '../../components/BarcodeField'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'
import { hasPermission } from '../../utils/hasPermission'
import { ContributionMechanicField } from '../../components/ContributionMechanicField'
import { SingleMechanicField } from '../../components/SIngleMechanicField'

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardValue: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  bold: {
    fontWeight: 'bold',
  },
  activeButton: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: '#56c85f',
  },
  activeLabel: {
    color: 'white',
  },
  card: {
    position: 'sticky',
    top: theme.spacing(3),
  },
  statCard: {
    flex: 1,
  },
  customerReservations: {
    // backgroundColor: theme.palette.background.paper,
    // overflow: 'scroll',
    alignSelf: 'flex-start',
    width: 'calc(100% - 315px)',
    maxWidth: 'calc(100% - 315px)',
  },
  brandTitle: {
    color: 'black',
    marginLeft: theme.spacing(3),
  },
}))

const ShortCollectionShowComp = (props: any): any => {
  const classes = useStyles()
  const { loaded: permLoaded, permissions } = usePermissions()

  return (
    <Box display="flex" position="relative">
      <Box pr={3}>
        <InfoCardWrapper>
          <InfoCard>
            <InfoCardItem direction="column" alignItems="flex-start">
              <Box display="flex">
                <InfoCardAvatar>
                  <FaGifts size={20} />
                </InfoCardAvatar>
                <InfoCardTypography variant="title">
                  <ReferenceField
                    {...props}
                    label="Nome"
                    source="productCategoryId"
                    reference="ProductCategory"
                    link={false}
                  >
                    <TextField source="name" variant="h4" className={classes.bold} />
                  </ReferenceField>
                </InfoCardTypography>
                {props.record.name && <InfoCardTypography variant="title">{props.record.name}</InfoCardTypography>}
              </Box>
              <Box mt={2}>
                {props.record.description && (
                  <InfoCardTypography variant="label">{props.record.description}</InfoCardTypography>
                )}
              </Box>
            </InfoCardItem>
            <InfoCardItem>
              <InfoCardTypography variant="label">Validità Short Collection:</InfoCardTypography>
              <Box display="flex" className={classes.cardValue}>
                <DateField {...props} source="startDate" className={classes.cardValue} />
                {'  -  '}
                <DateField {...props} source="endDate" className={classes.cardValue} />
              </Box>
            </InfoCardItem>
            <InfoCardItem>
              <InfoCardTypography variant="label">Data clean-up:</InfoCardTypography>
              <DateField {...props} source="cleanUpDate" className={classes.cardValue} />
            </InfoCardItem>
            {permLoaded && hasPermission(permissions) && (
              <InfoCardItem direction="row" justifyContent="space-between">
                <div>
                  <InfoCardTypography variant="label">Status:</InfoCardTypography>
                  <BooleanStatusField className={classes.cardValue} variant="body1" {...props} source="active" />
                </div>
                <ToggleStatusButton {...props} source="active" />
              </InfoCardItem>
            )}
          </InfoCard>
          <Box mb={2} />
          <InfoCard>
            <InfoCardItem direction="row">
              {/* {!loading && data && data.pictureId && (
                <Box mr={3}>
                  <BrandImageField id={data.pictureId} />
                </Box>
              )} */}
              <Box display="flex" flexDirection="column">
                <InfoCardTypography variant="label">Brand:</InfoCardTypography>
                <TextField source="brand" record={props.record} className={classes.cardValue} />
              </Box>
            </InfoCardItem>
          </InfoCard>
        </InfoCardWrapper>
      </Box>

      <ProductsTable {...props} />
    </Box>
  )
}

const ProductsTable: FC<any> = ({ record }) => {
  const classes = useStyles()

  return (
    <Box className={classes.customerReservations}>
      <ReferenceManyField
        pagination={<Pagination />}
        record={record}
        reference="Product"
        target="shortCollectionId"
        basePath="/ShortCollection"
      >
        <Datagrid emptyComponent={<Empty buttonComponent={<></>} />}>
          <TextField source="id" label="ID" />
          <TextField source="name" label="Prodotto" />
          <BarcodeField label="Ean Bol" source="eanStamps" sortable={false} record={record} height={25} preKey={''} />
          <SingleMechanicField label="Mecc. Bollini" record={record} source="numStampsStamps" />
          <BarcodeField
            label="EAN Bol + Contr."
            record={record}
            source="eanStampsContribution"
            sortable={false}
            height={25}
            preKey={''}
          />
          <ContributionMechanicField
            label="Mecc. Bol + Contr."
            record={record}
            stampsQuantitySource="numStampsStampsContribution"
            contributionSource="contributionStampsContribution"
          />
          <BarcodeField label="EAN Pt" source="eanPoints" sortable={false} record={record} height={25} preKey={''} />
          <SingleMechanicField label="Mecc. Pt" record={record} appendix="pt" source="numPointsPoints" />
          <BarcodeField
            label="EAN Pt + Contrib."
            source="eanPointsContribution"
            sortable={false}
            record={record}
            height={25}
            preKey={''}
          />
          <ContributionMechanicField
            label="Mecc. Pt + Contrib."
            record={record}
            pointsQuantitySource="numPointsPointsContribution"
            contributionSource="contributionPointsContribution"
          />
          <BarcodeField
            label="EAN Contr."
            source="eanContribution"
            sortable={false}
            record={record}
            height={25}
            preKey={''}
          />
          <SingleMechanicField
            label="Mecc. Contr."
            record={record}
            source="contributionContribution"
            type="currency"
            appendix="€"
          />
          <BarcodeField
            label="EAN Bol + Pt + Contr."
            source="eanStampsPointsContribution"
            sortable={false}
            record={record}
            height={25}
            preKey={''}
          />

          <ContributionMechanicField
            label="Mecc. Bol + Pt + Contrib."
            stampsQuantitySource="numStampsStampsPointsContribution"
            pointsQuantitySource="numPointsStampsPointsContribution"
            contributionSource="contributionStampsPointsContribution"
          />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  )
}

const ShortCollectionShow: FC<any> = (props) => {
  const { loaded, permissions } = usePermissions()
  const { loading, record } = useShowController(props)

  return (
    <Show
      {...props}
      hideEdit={loaded && hasPermission(permissions) ? false : true}
      titleBar={
        !loading ? (
          record && !record.isPointsBurner ? undefined : (
            <Chip
              label="Iniziativa bruciapunti"
              // icon={<WhatshotIcon />}
              color="primary"
            />
          )
        ) : undefined
      }
    >
      <ShortCollectionShowComp />
    </Show>
  )
}

const BrandImageField: FC<any> = ({ id }) => {
  const { loading, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'Picture',
    payload: { id },
  })

  if (loading) return <LinearProgress />
  if (data) return <AvatarField variant="rounded" record={data} source="urlSmall" />
  return null
}

export default ShortCollectionShow
