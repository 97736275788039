import { FC } from 'react'
import { SelectInput } from 'react-admin'
import { Alert, AlertIcon, Button, Flex } from '@chakra-ui/react'
import { useShortCollections } from '../hooks/useShortCollections'

type Props = {
  source: string
  resource?: string
  record?: any
  [x: string]: any
}

const ShortCollectionSelectInput: FC<Props> = (props) => {
  const { choices, isEmpty, reload, isReloading } = useShortCollections()

  if (isEmpty) {
    return (
      <Alert status="error" mb="14px" borderRadius="md">
        <AlertIcon />
        <Flex justifyContent="space-between" alignItems="center" flex={1}>
          Al momento non sono presenti short collection attive, siete pregati di non aprire ticket, ma di chiedere
          informazioni all’ufficio fidelizzazione
          <Button colorScheme="gray" size="sm" isLoading={isReloading} onClick={reload}>
            Riprova
          </Button>
        </Flex>
      </Alert>
    )
  }

  return <SelectInput {...props} choices={choices} />
}

export default ShortCollectionSelectInput
