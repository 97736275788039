import React, { FC, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import BaseButton from '../../components/button/BaseButton'
import { Collapse, DialogContent, DialogContentText, DialogTitle, Fade, LinearProgress, Modal } from '@material-ui/core'
import BaseTypography from '../../components/BaseTypography'
import { IoMdAlert } from 'react-icons/io'
import { ReferenceInput } from 'react-admin'
import { SelectInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const QUERY_SMS_SORRY_NUMBER = gql`
  query SorrySMSPreview($data: PreviewTotalCanceledReservationsInput!) {
    previewTotalCanceledReservations(data: $data)
  }
`

type Props = {
  record?: any
  open: boolean
  onClose?: () => void
  onConfirm?: () => void
}

const ProductStockEditConfrimModal: FC<Props> = (props) => {
  const classes = useStyles()
  const { onClose, onConfirm, open, record } = props
  const [showWarning, setShowWarning] = useState<boolean>(true)

  const handleClose = (): void => onClose && onClose()

  const handleHideWarning = (): void => setShowWarning(false)

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
      onClose={handleClose}
      aria-labelledby="productStockEdit-dialog-title"
      open={open}
      className={classes.root}
    >
      <Fade in={open}>
        <Box
          p={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          maxWidth="480px"
          m="auto"
          className={classes.wrapper}
        >
          <Box width="100%" display="flex" flexDirection="column" alignSelf="center">
            <ProductStockEditDialog record={record} />
            <ProductStockEditWarningBox record={record} onClose={handleHideWarning} open={showWarning} />

            <Box width="100%" display="flex" alignItems="stretch">
              <BaseButton size="large" onClick={handleClose} fullWidth variant="contained">
                Annulla
              </BaseButton>
              <Box pl={2} />
              <BaseButton size="large" onClick={onConfirm} fullWidth color="primary" variant="contained">
                Procedi
              </BaseButton>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

type ProductStockEditWarningBoxProps = {
  record: Record<string, any>
  open?: boolean
  onClose?: () => void
}

const ProductStockEditWarningBox: FC<ProductStockEditWarningBoxProps> = ({ onClose, open, record }) => {
  const { values } = useFormState()
  const { loading, error, data } = useQuery(QUERY_SMS_SORRY_NUMBER, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        productId: record.productId,
        salesPointId: record.salesPointId,
        quantity: parseInt(values.stock, 10),
      },
    },
  })

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <Collapse in={open && data && data.previewTotalCanceledReservations > 0}>
        <Box mb={2}>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            padding={6}
            bgcolor="#fff9d1"
            borderRadius={4}
            boxShadow={3}
          >
            {!loading ? (
              <>
                <BaseTypography gutterBottom variant="h3">
                  Invio {data.previewTotalCanceledReservations} SMS di scuse
                </BaseTypography>
                <BaseTypography weight="light" variant="caption">
                  Procedendo con l’aggiornamento della giacenza andrai a rendere indisponibili dei premi che erano stati
                  assegnati a {data.previewTotalCanceledReservations} persone, sei sicuro che non hai dei prodotti in
                  area vendita che ti permettono di pareggiare la giacenza a sistema?”
                </BaseTypography>
              </>
            ) : (
              <LinearProgress />
            )}
            {/*  <FormControlLabel
              control={<Checkbox name="showAlert" color="primary" />}
              label="Non mostrare più"
              onClick={onClose}
          /> */}
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

type ProductStockEditDialogProps = {
  record?: any
  open?: boolean
  onClose?: () => void
}

const ProductStockEditDialog: FC<ProductStockEditDialogProps> = (props) => {
  const { values } = useFormState()
  const { record } = props
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" className={classes.dialog} height="100%" mb={2}>
      <Box padding={1} paddingY={2} bgcolor="background.paper" borderRadius={4} boxShadow={3} height="100%">
        <DialogTitle id="productStockEdit-dialog-title" className={classes.dialogTitle}>
          <BaseTypography variant="h3"> Conferma modifica giacenza</BaseTypography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box borderRadius={3} display="flex">
              <Box display="flex" flexDirection="column" mr={3}>
                <Box
                  my="auto"
                  color="background.paper"
                  bgcolor={`${values.stock - record.stock > 0 ? 'success' : 'primary'}.dark`}
                  borderRadius={4}
                  minWidth="35px"
                  minHeight="35px"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                >
                  <BaseTypography weight="bold">
                    {values.stock - record.stock > 0 ? '+' : ''}
                    {values.stock - record.stock}
                  </BaseTypography>
                </Box>
              </Box>

              <ReferenceInput
                {...props}
                record={record}
                resource="ProductStock"
                source="productId"
                reference="Product"
                link={false}
                className={classes.fullWidthInput}
              >
                <SelectInput source="name" variant="outlined" disabled helperText={''} />
              </ReferenceInput>
            </Box>
            {values.stock - record.stock > 0 && (
              <Box>
                <IoMdAlert /> Ricordati che verrà inviato un SMS subito dopo aver premuto “Procedi”
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialog: {},
  dialogTitle: {
    paddingBottom: 0,
  },
  wrapper: {
    outline: 'none',
  },
  input: {
    width: '100px',
    paddingLeft: theme.spacing(3),
  },
  fullWidthInput: {
    width: '100%',
  },
}))

export default ProductStockEditConfrimModal
