import React, { FC } from 'react'
import List from '../../components/list/List'
import { TextField, ReferenceField } from 'react-admin'
import FilterTextInput from '../../components/FilterTextInput'
import { FaShoppingBasket } from 'react-icons/fa'
import Datagrid from '../../components/Datagrid'
import StatusInfoLabel from '../../components/StatusInfoLabel'
import { ProductReservationStatusColors, ProductReservationStatusEnum } from '../../customEnums'
import { FunctionField } from 'react-admin'
import { DateField } from 'react-admin'
import { ReferenceInput } from 'react-admin'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import SearchAutoCompleteInput from '../../components/SearchAutoCompleteInput'
import { usePermissions } from 'react-admin'
import { hasPermission } from '../../utils/hasPermission'
import { DateInput } from 'react-admin'
import Filter from '../../components/list/filter/Filter'
import { SelectInput } from 'react-admin'
import { Box, makeStyles } from '@material-ui/core'
import ProductReservationStatusField from '../../components/ProductReservationStatusField'
import { TextInput } from 'react-admin'

const StatusItem = (choice: any): any => (
  <Box height="20px" display="flex" alignItems="flex-start" justifyContent="flex-start">
    <ProductReservationStatusField record={{ productReservationStatus: choice.record.id }} />
  </Box>
)

const useStyles = makeStyles((theme) => ({
  textCell: {
    maxWidth: 130,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  smallText: {
    fontSize: 12,
  },
}))

const useFilterStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    borderWidth: 0,
    borderColor: 'transparent',
    width: 200,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    margin: 0,
    // height: '45px',
    '& .MuiInputBase-root': {
      '& hover': {
        borderColor: 'transparent',
      },

      '& fieldset': {
        borderColor: 'transparent',
        height: '50px',
      },
    },
  },
  // select: {
  //   height: '45px',
  //   borderColor: 'transparent !important',
  // },
  // outlined: {
  //   borderWidth: 0,
  //   borderColor: 'transparent',
  // },
}))

const filterToQueryShorts = (name: string): Record<string, any> => ({ name })
const filterToQueryProducts = (name: string): Record<string, any> => ({ name })
const filterToQuerySalesPoints = (name: string): Record<string, any> => ({ name })

const ProductReservationFilters: FC<any> = (props) => {
  const classes = useFilterStyles()
  const { loaded, permissions } = usePermissions()
  return (
    <Filter {...props}>
      <SelectInput
        classes
        alwaysOn
        allowEmpty
        emptyText="Tutti gli stati"
        variant="outlined"
        optionText={<StatusItem />}
        label="Cerca per Stato"
        options={{
          classes,
        }}
        source="productReservationStatus"
        choices={[
          { id: 'WAITING', name: ProductReservationStatusEnum.WAITING },
          {
            id: 'WAITING_FOR_AVAILABLE_NOTIFICATION',
            name: ProductReservationStatusEnum.WAITING_FOR_AVAILABLE_NOTIFICATION,
          },
          { id: 'CUSTOMER_NOTIFIED', name: ProductReservationStatusEnum.CUSTOMER_NOTIFIED },
          { id: 'DELIVERED', name: ProductReservationStatusEnum.DELIVERED },
          {
            id: 'WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION',
            name: ProductReservationStatusEnum.WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION,
          },
        ]}
      />
      <TextInput label="Cerca cliente..." source="clientFullName" />
      <ReferenceInput filterToQuery={filterToQueryShorts} source="shortCollectionId" reference="ShortCollection">
        <AutocompleteInputInDrawer optionText="name" />
      </ReferenceInput>
      <ReferenceInput filterToQuery={filterToQueryProducts} source="productId" reference="Product">
        <AutocompleteInputInDrawer optionText="name" />
      </ReferenceInput>
      {loaded && hasPermission(permissions) && (
        <ReferenceInput
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={filterToQuerySalesPoints}
          source="salesPointId"
          reference="SalesPoint"
        >
          <AutocompleteInputInDrawer optionText="name" fullWidth />
        </ReferenceInput>
      )}
      <DateInput source="dateRangeStart" />
      <DateInput source="dateRangeEnd" />
    </Filter>
  )
}

const ProductReservationList: FC = (props) => {
  const classes = useStyles(props)
  return (
    <List
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<ProductReservationFilters variant="outlined" />}
      titleIcon={<FaShoppingBasket />}
      actions={<></>}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={(id: any, basePath: any, record: any): string => `/Reservation/${record.reservationId}/show`}>
        {/* <TextField label="Nome" source="reservation.clientFirstName" />
        <TextField label="Cognome" source="reservation.clientLastName" /> */}
        <FunctionField
          label="Cognome e Nome Cliente"
          cellClassName={classes.textCell}
          render={(record: any): React.ReactNode => (
            <span className={classes.smallText}>
              {`${record.reservation.clientLastName} ${record.reservation.clientFirstName}`}
            </span>
          )}
          noWrap
          sortable={false}
        />
        <TextField label="Telefono" source="reservation.clientPhone" className={classes.smallText} sortable={false} />
        <TextField
          label="Short Collection"
          source="reservation.shortCollection.name"
          sortable={false}
          cellClassName={classes.textCell}
          className={classes.smallText}
          noWrap
        />
        <TextField
          label="Prodotto"
          source="product.name"
          sortable={false}
          className={classes.smallText}
          cellClassName={classes.textCell}
          noWrap
        />
        <FunctionField
          label="Stato"
          render={(record: any): any => (
            <StatusInfoLabel
              color={ProductReservationStatusColors[record.productReservationStatus]}
              label={ProductReservationStatusEnum[record.productReservationStatus] || 'N.D.'}
            />
          )}
          sortable={false}
        />
        {/* <TextField label="ID Prenotazione" source="reservation.id" />
        <TextField label="ID Riga" source="id" /> */}
        <FunctionField
          label="ID"
          render={(record: any): React.ReactNode => (
            <span className={classes.smallText}>
              <strong>Pren.:</strong> {record.reservation.id}
              <br />
              <strong>Riga:</strong> {record.id}
            </span>
          )}
          sortable={false}
        />
        <DateField showTime source="createdAt" className={classes.smallText} />
      </Datagrid>
    </List>
  )
}

export default ProductReservationList
