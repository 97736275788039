import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  components: {
    Switch: {
      defaultProps: {
        colorScheme: 'red',
        size: 'md',
      },
    },
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  fonts: {
    body: 'Avenir Next',
    heading: 'Avenir Next',
  },
  styles: {
    global: {
      body: {
        backgroundColor: 'gray.50',
      },
    },
  },
  colors: {
    blackAlpha: {
      100: 'rgba(0, 0, 0, 0.36)',
      900: 'rgba(0, 0, 0, 0.8)',
    },
    gray: {
      50: '#FBFBFB',
      100: '#EEEEEE',
      300: '#A6B0B9',
      400: '#1A2240',
      600: '#5e5555',
      800: '#333333',
      900: '#242326',
    },
    red: {
      100: '#EDD6D6',
      200: '#E6D5D5',
      300: '#FF5E5E',
      500: '#ED1D23',
      900: '#481A1A',
    },
    green: {
      300: '#0edd4d',
    },
  },
  shadows: {
    outline: 'none',
  },
})
