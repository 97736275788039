import React, { FC, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useSetRecoilState } from 'recoil'
import { jobsQueueCount } from '../../utils/jobs-queue-state'

const QUERY_GET_QUEUE_COUNT = gql`
  query getQueueCount($salesPointId: ID) {
    jobsQueueCount(salesPointId: $salesPointId)
  }
`

export const JobsQueueInfoInitializer: FC = () => {
  const setQueueLength = useSetRecoilState(jobsQueueCount)

  const { startPolling, stopPolling, data } = useQuery(QUERY_GET_QUEUE_COUNT)

  useEffect(() => {
    setQueueLength(data?.jobsQueueCount || 0)
  }, [data?.jobsQueueCount])

  useEffect(() => {
    startPolling(10 * 1000)
    return () => {
      stopPolling()
    }
  }, [])

  return null
}
