import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { InputProps } from 'ra-core'
import { TextFieldProps } from '@material-ui/core/TextField'
import { Box } from '@material-ui/core'
import { FaSearch } from 'react-icons/fa'

type Props = InputProps<TextFieldProps> &
  Omit<TextFieldProps, 'label' | 'helperText'> & {
    icon?: React.ReactElement
  }

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    borderWidth: 0,
    borderColor: 'transparent',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    margin: 0,
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    height: '45px',
  },
  textInput: {
    margin: 0,
  },
  inputRoot: {
    backgroundColor: 'transparent !important',
    borderColor: 'transparent',
    borderWidth: 0,
    width: '230px',
    '&::before,&::after': {
      display: 'none !important',
    },
  },
  input: {
    backgroundColor: 'transparent',
    paddingTop: '18px',
    paddingBottom: '9px',
    paddingRight: 0,
    '& + fieldset': {
      borderColor: 'transparent',
      borderWidth: 0,
    },
  },
  label: {
    top: '-3px',
  },
  helperText: {
    display: 'none',
  },
  iconWrapper: {
    display: 'flex',
  },
  icon: {
    display: 'flex',
    color: theme.palette.text.primary,
    fontSize: '1rem',
  },
  focused: {},
  disabled: {},
}))

const FilterTextInput: FC<Props> = ({ icon = FaSearch, ...rest }) => {
  const classes = useStyles(rest)
  return (
    <Box display="flex" className={classes.root}>
      <Box className={classes.iconWrapper} alignItems="center" justifyContent="center">
        <FaSearch className={classes.icon} />
      </Box>
      <TextInput
        {...rest}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.input,
            // formControl: classes.label,
          },
        }}
        InputLabelProps={{ classes: { root: classes.label } }}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        variant="filled"
        className={classes.textInput}
      />
    </Box>
  )
}

export default FilterTextInput
