import list from './ReservationList'
import create from './ReservationCreate'
import show from './ReservationShow'
import edit from './ReservationEdit'

export default {
  name: 'Reservation',
  list,
  create,
  show,
  edit,
}
