import React, { FC } from 'react'
import { chakra } from '@chakra-ui/system'
import { useBreakpointValue } from '@chakra-ui/media-query'
import { Text, Image } from '@chakra-ui/react'
import { TutorialCard } from './TutorialCard'
import { LoginForm } from './LoginForm'
import coopItalia from '../../assets/images/coop-italia.png'

type Props = {
  //
}

export const Login: FC<Props> = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <chakra.div
      px={{ base: 2, lg: '32px' }}
      pt="38px"
      // h="100%"
      minH="100vh"
      bgGradient="linear(to-r, red.300, red.900)"
    >
      <chakra.div
        display={{ base: 'flex', lg: 'grid' }}
        gridTemplateColumns={{ base: '1fr', lg: '400px 1fr' }}
        gridColumnGap={{ base: undefined, lg: '147px' }}
        flexDir="column"
      >
        {!isMobile && <TutorialCard />}
        <chakra.div display="flex" flexDir="column" justifyContent="center">
          <chakra.div display="flex" flexDir="column" alignItems={{ base: 'center', lg: 'flex-start' }}>
            <chakra.div w={{ base: '100%', lg: 'auto' }} mb={{ base: 8, lg: 5 }} display="flex" flexDir="column">
              {isMobile && (
                <chakra.div
                  mt="20px"
                  mb={6}
                  w="76px"
                  flex="0 0 76px"
                  borderTopRadius="4px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="4px"
                  alignSelf="center"
                  bg="white"
                >
                  <Image w="73px" h="73px" objectFit="contain" src={coopItalia} />
                </chakra.div>
              )}

              <Text
                textAlign={{ base: 'center', lg: 'left' }}
                color="white"
                fontSize={{ base: 'xl', lg: '4xl' }}
                lineHeight="40px"
              >
                Benvenuto nel portale
              </Text>
              <Text
                textAlign={{ base: 'center', lg: 'left' }}
                color="white"
                fontSize={{ base: 'xl', lg: '4xl' }}
                fontWeight="extrabold"
              >
                Gestione short collection
              </Text>
            </chakra.div>

            <LoginForm />
          </chakra.div>
        </chakra.div>
      </chakra.div>
    </chakra.div>
  )
}
