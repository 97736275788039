import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { IoMdArrowDropright, IoMdArrowDropleft } from 'react-icons/io'
import { Box } from '@material-ui/core'

type Props = {
  /**
   * Props applied to the back arrow [`IconButton`](/api/icon-button/) element.
   */
  backIconButtonProps?: any
  /**
   * The total number of rows.
   */
  count?: number
  /**
   * Props applied to the next arrow [`IconButton`](/api/icon-button/) element.
   */
  nextIconButtonProps?: any
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onChangePage?: (event: any, page: number) => void
  /**
   * The zero-based index of the current page.
   */
  page?: number
  /**
   * The number of rows per page.
   */
  rowsPerPage?: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginLeft: theme.spacing(3),
    },
    iconButton: {
      padding: theme.spacing(1),
    },
  })
)

/**
 * @ignore - internal component.
 */
const TablePaginationActions = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    backIconButtonProps,
    count = 0,
    nextIconButtonProps,
    onChangePage,
    page = 0,
    rowsPerPage = 0,
    ...other
  } = props

  const theme = useTheme()
  const classes = useStyles()

  const handleBackButtonClick = useCallback(
    (event) => {
      onChangePage!(event, page - 1)
    },
    [page, onChangePage]
  )

  const handleNextButtonClick = useCallback(
    (event) => {
      onChangePage!(event, page + 1)
    },
    [page, onChangePage]
  )

  return (
    <div ref={ref} {...other} className={classes.root}>
      <Box boxShadow={1} borderRadius={3} bgcolor="background.paper" width="35px" height="35px">
        <IconButton
          // size="small"
          onClick={handleBackButtonClick}
          disabled={page === 0}
          color="inherit"
          className={classes.iconButton}
          {...backIconButtonProps}
        >
          {theme.direction === 'rtl' ? <IoMdArrowDropright /> : <IoMdArrowDropleft />}
        </IconButton>
      </Box>
      <Box boxShadow={1} borderRadius={3} bgcolor="background.paper" width="35px" height="35px" ml={1}>
        <IconButton
          // size="small"
          onClick={handleNextButtonClick}
          disabled={count !== -1 ? page >= Math.ceil(count / rowsPerPage) - 1 : false}
          color="inherit"
          className={`${classes.iconButton}`}
          {...nextIconButtonProps}
        >
          {theme.direction === 'rtl' ? <IoMdArrowDropleft /> : <IoMdArrowDropright />}
        </IconButton>
      </Box>
    </div>
  )
})

export default TablePaginationActions
