import React, { FC, useCallback } from 'react'
import RAList from '../../components/list/List'
import { TextField, ReferenceField, ReferenceInput } from 'react-admin'

import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import { FaBox } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core'
import { Route, useHistory } from 'react-router-dom'
import Drawer from '../../components/Drawer'
import DrawerHeader from '../../components/DrawerHeader'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import Filter from '../../components/list/filter/Filter'
import { usePermissions } from 'ra-core'
import AlreadyAssignedProductsField from '../../components/AlreadyAssignedProductsField'
import FilterTextInput from '../../components/FilterTextInput'
import OperatorProductStockField from './OperatorProductStockField'
import OperatorProductStockEdit from './OperatorProductStockEdit'

type Props = {
  //
}

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    width: '26vw',
    minWidth: 260,
    // eslint-disable-next-line prettier/prettier
    content: '\'\'',
  },
}))

const OperatorProductStockFilters: FC<any> = (props) => {
  const { loaded, permissions } = usePermissions()
  const autocompleteQuery = useCallback(
    () =>
      (name: string): Record<string, string> => ({ name }),
    []
  )
  return (
    <Filter {...props}>
      <FilterTextInput label="Prodotto" source="name" alwaysOn />
      <ReferenceInput
        label="Short Collection"
        source="shortCollectionId"
        reference="ShortCollection"
        link={false}
        filterToQuery={autocompleteQuery}
      >
        <AutocompleteInputInDrawer optionText={'name'} fullWidth />
      </ReferenceInput>
    </Filter>
  )
}

const OperatorProductStockList: FC<Props> = (props) => {
  const classes = useStyles(props)
  const history = useHistory()

  const handleDrawerClose = useCallback(() => {
    history.push('/OperatorProductStock')
  }, [])

  const handleRowClick = useCallback((id, basePath, record): string => {
    console.log(record, 'handleRowClick')
    return `${basePath}/${record.productId}`
  }, [])

  return (
    <>
      <RAList
        {...props}
        title="Giacenze"
        titleIcon={<FaBox />}
        actions={<CreateButton label="Nuova Consegna" />}
        filters={<OperatorProductStockFilters variant="outlined" />}
        bulkActionButtons={false}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <ReferenceField label="Collection" source="shortCollectionId" reference="ShortCollection" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <OperatorProductStockField label="stock" />
          <AlreadyAssignedProductsField productIdSource="id" label="Già assegnati" />
          <GenericMoreMenu disableDelete />
        </Datagrid>
      </RAList>

      <Route path="/OperatorProductStock/:id">
        {({ match }: any): React.ReactNode => {
          const isMatch = match && match.params && match.params.id !== 'create'

          return (
            <Drawer open={isMatch} onClose={handleDrawerClose}>
              {isMatch ? (
                <>
                  <DrawerHeader buttonOnClick={handleDrawerClose} title="Modifica Giacenza" />
                  <OperatorProductStockEdit
                    id={isMatch && match && match.params ? match.params.id : undefined}
                    onCancel={handleDrawerClose}
                    {...props}
                  />
                </>
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          )
        }}
      </Route>
    </>
  )
}

export default OperatorProductStockList
