import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import { AutocompleteInput, ReferenceInput } from 'ra-ui-materialui'

type Props = {
  [x: string]: any
}

const filterToQuery = (name: string): Record<string, any> => ({ name })

const CoopZoneCreate: FC<Props> = (props) => {
  return (
    <Create {...props} component="div">
      <SectionedForm variant="outlined" redirect="list" title="Nuova Zona">
        <FormSection title="Nome Zona">
          <FormTextInput {...props} source="name" helperText="" />
        </FormSection>
        <FormSection title="Area di Appartanenza">
          <ReferenceInput label="Area" filterToQuery={filterToQuery} source="coopAreaId" reference="CoopArea">
            <AutocompleteInput dense={false} optionText="name" fullWidth />
          </ReferenceInput>
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default CoopZoneCreate
