import { useQuery } from '@apollo/client'
import { Alert } from '@material-ui/lab'
import React, { FC } from 'react'
import { RadioButtonGroupInput } from 'react-admin'
import { SalesPointAreaEnum } from '../customEnums'
import { QUERY_GET_ALL_COOP_AREAS } from '../queries'

type Props = {
  record?: Record<string, any>
  source?: string
  label?: string | false
  disabled?: boolean
}

const SalesPointAreaInput: FC<Props> = ({ source = 'coopAreaId', label, record, disabled }) => {
  const { loading, data, error } = useQuery(QUERY_GET_ALL_COOP_AREAS)

  if (loading) {
    return (
      <RadioButtonGroupInput
        disabled
        label={label}
        source={source}
        choices={Object.entries(SalesPointAreaEnum).map((area: Record<number, string>) => ({
          id: area[0],
          name: area[1],
        }))}
      />
    )
  }

  if (error) {
    return <Alert severity="error">Error retrieving Areas</Alert>
  }

  return (
    <RadioButtonGroupInput
      disabled={disabled}
      label={label}
      source={source}
      choices={(data?.coopAreas?.data || []).map((area: Record<string, any>) => ({
        id: area.id,
        name: area.name,
      }))}
    />
  )
}

export default SalesPointAreaInput
