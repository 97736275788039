import React, { FC, useCallback, useMemo, useState } from 'react'
import { useFormState, useForm } from 'react-final-form'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import { TextInput, ImageInput, TextField, ArrayField, ImageField } from 'react-admin'
import WizardSectionedForm, { WizardStep } from '../../components/forms/WizardSectionedForm'
import FormDateInput from '../../components/FormDateInput'
import { Drawer, makeStyles, Typography, TextField as MuiTextInput, Box, Card } from '@material-ui/core'
import WizardRightControls from '../../components/forms/WizardSectionedForm/WizardRightControls'
import Button from '@material-ui/core/Button'
import { FormDataConsumer, required, useNotify, useRedirect, useTranslate } from 'ra-core'
import Datagrid from '../../components/Datagrid'
import IconButton from '@material-ui/core/IconButton'
import { Confirm } from 'ra-ui-materialui'
import SaveButton from '../../components/button/SaveButton'
import { gql, useApolloClient } from '@apollo/client'
import SwitchCardInput from '../../components/SwitchCardInput'
import BaseButton from '../../components/button/BaseButton'
import Empty from '../../components/list/Empty'
import { FaTrashAlt } from 'react-icons/fa'
import BarcodeField from '../../components/BarcodeField'
import MuiCurrencyInput, { formatCurrencyInput } from '../../components/MuiCurrencyInput'
import MuiNumberInput, { formatNumberInput } from '../../components/MuiNumberInput'
import { ShortCollectionRulesFormSection } from './ShortCollectionRules/ShortCollectionRulesFormSection'
import { SingleMechanicField } from '../../components/SIngleMechanicField'
import { ContributionMechanicField } from '../../components/ContributionMechanicField'

type Props = {
  [x: string]: any
}

const MUTATION_CREATE_SHORTCOLLECTION = gql`
  mutation CreateShortCollection($data: ShortCollectionCreateInput!) {
    createShortCollection(data: $data) {
      id
    }
  }
`

type AddProductButtonProps = {
  size?: 'small' | 'medium' | 'large'
} & Props

const AddProductButtonDrawer: FC<AddProductButtonProps> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [productRecord, setProductRecord] = useState<Record<string, any>>({})

  const onButtonClick = useCallback((): void => setIsDrawerOpen(true), [])
  const onClose = useCallback((): void => setIsDrawerOpen(false), [])

  const onUndoClick = useCallback((): void => {
    setProductRecord({})
    onClose()
  }, [])

  const onInputChange = useCallback(
    (event: any): void => setProductRecord({ ...productRecord, [event.target.name]: event.target.value }),
    [productRecord]
  )

  const onAddClick = useCallback((): void => {
    productRecord.active = true
    productRecord.numStampsStamps = formatNumberInput(productRecord.numStampsStamps)
    productRecord.numStampsStampsContribution = formatNumberInput(productRecord.numStampsStampsContribution)
    productRecord.contributionStampsContribution = formatCurrencyInput(productRecord.contributionStampsContribution)
    productRecord.numPointsPoints = formatNumberInput(productRecord.numPointsPoints)
    productRecord.numPointsPointsContribution = formatNumberInput(productRecord.numPointsPointsContribution)
    productRecord.contributionPointsContribution = formatCurrencyInput(productRecord.contributionPointsContribution)
    productRecord.contributionContribution = formatCurrencyInput(productRecord.contributionContribution)

    productRecord.numStampsStampsPointsContribution = formatNumberInput(productRecord.numStampsStampsPointsContribution)
    productRecord.numPointsStampsPointsContribution = formatNumberInput(productRecord.numPointsStampsPointsContribution)
    productRecord.contributionStampsPointsContribution = formatCurrencyInput(
      productRecord.contributionStampsPointsContribution
    )

    if (values.productImages) {
      productRecord.images = values.productImages
    }

    form.mutators.push('products', productRecord)
    if (values.productImages) {
      for (let i = 0; i < values.productImages.length; i++) {
        form.mutators.pop('productImages')
      }
    }
    // saveProductsToLS(productRecord)
    setProductRecord({})
    onClose()
  }, [productRecord])

  const disableAddProductButton = useMemo(
    (): boolean =>
      !productRecord.name ||
      !productRecord.eanStamps ||
      !productRecord.eanStampsContribution ||
      !productRecord.eanPoints ||
      !productRecord.eanPointsContribution ||
      !productRecord.eanContribution ||
      !productRecord.numStampsStamps ||
      !productRecord.numStampsStampsContribution ||
      !productRecord.contributionStampsContribution ||
      !productRecord.numPointsPoints ||
      !productRecord.numPointsPointsContribution ||
      !productRecord.contributionPointsContribution ||
      !productRecord.contributionContribution ||
      !productRecord.eanStampsPointsContribution ||
      !productRecord.numStampsStampsPointsContribution ||
      !productRecord.numPointsStampsPointsContribution ||
      !productRecord.contributionStampsPointsContribution,
    [productRecord]
  )

  return (
    <Box pt={3}>
      <BaseButton
        fullWidth
        onClick={onButtonClick}
        variant="contained"
        size="xl"
        className={classes.addButton}
        {...props}
      >
        {translate('resources.ShortCollection.actions.addProduct')}
      </BaseButton>
      <Drawer open={isDrawerOpen} anchor="right" classes={{ paper: classes.drawer }} onClose={onClose}>
        <div className={classes.drawerContainer}>
          <div className={classes.row}>
            <Typography variant="h2">Aggiungi Prodotto</Typography>
            <BaseButton onClick={onUndoClick}>Annulla</BaseButton>
          </div>

          <Typography variant="h4" className={classes.subtitle}>
            Codici
          </Typography>
          <div className={classes.row}>
            <MuiNumberInput
              label="Codice Bollini"
              name="eanStamps"
              value={productRecord.eanStamps}
              onChange={onInputChange}
              fullWidth
            />
            <Box pl={2} />
            <MuiNumberInput
              label="Codice Bollini + Contributo"
              name="eanStampsContribution"
              value={productRecord.eanStampsContribution}
              onChange={onInputChange}
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <MuiNumberInput
              label="Codice Punti"
              name="eanPoints"
              value={productRecord.eanPoints}
              onChange={onInputChange}
              fullWidth
            />
            <Box pl={2} />
            <MuiNumberInput
              label="Codice Punti + Contributo"
              name="eanPointsContribution"
              value={productRecord.eanPointsContribution}
              onChange={onInputChange}
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <MuiNumberInput
              label="Codice Contributo"
              name="eanContribution"
              value={productRecord.eanContribution}
              onChange={onInputChange}
              fullWidth
              style={{ maxWidth: '49%' }}
            />
            <MuiNumberInput
              label="Codice Bollini + Punti + Contributo"
              name="eanStampsPointsContribution"
              value={productRecord.eanStampsPointsContribution}
              onChange={onInputChange}
              fullWidth
              style={{ maxWidth: '49%' }}
            />
          </div>

          <Typography variant="h4" className={classes.subtitle}>
            Informazioni di base
          </Typography>
          <div className={classes.row}>
            <MuiTextInput
              variant="outlined"
              label="Nome Prodotto"
              name="name"
              fullWidth
              value={productRecord.name}
              onChange={onInputChange}
            />
          </div>
          <div className={classes.row}>
            <MuiTextInput
              variant="outlined"
              label="Descrizione"
              name="description"
              fullWidth
              multiline
              value={productRecord.description}
              onChange={onInputChange}
            />
          </div>

          <Typography variant="h4" className={classes.subtitle}>
            Meccanica Bollini
          </Typography>
          <div className={classes.row}>
            <MuiNumberInput
              label="Bollini"
              name="numStampsStamps"
              value={productRecord.numStampsStamps}
              onChange={onInputChange}
              classes={{ root: classes.halfWidth }}
              fullWidth
            />
          </div>
          <Typography variant="h4" className={classes.subtitle}>
            Meccanica Bollini + Contributo
          </Typography>
          <div className={classes.row}>
            <MuiNumberInput
              label="Bollini"
              name="numStampsStampsContribution"
              value={productRecord.numStampsStampsContribution}
              onChange={onInputChange}
              fullWidth
            />
            <Box pl={2} />
            <MuiCurrencyInput
              label="Contributo (€)"
              name="contributionStampsContribution"
              value={productRecord.contributionStampsContribution}
              onChange={onInputChange}
              fullWidth
            />
          </div>
          <Typography variant="h4" className={classes.subtitle}>
            Meccanica Punti
          </Typography>
          <div className={classes.row}>
            <MuiNumberInput
              label="Punti"
              name="numPointsPoints"
              value={productRecord.numPointsPoints}
              onChange={onInputChange}
              classes={{ root: classes.halfWidth }}
              fullWidth
            />
          </div>
          <Typography variant="h4" className={classes.subtitle}>
            Meccanica Punti + Contributo
          </Typography>
          <div className={classes.row}>
            <MuiNumberInput
              label="Punti"
              name="numPointsPointsContribution"
              value={productRecord.numPointsPointsContribution}
              onChange={onInputChange}
              fullWidth
            />
            <Box pl={2} />
            <MuiCurrencyInput
              label="Contributo (€)"
              name="contributionPointsContribution"
              value={productRecord.contributionPointsContribution}
              onChange={onInputChange}
              fullWidth
            />
          </div>
          <Typography variant="h4" className={classes.subtitle}>
            Meccanica Contributo
          </Typography>
          <div className={classes.row}>
            <MuiCurrencyInput
              label="Contributo (€)"
              name="contributionContribution"
              value={productRecord.contributionContribution}
              onChange={onInputChange}
              classes={{ root: classes.halfWidth }}
              fullWidth
            />
          </div>
          <Typography variant="h4" className={classes.subtitle}>
            Meccanica Bollini + Punti + Contributo
          </Typography>
          <div className={classes.row}>
            <MuiNumberInput
              label="Bollini"
              name="numStampsStampsPointsContribution"
              value={productRecord.numStampsStampsPointsContribution}
              onChange={onInputChange}
              fullWidth
            />
            <Box pl={2} />
            <MuiNumberInput
              label="Punti"
              name="numPointsStampsPointsContribution"
              value={productRecord.numPointsStampsPointsContribution}
              onChange={onInputChange}
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <MuiCurrencyInput
              label="Contributo (€)"
              name="contributionStampsPointsContribution"
              value={productRecord.contributionStampsPointsContribution}
              onChange={onInputChange}
              classes={{ root: classes.halfWidth }}
              fullWidth
            />
          </div>
          <Button
            color="primary"
            variant="contained"
            className={classes.createButton}
            onClick={onAddClick}
            disabled={disableAddProductButton}
          >
            Aggiungi
          </Button>
        </div>
      </Drawer>
    </Box>
  )
}

const ProductsTable: FC<any> = ({ formDataProps }) => {
  return !formDataProps.formData.products ? (
    <Box>
      <Empty
        component={Card}
        buttonComponent={<AddProductButtonDrawer size="medium" />}
        messageComponent={<Typography>Clicca "Aggiungi Prodotto"</Typography>}
      />
    </Box>
  ) : (
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        overflowY: 'hidden',
        overflowX: 'scroll',
        flexDirection: 'column',
      }}
    >
      <ArrayField source="products" record={formDataProps.formData} selectedIds={[]}>
        <Datagrid fieldKey="id" emptyComponent={<Empty buttonComponent={<></>} component={Card} />}>
          <TextField source="name" sortable={false} />
          <BarcodeField
            source="eanStamps"
            label="Ean Bol"
            sortable={false}
            record={formDataProps.formData}
            height={25}
            preKey={''}
          />
          <SingleMechanicField source="numStampsStamps" label="Mecc. Bollini" />
          <BarcodeField
            label="EAN Bol + Contr."
            source="eanStampsContribution"
            sortable={false}
            record={formDataProps.formData}
            height={25}
            preKey={''}
          />
          <ContributionMechanicField
            label="Mecc. Bol + Contr."
            stampsQuantitySource="numStampsStampsContribution"
            contributionSource="contributionStampsContribution"
          />
          <BarcodeField
            label="EAN Pt"
            source="eanPoints"
            sortable={false}
            record={formDataProps.formData}
            height={25}
            preKey={''}
          />
          <SingleMechanicField label="Mecc. Pt" source="numPointsPoints" />
          <BarcodeField
            label="EAN Pt + Contrib."
            source="eanPointsContribution"
            sortable={false}
            record={formDataProps.formData}
            height={25}
            preKey={''}
          />
          <ContributionMechanicField
            label="Mecc. Pt + Contrib."
            pointsQuantitySource="numPointsPointsContribution"
            contributionSource="contributionPointsContribution"
          />
          <BarcodeField
            label="EAN Contr."
            source="eanContribution"
            sortable={false}
            record={formDataProps.formData}
            height={25}
            preKey={''}
          />
          <SingleMechanicField label="Mecc. Contr." source="contributionContribution" type="currency" appendix="€" />

          <BarcodeField
            label="EAN Bol + Pt + Contr."
            source="eanStampsPointsContribution"
            sortable={false}
            record={formDataProps.formData}
            height={25}
            preKey={''}
          />

          <ContributionMechanicField
            label="Mecc. Bol + Pt + Contrib."
            stampsQuantitySource="numStampsStampsPointsContribution"
            pointsQuantitySource="numPointsStampsPointsContribution"
            contributionSource="contributionStampsPointsContribution"
          />

          <DeleteProductButton />
        </Datagrid>
      </ArrayField>
    </div>
  )
}

const DeleteProductButton: FC<any> = (props) => {
  const { values } = useFormState()
  const form = useForm()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const classes = useStyles()

  const onClose = useCallback((): void => setIsDialogOpen(false), [])
  const onClick = useCallback((): void => setIsDialogOpen(true), [])

  // need to save updated table to localstorage here
  const handleDelete = useCallback((): void => {
    const indexToDelete = values.products.findIndex((elem: any) => elem.id === props.record.id)
    const updated = [...values.products.slice(0, indexToDelete), ...values.products.slice(indexToDelete + 1)]
    form.change('products', updated)
  }, [values, props?.record])

  return (
    <>
      <IconButton onClick={onClick} className={classes.iconDelete}>
        <FaTrashAlt fontSize="1rem" />
      </IconButton>
      <Confirm
        isOpen={isDialogOpen}
        title={`Cancellare il prodotto ${props.record.name} ?`}
        content="ra.message.delete_content"
        onConfirm={handleDelete}
        onClose={onClose}
      />
    </>
  )
}

const SaveShortCollection: FC<any> = (props) => {
  const { values } = useFormState()
  const client = useApolloClient()
  const [saving, setSaving] = useState(false)
  const notify = useNotify()
  const redirect = useRedirect()

  const handleSave = async (): Promise<any> => {
    try {
      setSaving(true)

      const { data: shortCollData } = await client.mutate({
        mutation: MUTATION_CREATE_SHORTCOLLECTION,
        variables: {
          data: {
            name: values.name,
            active: values.active,
            description: values.description,
            startDate: values.startDate,
            endDate: values.endDate,
            brand: values.brand,
            cleanUpDate: values.cleanUpDate,
            isPointsBurner: values.isPointsBurner,
            picture: values.picture ? { file: values.picture } : null,
            rules: values.collectionRules.map((item: any) => {
              const { areaIds, zoneIds, ...rest } = item
              return {
                coopAreaIds: areaIds,
                coopZoneIds: zoneIds,
                ...rest,
              }
            }),
            products: values.products.map((item: any) => {
              return {
                name: item.name,
                description: item.description,
                eanStamps: item.eanStamps,
                eanStampsContribution: item.eanStampsContribution,
                eanPoints: item.eanPoints,
                eanPointsContribution: item.eanPointsContribution,
                eanContribution: item.eanContribution,
                numStampsStamps: +item.numStampsStamps,
                numStampsStampsContribution: +item.numStampsStampsContribution,
                contributionStampsContribution: +item.contributionStampsContribution,
                numPointsPoints: +item.numPointsPoints,
                numPointsPointsContribution: +item.numPointsPointsContribution,
                contributionPointsContribution: +item.contributionPointsContribution,
                contributionContribution: +item.contributionContribution,
                eanStampsPointsContribution: item.eanStampsPointsContribution,
                numStampsStampsPointsContribution: +item.numStampsStampsPointsContribution,
                numPointsStampsPointsContribution: +item.numPointsStampsPointsContribution,
                contributionStampsPointsContribution: +item.contributionStampsPointsContribution,
              }
            }),
          },
        },
      })
      if (
        shortCollData &&
        shortCollData.createShortCollection &&
        shortCollData.createShortCollection.id === undefined
      ) {
        throw new Error('Error creating Short Collection')
      }

      localStorage.removeItem(`_wizard_${props.resource}_create`)
      notify('Short Collection creata con successo!')
      redirect('/ShortCollection')
    } catch (error) {
      console.log('BAD THINGS HAPPENED: ', error)
      notify('Errore nella creazione della Short Collection', 'error')
    } finally {
      setSaving(false)
    }
  }

  return (
    <SaveButton
      {...props}
      variant="contained"
      saving={saving}
      disabled={saving}
      handleSubmitWithRedirect={handleSave}
    />
  )
}

const ShortCollectionActiveSwitch: FC<any> = (props) => {
  const { values } = useFormState()
  const translate = useTranslate()

  return (
    <SwitchCardInput
      {...props}
      record={values}
      source="active"
      label={translate('resources.ShortCollection.fields.activeExtended')}
      startingValue={true}
    />
  )
}

const ShortCollectionCreate: FC<Props> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Create {...props} component="div">
      <WizardSectionedForm variant="outlined" redirect="list" title={translate('resources.ShortCollection.new')}>
        <WizardStep
          formRightControls={<WizardRightControls asideUpperContent={<ShortCollectionActiveSwitch {...props} />} />}
        >
          <FormSection title={translate('resources.ShortCollection.sections.shortCollection')}>
            <FormTextInput
              {...props}
              source="name"
              helperText="Inserisci il nome della short collection"
              validate={required()}
            />
            <TextInput multiline source="description" className={classes.description} />
          </FormSection>
          <FormSection
            title="Regole Short Collection"
            hideCardHeader
            wrapperComponent={<React.Fragment />}
            contentComponent={<React.Fragment />}
          >
            <ShortCollectionRulesFormSection defaultValue={[undefined]} />
          </FormSection>

          <FormSection title={translate('resources.ShortCollection.sections.availability')}>
            <FormDateInput
              source="startDate"
              validate={required()}
              helperText="Data di inizio validità short collection"
            />
            <FormDateInput source="endDate" validate={required()} helperText="Data di fine validità short collection" />
            <FormDateInput source="cleanUpDate" validate={required()} helperText="Data di clean-up short collection" />
          </FormSection>
          <FormSection title={translate('resources.ShortCollection.sections.brand')}>
            <FormTextInput {...props} source="brand" helperText="Inserisci il nome del brand" validate={required()} />
            <ImageInput
              source="picture"
              accept="image/*"
              classes={{
                root: classes.imgRoot,
                dropZone: classes.imgDropZoneLarge,
                removeButton: classes.imgRemoveButton,
              }}
              helperText=""
            >
              <ImageField source="src" title="title" classes={{ image: classes.imageLarge }} />
            </ImageInput>
          </FormSection>
        </WizardStep>
        <WizardStep
          formRightControls={
            <WizardRightControls
              saveButtonComponent={<SaveShortCollection />}
              asideLowerContent={<AddProductButtonDrawer {...props} />}
            />
          }
        >
          <FormDataConsumer>
            {(formDataProps: any): React.ReactNode => <ProductsTable formDataProps={formDataProps} />}
          </FormDataConsumer>
        </WizardStep>
      </WizardSectionedForm>
    </Create>
  )
}

const useStyles = makeStyles((theme) => ({
  description: {
    width: '100%',
  },
  drawer: {
    width: '560px',
    minWidth: 350,
  },
  drawerContainer: {
    padding: theme.spacing(10),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  rootInput: {
    maxWidth: '48%',
    marginRight: theme.spacing(3),
  },
  createButton: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '100%',
  },
  addButton: {
    backgroundColor: theme.palette.common.white,
  },
  imgDropZoneLarge: {
    padding: theme.spacing(8),
    outline: 'none',
    width: '100%',
  },
  imgRoot: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .previews': {
      display: 'flex',
      alignSelf: 'flex-start',
      width: '100%',
      overflow: 'scroll',
      marginLeft: theme.spacing(6),
    },
  },
  imageLarge: {
    borderRadius: theme.shape.borderRadius,
    maxWidth: '150px',
    margin: 0,
  },
  imgRemoveButton: {
    position: 'relative',
    '& button': {
      position: 'absolute',
      left: '5px',
      top: '5px',
      padding: 0,
      backgroundColor: theme.palette.background.paper,
    },
    '& button svg': {
      width: '0.8em',
      height: '0.8em',
    },
  },
  iconDelete: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 29,
    height: 33,
    borderRadius: theme.shape.borderRadius,
  },
  halfWidth: {
    maxWidth: '48%',
  },
}))

export default ShortCollectionCreate
