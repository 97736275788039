import { Box } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { ProductReservationStatusColors, ProductReservationStatusEnum } from '../customEnums'
import { ProductReservationStatus } from '../types'
import ProductReservationUpdateStatusField from './ProductReservationUpdateStatusField'
import StatusInfoLabel, { StatusInfoLabelDefaultColors } from './StatusInfoLabel'

type Props = {
  label?: string
  record?: any
  source?: string
}

const ProductReservationStatusFieldMenu: FC<Props> = ({ label, record, source = 'productReservationStatus' }) => {
  const currentStatus: ProductReservationStatus = useMemo(
    () => (record && source && record[source] ? record[source] : undefined),
    [record, source]
  )

  if (!currentStatus) {
    return <StatusInfoLabel color={StatusInfoLabelDefaultColors.INACTIVE} label="N.D." />
  }

  return (
    <Box display="flex">
      <StatusInfoLabel
        color={ProductReservationStatusColors[currentStatus]}
        label={ProductReservationStatusEnum[currentStatus] || 'N.D.'}
      />
      <Box ml={2} />
      <ProductReservationUpdateStatusField {...{ label, record, source }} />
    </Box>
  )
}

export default ProductReservationStatusFieldMenu
