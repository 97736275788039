import React, { FC } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, RadioButtonGroupInput, DateInput } from 'react-admin'

import Datagrid from './../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import { FaGifts } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import BooleanStatusField from '../../components/BooleanStatusField'
import { DateField } from 'react-admin'
import Filter from '../../components/list/filter/Filter'
import { usePermissions, useTranslate } from 'ra-core'
import { hasPermission } from '../../utils/hasPermission'

type Props = {
  //
}

const ShortCollectionFilters: FC<any> = (props) => {
  const translate = useTranslate()
  return (
    <Filter {...props}>
      <FilterTextInput source="name" alwaysOn />
      <DateInput source="dateRange.startDate" />
      <DateInput source="dateRange.endDate" />
      <DateInput source="cleanUpDate" />
      <RadioButtonGroupInput
        // label={'Pubblica - Privata'}
        source="active"
        choices={[
          { id: true, name: translate('ra.boolean.isPublic.F.true') },
          { id: false, name: translate('ra.boolean.isPublic.F.false') },
        ]}
      />
    </Filter>
  )
}

const ShortCollectionList: FC<Props> = (props) => {
  const translate = useTranslate()
  const { loaded, permissions } = usePermissions()
  return (
    <RAList
      {...props}
      title="Short Collection"
      titleIcon={<FaGifts />}
      actions={
        loaded && hasPermission(permissions) ? (
          <CreateButton label={translate('resources.ShortCollection.new')} />
        ) : (
          <></>
        )
      }
      filters={<ShortCollectionFilters variant="outlined" />}
      bulkActionButtons={loaded && hasPermission(permissions) ? undefined : false}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="name" />
        <DateField source="startDate" />
        <DateField source="endDate" />
        <DateField source="cleanUpDate" />
        <NumberField source="noOfProducts" />
        {loaded && hasPermission(permissions) && (
          <BooleanStatusField
            source="active"
            trueValue={translate('ra.boolean.isPublic.F.true')}
            falseValue={translate('ra.boolean.isPublic.F.false')}
          />
        )}
        {loaded && hasPermission(permissions) && <GenericMoreMenu />}
      </Datagrid>
    </RAList>
  )
}

export default ShortCollectionList
