import { Button, ButtonGroup, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { FC, useCallback, useState } from 'react'
import CodeRoundedIcon from '@material-ui/icons/CodeRounded'
import TableChartRoundedIcon from '@material-ui/icons/TableChartRounded'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import ReactJson from '@textea/json-viewer'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const JSONViewer = require('./react-json-viewer').default

type Props = {
  result?: any
}
export const ResultPreview: FC<Props> = ({ result }) => {
  const classes = useStyles()
  const [mode, setMode] = useState<'tree' | 'table'>('table')

  const handleModeChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newAlignment: 'tree' | 'table' | undefined) => {
      if (newAlignment) {
        setMode(newAlignment)
      }
    },
    []
  )

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        {/* <ButtonGroup size="small" variant="contained" aria-label="small outlined button group">
          <Button className={classes.button}>
            <CodeRoundedIcon className={classes.toolbarIcon} />
          </Button>
          <Button className={classes.button}>
            <TableChartRoundedIcon className={classes.toolbarIcon} />
          </Button>
        </ButtonGroup> */}

        <ToggleButtonGroup
          size="small"
          value={mode}
          exclusive
          onChange={handleModeChange}
          aria-label="mode view switcher"
        >
          <ToggleButton className={classes.button} value="tree" aria-label="tree">
            <CodeRoundedIcon className={classes.toolbarIcon} />
          </ToggleButton>
          <ToggleButton className={classes.button} value="table" aria-label="table">
            <TableChartRoundedIcon className={classes.toolbarIcon} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.content}>
        {!result?.data ? (
          <div>Empty result</div>
        ) : mode === 'tree' ? (
          <ReactJson src={result.data} />
        ) : (
          <JSONViewer json={result.data} />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(1, 2, 1, 2),
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    height: 30,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // margin: theme.spacing(1),
  },
  content: {
    overflow: 'scroll',
    height: '100%',
    paddingBottom: 50,
  },
  toolbarIcon: {
    fontSize: '18px',
  },
}))
