import React, { FC } from 'react'
import TextField from '@material-ui/core/TextField'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 8, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}
const defaultMaskOptions13 = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 13, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

type Props = {
  variant?: string
  label?: string
  name?: string
  value: string
  onChange: (event: any) => void
  classes?: Record<string, any>
  isEan13?: boolean
  [x: string]: any
}

const MuiNumberInput: FC<Props> = ({
  variant = 'outlined',
  label,
  name,
  value,
  onChange,
  classes,
  isEan13 = false,
  ...props
}) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      InputProps={{
        classes: { ...classes },
        inputComponent: isEan13 ? (TextMaskCustom13 as any) : (TextMaskCustom as any),
      }}
      {...props}
    />
  )
}

const TextMaskCustom: FC<any> = (props: any) => {
  const { inputRef, ...other } = props
  const currencyMask = createNumberMask(defaultMaskOptions)

  return <MaskedInput {...other} ref={(ref: any): any => inputRef(ref ? ref.inputElement : null)} mask={currencyMask} />
}

const TextMaskCustom13: FC<any> = (props: any) => {
  const { inputRef, ...other } = props
  const currencyMask = createNumberMask(defaultMaskOptions13)

  return <MaskedInput {...other} ref={(ref: any): any => inputRef(ref ? ref.inputElement : null)} mask={currencyMask} />
}

export default MuiNumberInput

export const formatNumberInput = (value: string): any => (value ? parseInt(value) : value)
