import { client } from './apolloClient'
import gql from 'graphql-tag'

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file.rawFile)

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = (requestHandler) => async (type, resource, params) => {
  // console.log('PICTURE UPLOAD 1', params, type, resource, requestHandler)

  if ((type === 'GET_LIST' || type === 'GET_MANY') && resource === 'OperatorProductStock') {
    return requestHandler(type, 'Product', params)
  }

  if ((type === 'CREATE' || type === 'UPDATE') && (resource === 'Picture' || resource === 'Document')) {
    // console.log('PICTURE UPLOAD 2', params)
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    if (params.data.file && params.data.file.rawFile instanceof File) {
      // console.log('RIGA 27 :', params.data.file.rawFile)
      // only freshly dropped pictures are instance of File
      // const uploadedFile = await client.mutate({
      //   mutation: gql`uploadPicture($file: Upload!){
      //     uploadPicture(file: $file) {
      //       id
      //     }
      //   }`
      // })
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          file: params.data.file.rawFile,
        },
      })
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params)
}

export default addUploadFeature
