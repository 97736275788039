import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar, { AvatarProps } from '@material-ui/core/Avatar'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 52,
  },
  avatar: {
    backgroundColor: 'white',
    color: theme.palette.secondary.dark,
    width: '45px',
    height: '45px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
  },
  avatarButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    marginLeft: theme.spacing(4),
  },
}))

type Props = {
  title?: string | React.ReactNode
  defaultTitle?: string
  icon?: React.ReactNode
  avatarButton?: boolean
  avatarOnClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  classes?: {
    root?: string
    avatar?: string
    avatarButton?: string
    title?: string
  }
  avatarProps?: AvatarProps
  titleProps?: TypographyProps
  disableGoBack?: boolean
}

const Title: FC<Props> = (props) => {
  const { defaultTitle, title, icon, avatarButton, disableGoBack, avatarProps = {}, titleProps = {} } = props
  const classes = useStyles(props)
  const history = useHistory()

  const handleClick = (): void => {
    if (disableGoBack) return
    history.goBack()
  }

  return (
    <div className={classes.root}>
      {icon && (
        <Avatar
          className={clsx(classes.avatar, avatarButton && classes.avatarButton)}
          variant="rounded"
          onClick={handleClick}
          {...avatarProps}
        >
          {icon}
        </Avatar>
      )}
      {(title && typeof title === 'string') || !title ? (
        <Typography variant="h1" className={classes.title} {...titleProps}>
          {title ? title : defaultTitle}
        </Typography>
      ) : (
        title
      )}
    </div>
  )
}

export default Title
