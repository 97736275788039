import React, { FC, ReactNode } from 'react'
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  AccordionDetails as MuiAccordionDetails,
  makeStyles,
  createStyles,
  AccordionProps,
  withStyles,
  AccordionSummaryProps,
  AccordionDetailsProps,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    border: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    paddingLeft: '16px',
    paddingRight: '38px',
    minHeight: 56,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    '&$expanded': {
      minHeight: 56,
      borderBottom: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expandIcon: {
    width: 42,
    height: 42,
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles({})(MuiAccordionDetails)

export type PerimeterAccordionProps = {
  //
  title?: ReactNode
  SummaryProps?: AccordionSummaryProps
  DetailsProps?: AccordionDetailsProps
} & Partial<AccordionProps>

export const PerimeterAccordion: FC<PerimeterAccordionProps> = ({
  children,
  title,
  SummaryProps = {},
  DetailsProps = {},
  ...props
}) => {
  const classes = useStyles()

  return (
    <Accordion {...props}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        {...SummaryProps}
      >
        {typeof title === 'string' ? (
          <Typography className={classes.heading}>{title}</Typography>
        ) : (
          title || <Typography className={classes.heading}>Seleziona Elementi</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails} {...DetailsProps}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
)
