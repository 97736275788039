import { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client/react'
import { QUERY_GET_PERIMETER_AWARE_SHORT_COLLECTIONS } from '../queries'
import { NetworkStatus } from '@apollo/client'

type Choice = {
  id: string
  name: string
}

export function useShortCollections(): {
  choices: Choice[]
  reload: () => void
  isReloading: boolean
  isEmpty: boolean
} {
  const { data, refetch, networkStatus } = useQuery(QUERY_GET_PERIMETER_AWARE_SHORT_COLLECTIONS, {
    variables: {
      filters: { active: true, notExpired: true },
    },
    notifyOnNetworkStatusChange: true,
  })

  const choices: Choice[] = useMemo(
    () => [
      ...(data?.shortCollections?.data
        ? data.shortCollections.data.map((item: any) => ({
            id: item.id,
            name: item.name,
          }))
        : []),
    ],
    [data]
  )

  const reload = useCallback(() => refetch(), [refetch])
  const isEmpty = data?.shortCollections !== undefined && choices.length === 0

  return {
    choices,
    reload,
    isReloading: networkStatus === NetworkStatus.refetch,
    isEmpty,
  }
}
