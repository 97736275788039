import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { sanitizeListRestProps, useListContext } from 'ra-core'
import { ClassesOverride } from 'ra-ui-materialui'
import InlineFilterForm from './InlineFilterForm'
import DrawerHeader from '../../DrawerHeader'
import EnhancedDrawerFilterForm from './DrawerFilterForm'
import Drawer from '../../Drawer'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    button: {},
    inlineForm: {},
    drawerForm: {},
    removeFiltersButton: {
      padding: 0,
    },
  }),
  { name: 'HyFilter' }
)

type Props = {
  children: ReactNode
  classes?: ClassesOverride<typeof useStyles>
  context?: 'form' | 'button'
  variant?: string
}

const Filter: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { resource, setFilters, displayedFilters, filterValues, currentSort } = useListContext(props)
  const { classes: classesOverride, context, children, ...rest } = props
  const filters = useMemo(() => React.Children.toArray(children), [props.children])
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(true)
  }, [])

  const deleteAllFilters = useCallback(() => {
    setFilters({}, {})
    handleCloseDrawer()
  }, [currentSort]) // keep dep here to not loose the current sort

  return props.context === 'button' ? (
    <React.Fragment />
  ) : (
    <div className={classes.root}>
      <InlineFilterForm
        className={classes.inlineForm}
        resource={resource}
        filters={filters}
        displayedFilters={displayedFilters}
        initialValues={filterValues}
        setFilters={setFilters}
        handleOpenDrawer={handleOpenDrawer}
        {...sanitizeListRestProps(rest)}
      />
      <Drawer open={isDrawerOpen} onClose={handleCloseDrawer}>
        <DrawerHeader
          buttonOnClick={handleCloseDrawer}
          title="Filtri"
          subtitle={
            <Button color="primary" variant="text" className={classes.removeFiltersButton} onClick={deleteAllFilters}>
              Rimuovi filtri
            </Button>
          }
        />
        <EnhancedDrawerFilterForm
          className={classes.drawerForm}
          resource={resource}
          filters={filters}
          displayedFilters={displayedFilters}
          initialValues={filterValues}
          setFilters={setFilters}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          {...sanitizeListRestProps(rest)}
        />
      </Drawer>
    </div>
  )
}

export default Filter
