// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const hasPermission = (permissions: any): boolean => {
  if (!permissions || !permissions.user || !permissions.params) {
    return false
  }

  if (permissions.params.limitedToOwnSalesPoint) {
    return false
  }

  return true
}
