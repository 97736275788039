import { OutlinedInput, Typography, makeStyles } from '@material-ui/core'
import { FC, forwardRef, useCallback, useState } from 'react'
import 'react-phone-number-input/style.css'
import { InputProps, useInput, useTranslate } from 'ra-core'
import Input from 'react-phone-number-input/input'
import * as Sentry from '@sentry/browser'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    width: '50%',
    alignSelf: 'stretch',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  helper: {
    width: '50%',
    marginLeft: theme.spacing(8),
  },
}))

type FormTextInputProps = {
  helperText?: string
  source?: string
  [x: string]: any
} & InputProps<any>

type CustomInputProps = {
  // isRequired?: boolean
  // error?: any
  // touched?: boolean | undefined
  // value?: string
} & Record<string, any>

export const TextPhoneInput: FC = forwardRef<CustomInputProps, any>((props, ref) => {
  const translate = useTranslate()
  return (
    <OutlinedInput
      style={{ fontSize: '16px', lineHeight: '1.18em', height: '2.5rem', width: '100%' }}
      placeholder={translate('resources.Customer.fields.phone') as string}
      inputRef={ref}
      inputProps={{
        maxLength: 19,
        onChange: props.onChange,
        value: props.value,
        'data-trace-id': 'coop-tosco-phone-input',
      }}
      value={props.value}
      onChange={props.onChange}
    />
  )
})

const FormPhoneNumberInput: FC<FormTextInputProps> = (props) => {
  const classes = useStyles()

  const {
    input: { name, onChange, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const handlePhoneChange = useCallback(
    (e: string | undefined) => {
      Sentry.addBreadcrumb({
        category: 'FormPhoneNumberInput',
        message: 'handlePhoneChange',
        level: 'log',
        data: {
          currentVal: value,
          newVal: e,
        },
      })

      onChange(e)
    },
    [onChange, value]
  )

  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputWrapper}>
        <Input
          data-trace-id="coop-tosco-phone-input"
          // labels={it}
          defaultCountry="IT"
          inputComponent={TextPhoneInput as any}
          value={value}
          onChange={handlePhoneChange}
          smartCaret
          // useNationalFormatForDefaultCountryValue
          {...props}
        />
      </div>
      <Typography className={classes.helper} variant="body2">
        {props.helperText}
      </Typography>
    </div>
  )
}

export default FormPhoneNumberInput
