import React, { FC, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import BaseButton from './button/BaseButton'
import { useHistory } from 'react-router-dom'

type Props = {
  title: string
  buttonLabel?: string
  subtitle?: React.ReactElement
  buttonOnClick?: () => void
  classes?: {
    root?: string
    title?: string
    button?: string
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    maxWidth: 189,
    textOverflow: 'ellipsis',
  },
  subtitle: {},
  button: {},
}))

const DrawerHeader: FC<Props> = (props) => {
  const history = useHistory()
  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])
  const { title, buttonLabel = 'Annulla', buttonOnClick = handleGoBack } = props
  const classes = useStyles(props)

  return (
    <Box width="100%" display="flex" mb={5} justifyContent="space-between" alignItems="center" className={classes.root}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h2" className={classes.title}>
          {props.title}
        </Typography>
        {props.subtitle && (
          <Typography variant="body2" className={classes.subtitle}>
            {props.subtitle}
          </Typography>
        )}
      </Box>

      <BaseButton onClick={buttonOnClick} className={classes.button}>
        {buttonLabel}
      </BaseButton>
    </Box>
  )
}

export default DrawerHeader
