import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import BaseTypography from './BaseTypography'
import { QUERY_ALREADY_ASSIGNED_PRODUCTS_TOTAL } from './../queries'

type Props = {
  label?: string
  record?: Record<string, any>
  productIdSource?: string
  [x: string]: any
}

const AlreadyAssignedProductsField: FC<Props> = ({ record = {}, productIdSource = 'productId' }) => {
  const { loading, data } = useQuery(QUERY_ALREADY_ASSIGNED_PRODUCTS_TOTAL, {
    variables: {
      filters: {
        salesPointId: record.salesPointId,
        productId: record[productIdSource],
        productReservationStatus: ['CUSTOMER_NOTIFIED', 'WAITING_FOR_AVAILABLE_NOTIFICATION'],
      },
    },
    fetchPolicy: 'network-only',
  })

  if (loading) return <LinearProgress />
  if (data)
    return (
      <BaseTypography variant="body2" weight="bold" color={data.productReservationsCount > 0 ? 'primary' : undefined}>
        {`${data.productReservationsCount}${data.productReservationsCount > 0 ? ' già assegnati' : ''}`}
      </BaseTypography>
    )
  return null
}

export default AlreadyAssignedProductsField
