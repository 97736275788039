import { UserManager } from 'oidc-client'
const {
  REACT_APP_OIDC_AUTHORITY,
  REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_OIDC_REDIRECT_URI,
  REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
} = process.env

export default new UserManager({
  authority: REACT_APP_OIDC_AUTHORITY,
  client_id: REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: REACT_APP_OIDC_REDIRECT_URI,
  response_type: 'code',
  scope: 'openid profile',
  acr_values: 'Level3',
  ui_locales: 'nb',
  loadUserInfo: false,
  revokeAccessTokenOnSignout: true,
  post_logout_redirect_uri: REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
})
