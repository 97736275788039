import React, { FC, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink, useHistory } from 'react-router-dom'
import { useFormState } from 'react-final-form'
import FormRightControls from '../FormRightControls'
import SaveButton from '../../button/SaveButton'
import { FaLongArrowAltRight } from 'react-icons/fa'
import BaseButton from '../../button/BaseButton'
import { HiArrowNarrowRight } from 'react-icons/hi'

type WizardProps = {
  resource?: string
  record?: Record<string, any>
  basePath?: string
  wizardBasePath?: string
  currentStep?: number
  hasNextStep?: boolean
  hasPreviousStep?: boolean
  nextStepTo?: string
  children?: React.ReactNode
  asideUpperContent?: React.ReactNode
  asideLowerContent?: React.ReactNode
  saveButtonComponent?: React.ReactNode
  undoButtonComponent?: React.ReactNode
  [x: string]: any
}

const WizardRightControls: FC<WizardProps> = ({
  resource,
  record = {},
  basePath,
  currentStep,
  hasNextStep,
  hasPreviousStep,
  nextStepTo,
  children,
  asideUpperContent,
  asideLowerContent,
  saveButtonComponent,
  undoButtonComponent,
  ...rest
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { values, valid } = useFormState()

  const handleGoForwardClick = useCallback(() => {
    if (hasNextStep && nextStepTo && valid) {
      if (!record.id) {
        localStorage.setItem(`_wizard_${resource}_create`, JSON.stringify(values))
      }
      history.push(nextStepTo)
    }
  }, [nextStepTo, resource, record, values, valid])

  const handleSuccess = useCallback(() => {
    if (!record.id && localStorage.getItem(`_wizard_${resource}_create`)) {
      localStorage.removeItem(`_wizard_${resource}_create`)
    }
  }, [])

  return (
    <FormRightControls
      record={record}
      basePath={basePath}
      resource={resource}
      undoButtonComponent={
        undoButtonComponent ? (
          undoButtonComponent
        ) : hasPreviousStep ? (
          <Button className={classes.button} size="large" variant="outlined" onClick={history.goBack}>
            Indietro
          </Button>
        ) : undefined
      }
      saveButtonComponent={
        saveButtonComponent ? (
          saveButtonComponent
        ) : hasNextStep ? (
          <BaseButton
            color="primary"
            size="xl"
            variant="contained"
            fullWidth
            className={classes.createButton}
            onClick={handleGoForwardClick}
            endIcon={<HiArrowNarrowRight />}
            // component={NavLink}
            // to={hasNextStep && nextStepTo ? nextStepTo : ''}
            disabled={!valid}
          >
            Procedi
          </BaseButton>
        ) : (
          <SaveButton
            resource={resource}
            record={record}
            basePath={basePath}
            {...rest}
            variant="contained"
            onSuccess={handleSuccess}
          />
        )
      }
      asideUpperContent={asideUpperContent}
      asideLowerContent={asideLowerContent}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: theme.spacing(13),
    marginLeft: theme.spacing(6),
    padding: 0,
    maxWidth: 220,
  },
  createButton: {
    marginTop: theme.spacing(3),
  },
  sideWrapper: {
    width: '100%',
  },
  button: {
    width: 'inherit',
  },
  saveButton: {
    marginTop: theme.spacing(3),
  },
  spaceDivider: {
    height: theme.spacing(7),
  },
}))

export default WizardRightControls
