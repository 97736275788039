import React, { FC, useCallback, useEffect, useState } from 'react'
import Edit from '../../components/details/Edit'
import FormSection from '../../components/forms/FormSection'
import { useForm, useFormState } from 'react-final-form'
import WizardSectionedForm, { WizardStep } from '../../components/forms/WizardSectionedForm'
import ReservationFormSection from './ReservationFormSection'
import ReservationCustomerFormSection from './ReservationCustomerFormSection'
import WizardRightControls from '../../components/forms/WizardSectionedForm/WizardRightControls'
import ReservationProductsTable from './ReservationProductsTable'
import { gql } from 'apollo-boost'
import { useNotify } from 'ra-core'
import { useApolloClient, useQuery } from '@apollo/client'
import Loading from '../../components/Loading'
import { Route, useHistory, useLocation } from 'react-router-dom'
import Drawer from '../../components/Drawer'
import DrawerHeader from '../../components/DrawerHeader'
import AddProductToReservationForm from './AddProductToReservationForm'
import { QUERY_GET_PRODUCT_FOR_RESERVATION_TABLE, QUERY_GET_SHORT_COLLECTIONS } from '../../queries'

const QUERY_GET_EDIT_RESERVATION_PRODUCTS = gql`
  query GetEditReservationProducts($reservationId: ID) {
    productReservations(filters: { reservationId: $reservationId }) {
      data {
        id
        productReservationStatus
        product {
          id
          name
          shortCollection {
            id
            name
          }
        }
      }
    }
  }
`

type Props = {
  [x: string]: any
}

// const InitializeCustomer: FC<any> = (props) => {
//   const dataProvider = useDataProvider()
//   const form = useForm()
//   const { values } = useFormState()
//   const notify = useNotify()

//   useEffect(() => {
//     const fetchReservationCustomer = async (): Promise<void> => {
//       try {
//         const result = await dataProvider.getOne('ReservationCustomer', {
//           id: props.record.reservationCustomerId,
//         })
//         form.change('ean', result.data.ean)
//         form.change('firstName', result.data.firstName)
//         form.change('lastName', result.data.lastName)
//         form.change('email', result.data.email)
//         form.change('phone', result.data.phone)
//         props.setIsCustomerFetched(true)
//       } catch (e) {
//         console.error('error in fetchReservationCustomer()', e)
//         notify('ra.message.error', 'error')
//       }
//     }
//     if (!props.isCustomerFetched && values && values.id && props.record && props.record.reservationCustomerId) {
//       fetchReservationCustomer()
//     }
//   }, [])

//   return null
// }

const SaveReservationButton: FC = () => {
  return <div id="save-reservation-button" />
}

const InitializeShortCollection: FC<any> = (props) => {
  const { data } = useQuery(QUERY_GET_SHORT_COLLECTIONS)
  const { values } = useFormState()
  const form = useForm()

  useEffect(() => {
    if (
      data &&
      data.shortCollections &&
      data.shortCollections.data &&
      !values['shortCollectionId'] &&
      props.record.id
    ) {
      const foundedShortCollection = data.shortCollections.data.find((item: any) => {
        return item.id === props.record['shortCollectionId']
      })
      if (foundedShortCollection) {
        form.change('shortCollectionId', foundedShortCollection.id)
      }
    }
  }, [data])

  return null
}

const TitleBar: FC = () => {
  return <div id="reservation-stock-subtitle" />
}

const AddProductButtonPortal: FC = () => {
  return <div id="add-product-button" />
}

const ReservationEdit: FC<Props> = (props) => {
  const notify = useNotify()
  const client = useApolloClient()
  const history = useHistory()
  const { search, pathname } = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [reservationProductIds, setReservationProductIds] = useState<string[]>([])
  const [reservationProductsTable, setReservationProductsTable] = useState<Record<string, any>>({})
  const [newReservationProductIds, setNewReservationProductIds] = useState<string[]>([])
  const [productsToRemoveIds, setProductsToRemoveIds] = useState<string[]>([])
  const [newReservationProductsTable, setNewReservationProductsTable] = useState<Record<string, any>>({})
  const [isCustomerFetched, setIsCustomerFetched] = useState<boolean>(false)

  const handleClose = useCallback(() => {
    history.goBack()
  }, [search])

  const handleAddReservationProduct = useCallback(
    async (values: any) => {
      try {
        if (!newReservationProductsTable[values.productId]) {
          const result = await client.query({
            query: QUERY_GET_PRODUCT_FOR_RESERVATION_TABLE,
            variables: {
              id: values.productId,
            },
          })

          if (!result.data || !result.data.product) {
            throw new Error('Error fetching product')
          }

          setNewReservationProductsTable({
            ...newReservationProductsTable,
            [values.productId]: result.data.product,
          })
        }

        setNewReservationProductIds([
          ...newReservationProductIds,
          ...Array.from({ length: parseInt(values.quantity) }, () => {
            return values.productId
          }),
        ])

        handleClose()
      } catch (e) {
        console.error('Error adding ReservationProduct', e)
        notify('ra.message.error', 'warning')
      }
    },
    [
      reservationProductIds,
      reservationProductsTable,
      handleClose,
      newReservationProductIds,
      newReservationProductsTable,
    ]
  )

  const handleRemoveNewProduct = useCallback(
    (index: number) => {
      const newReservationProducts = [...newReservationProductIds]
      newReservationProducts.splice(index, 1)
      setNewReservationProductIds(newReservationProducts)
    },
    [newReservationProductIds]
  )

  const handleRemoveProduct = useCallback(
    (index: number) => {
      const newReservationProducts = [...reservationProductIds]
      const newProductsToRemove = [...productsToRemoveIds, reservationProductIds[index]]
      newReservationProducts.splice(index, 1)
      setProductsToRemoveIds(newProductsToRemove)
      setReservationProductIds(newReservationProducts)
    },
    [reservationProductIds, productsToRemoveIds]
  )

  useEffect(() => {
    const fetchProducts = async (): Promise<void> => {
      try {
        setLoading(true)
        const result = await client.query({
          query: QUERY_GET_EDIT_RESERVATION_PRODUCTS,
          variables: {
            reservationId: props.id,
          },
        })
        if (result.data && result.data.productReservations && result.data.productReservations.data) {
          setReservationProductIds(
            result.data.productReservations.data.map((item: any) => {
              return item.id
            })
          )
          setReservationProductsTable(
            result.data.productReservations.data.reduce((acc: any, item: any): any => {
              return {
                ...acc,
                [item.id]: item,
              }
            }, {})
          )
        } else {
          throw new Error('reservation products not found')
        }
        // console.log('sono resuklt', result)
      } catch (error) {
        console.error('error in fetchProducts()', error)
        notify('ra.message.error', 'error')
      } finally {
        setLoading(false)
      }
    }

    // console.log(props, 'resuklt')
    if (props.id) {
      fetchProducts()
    }
  }, [props.id])

  return (
    <>
      <Edit {...props} component="div">
        <WizardSectionedForm
          variant="outlined"
          redirect="show"
          title={`Modifica Prenotazione ${props.id}`}
          titleBar={<TitleBar />}
        >
          <WizardStep>
            <FormSection title="Prenotazione">
              <ReservationFormSection />
            </FormSection>

            <FormSection title="Destinatario">
              <ReservationCustomerFormSection />
            </FormSection>
          </WizardStep>

          <WizardStep
            formRightControls={
              <WizardRightControls
                saveButtonComponent={<SaveReservationButton />}
                asideLowerContent={<AddProductButtonPortal />}
              />
            }
          >
            <InitializeShortCollection />
            {loading ? (
              <Loading />
            ) : (
              <ReservationProductsTable
                onRemoveNewProduct={handleRemoveNewProduct}
                onRemoveProduct={handleRemoveProduct}
                reservationProductIds={reservationProductIds}
                reservationProductsTable={reservationProductsTable}
                newReservationProductsTable={newReservationProductsTable}
                newReservationProductIds={newReservationProductIds}
                productsToRemoveIds={productsToRemoveIds}
              />
            )}
          </WizardStep>
        </WizardSectionedForm>
      </Edit>

      <Route path="/Reservation/:id/:step/:drawer">
        {({ match }): React.ReactNode => {
          const isCreate = match && match.params && match.params.drawer === 'addProduct'
          return (
            <Drawer open={isCreate} onClose={handleClose}>
              {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
              {isCreate && (
                <>
                  <DrawerHeader title="Aggiungi Premio" />
                  <AddProductToReservationForm {...props} onSubmit={handleAddReservationProduct} />
                </>
              )}
            </Drawer>
          )
        }}
      </Route>
    </>
  )
}

export default ReservationEdit
