import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { PerimeterAccordion, PerimeterAccordionProps } from './PerimeterAccordion'
import { useFieldArray } from 'react-final-form-arrays'
import { useForm, useFormState } from 'react-final-form'
import { useApolloClient, useQuery } from '@apollo/client'
import { QUERY_GET_COOP_ZONES, QUERY_GET_SALES_POINTS } from '../../../queries'
import { DeselectAll, SelectableGroup, SelectAll } from 'react-selectable-fast'
import { Box, Button, IconButton, List, ListItem, ListSubheader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import CoopZoneListItem from './CoopZoneListItem'
import delve from 'dlv'
import { useNotify } from 'ra-core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

type Props = {
  source: string
  areaIdsSource: string
  salesPointIdsSource: string
  defaultValue?: any
  onGoToNextStep: () => void
  alreadyTakenSalesPointIds: string[]
} & Partial<PerimeterAccordionProps>

export const CoopZoneSelectabeListInput: FC<Props> = ({
  source,
  defaultValue,
  areaIdsSource,
  salesPointIdsSource,
  onGoToNextStep,
  alreadyTakenSalesPointIds,
  ...props
}) => {
  const classes = useStyles()
  const { fields, meta } = useFieldArray(source, { defaultValue })
  const { change } = useForm()
  const { values } = useFormState()
  const [zonesSalesPointsMap, setZonesSalesPointsMap] = useState<Record<string, number>>({})
  const areaIds = useMemo(() => delve(values, areaIdsSource.replaceAll('[', '.').replaceAll(']', '')), [values])
  const salesPointIds = useMemo(() => delve(values, salesPointIdsSource.replaceAll('[', '.').replaceAll(']', '')), [
    values,
  ])
  const notify = useNotify()
  const client = useApolloClient()

  const { data, loading } = useQuery(QUERY_GET_COOP_ZONES, {
    variables: {
      pagination: {
        disabled: true,
      },
      filters: {
        coopAreaIds: areaIds,
      },
      sort: {
        name: 'ASC',
      },
    },
    skip: !areaIds?.length || areaIds.length === 0,
  })

  useEffect(() => {
    const updateMap = async (): Promise<void> => {
      try {
        const currentValues = salesPointIds || []
        if (data?.zones?.data?.length > 0) {
          const { data: salesPointsData } = await client.query({
            query: QUERY_GET_SALES_POINTS,
            variables: {
              filters: {
                coopZoneIds: data.zones.data.map((zone: any) => zone.id),
              },
              pagination: {
                disabled: true,
              },
              sort: {
                name: 'ASC',
              },
            },
          })

          if (!salesPointsData?.salesPoints?.data) {
            throw new Error('Errore nella ricezione dei Punti Vendita')
          }

          const filteredAlreadyTakenSalesPoints = (alreadyTakenSalesPointIds || []).filter(
            (item) => !(currentValues.indexOf(item) > -1)
          )

          const newMap = (salesPointsData.salesPoints.data as any[])
            .filter((item) => filteredAlreadyTakenSalesPoints.indexOf(item.id) <= -1)
            .reduce((acc, item) => {
              const newItem = { ...acc }

              if (!newItem[item.stateId]) {
                newItem[item.stateId] = 1
              }

              newItem[item.id] = newItem[item.id] + 1

              return newItem
            }, {})
          setZonesSalesPointsMap(newMap)
        }
      } catch (error) {
        console.error('Error setting salespoints Map', error)
        notify((error as any).message, 'error')
      }
    }

    updateMap()
  }, [data?.zones?.data])

  const handleResourcesSelected = useCallback(
    async (selectedComponents: any[]) => {
      //
      console.log(selectedComponents, selectedComponents.length, 'selectedComponents')
      const selectedIds = selectedComponents
        .filter((item: any) => item.props && item.props.record)
        .map((item) => item.props.record.id)

      change(source, selectedIds)

      if (selectedIds && selectedIds.length > 0) {
        try {
          // select all salesPoints available
          const { data: salesPointsData } = await client.query({
            query: QUERY_GET_SALES_POINTS,
            variables: {
              filters: {
                coopZoneIds: selectedIds,
              },
              pagination: {
                disabled: true,
              },
              sort: {
                name: 'ASC',
              },
            },
          })

          if (!salesPointsData?.salesPoints?.data) {
            throw new Error('Errore nella ricezione dei Punti Vendita')
          }

          const currentValues = salesPointIds || []
          const filteredAlreadyTakenSalesPoints = (alreadyTakenSalesPointIds || []).filter(
            (item) => !(currentValues.indexOf(item) > -1)
          )
          change(
            salesPointIdsSource,
            (salesPointsData.salesPoints.data as any[])
              .filter((item) => filteredAlreadyTakenSalesPoints.indexOf(item.id) <= -1)
              .map((item) => item.id)
          )
        } catch (error) {
          console.error('Error setting salespoint values', error)
          notify((error as any).message, 'error')
          change(salesPointIdsSource, [])
        }
      } else {
        change(salesPointIdsSource, [])
      }
    },
    [change, alreadyTakenSalesPointIds, salesPointIds]
  )

  return (
    <PerimeterAccordion disabled={!areaIds?.length || areaIds.length <= 0} {...props}>
      <SelectableGroup
        enableDeselect
        globalMouse={true}
        allowClickWithoutSelected={true}
        onSelectionFinish={handleResourcesSelected}
        className={classes.root}
        deselectOnEsc={false}
      >
        <List
          subheader={
            <ListSubheader className={classes.subheader}>
              <div className={classes.selectAllContainer}>
                {data &&
                data.coopZones &&
                data?.coopZones?.data &&
                data.coopZones.data.length === (fields.value || []).length ? (
                  <DeselectAll>
                    <IconButton className={classes.selectAllButton} size="small">
                      <CheckBoxIcon />
                    </IconButton>
                  </DeselectAll>
                ) : (
                  <SelectAll>
                    <IconButton className={classes.selectAllButton} size="small">
                      <CheckBoxOutlineBlankIcon />
                    </IconButton>
                  </SelectAll>
                )}
              </div>
              {/* {data?.coopZones?.data && data.coopZones.data.length === (fields.value || []).length ? (
                <DeselectAll>
                  <Button size="small" variant="outlined">
                    Deseleziona tutti
                  </Button>
                </DeselectAll>
              ) : (
                <SelectAll>
                  <Button size="small" variant="outlined">
                    Seleziona tutti
                  </Button>
                </SelectAll>
              )} */}
            </ListSubheader>
          }
          className={classes.list}
        >
          {loading ? (
            <Skeleton>
              <ListItem />
              <ListItem />
              <ListItem />
            </Skeleton>
          ) : (
            data?.coopZones?.data?.map((zone: any, index: number) => {
              return (
                <CoopZoneListItem
                  key={`coop-zone-list-item-${zone.id}`}
                  selectedResourceIds={fields?.value || []}
                  record={zone}
                  isSelected={fields?.value?.indexOf(zone.id) > -1 || false}
                  numberOfAvailableSalesPoints={zonesSalesPointsMap[zone.id] || 0}
                />
              )
            })
          )}
        </List>
      </SelectableGroup>

      <div className={classes.bottomActionsContainer}>
        <Box />
        <Button
          className={classes.nextStepButton}
          onClick={onGoToNextStep}
          disabled={!fields || !fields.length || fields.length <= 0}
          endIcon={<NavigateNextIcon />}
          size="small"
        >
          Seleziona Punti Vendita
        </Button>
      </div>
    </PerimeterAccordion>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  list: {
    width: '100%',
  },
  subheader: {
    // backgroundColor: theme.palette.background.paper,
  },
  selectAllContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  selectAllButton: {
    width: 42,
    height: 42,
  },
  nextStepButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(5),
  },
  bottomActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))
