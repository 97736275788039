import React, { FC, useCallback } from 'react'
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core'
import { RemoveRulesBoxMenu } from './RemoveRulesBoxMenu'
import { CollectionPerimeterInput } from './CollectionPerimeterInput'
import { ToggleButtonInput } from '../ToggleButtonInput'

type Props = {
  record?: any
  source: string
  index: number
  disableRemove?: boolean
  alreadyTakenSalesPointIds: string[]
  onRemove?: (index: number) => void
}

export const ShortCollectionRulesBox: FC<Props> = ({
  index,
  source,
  record,
  disableRemove,
  onRemove,
  alreadyTakenSalesPointIds,
  ...props
}) => {
  const classes = useStyles()

  const handleRemove = useCallback(() => {
    if (onRemove) {
      onRemove(index)
    }
  }, [onRemove, index])

  return (
    <Card className={classes.root}>
      {!disableRemove && (
        <RemoveRulesBoxMenu className={classes.removeItemMenu} disableRemove={disableRemove} onRemove={handleRemove} />
      )}

      <CardContent className={classes.content}>
        <div>
          <Typography className={classes.title} variant="h5">
            Perimetro
          </Typography>
          <CollectionPerimeterInput
            alreadyTakenSalesPointIds={alreadyTakenSalesPointIds}
            source={`${source}[${index}]`}
          />
        </div>
        <div>
          <div className={classes.mechanicsContainer}>
            <Typography className={classes.title} variant="h5">
              Meccaniche
            </Typography>

            <div className={classes.buttonsContainer}>
              <ToggleButtonInput value="stamps" source={`${source}[${index}].stamps`}>
                Bollini
              </ToggleButtonInput>
              <ToggleButtonInput value="stamps_contribution" source={`${source}[${index}].stampsContribution`}>
                Bollini + Contributo
              </ToggleButtonInput>
              <ToggleButtonInput value="points" source={`${source}[${index}].points`}>
                Punti
              </ToggleButtonInput>
              <ToggleButtonInput value="points_contribution" source={`${source}[${index}].pointsContribution`}>
                Punti + Contributo
              </ToggleButtonInput>
              <ToggleButtonInput value="contribution" source={`${source}[${index}].contribution`}>
                Contributo
              </ToggleButtonInput>
              <ToggleButtonInput
                value="stamps_points_contribution"
                source={`${source}[${index}].stampsPointsContribution`}
              >
                Bollini + Punti + Contributo
              </ToggleButtonInput>
              {/* <Button variant="outlined">Bollini + Contributo</Button>
              <Button variant="outlined">Punti</Button>
              <Button variant="outlined">Punti + Contributo</Button> */}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'visible',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(14),
    paddingRight: theme.spacing(14),
    paddingBottom: theme.spacing(6),
    position: 'relative',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  title: {
    fontWeight: 900,
    fontSize: '24px',
  },
  removeItemMenu: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(2),
  },
  mechanicsContainer: {
    position: 'sticky',
    top: 30,
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
}))
