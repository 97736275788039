import React from 'react'
import { Route } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import PageNotFound from './components/PageNotFound'
import Settings from './components/Settings'
import AuthCallback from './routes/AuthCallback'
import { LogsConsole } from './routes/LogsConsole/LogsConsole'

export default [
  <Route exact path="/callback" component={AuthCallback} />,
  // <Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/settings" component={Settings} />,
  <Route exact path="/logs-console" component={LogsConsole} />,
]
