import React, { FC } from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import SaveButton from '../button/SaveButton'

type Props = {
  saveButtonComponent?: React.ReactNode
  undoButtonComponent?: React.ReactNode
  asideUpperContent?: React.ReactNode
  asideLowerContent?: React.ReactNode
  [x: string]: any
}

const useStyles = makeStyles((theme) => ({
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // marginRight: theme.spacing(13),
    marginLeft: theme.spacing(6),
    padding: 0,
    maxWidth: 220,
    height: '100%',
    minHeight: '100%',
    position: 'sticky',
    top: theme.spacing(3),
  },
  createButton: {
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  sideWrapper: {
    width: '100%',
  },
  button: {
    width: 'inherit',
  },
  saveButton: {
    width: '100%',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  spaceDivider: {
    // height: theme.spacing(7), //WHY?
  },
}))

const FormRightControls: FC<Props> = (props) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Container className={classes.aside}>
      <div className={classes.sideWrapper}>
        {props.undoButtonComponent && React.isValidElement(props.undoButtonComponent) ? (
          React.cloneElement(props.undoButtonComponent, props)
        ) : (
          <Button className={classes.button} size="large" variant="outlined" onClick={history.goBack}>
            Annulla
          </Button>
        )}
        {props.asideUpperContent}
      </div>
      <div className={classes.sideWrapper}>
        {props.asideLowerContent}
        {props.asideLowerContent && <div className={classes.spaceDivider} />}
        {props.saveButtonComponent && React.isValidElement(props.saveButtonComponent) ? (
          React.cloneElement(props.saveButtonComponent, props)
        ) : (
          <SaveButton {...props} className={classes.saveButton} />
        )}
      </div>
    </Container>
  )
}

export default FormRightControls
