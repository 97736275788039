import React, { FC } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, DateField, TextInput, RadioButtonGroupInput } from 'react-admin'

import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import { FaUsers } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import BooleanStatusField from '../../components/BooleanStatusField'
import Filter from '../../components/list/filter/Filter'
import { ReferenceField } from 'react-admin'
import { ReferenceInput } from 'react-admin'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'

type Props = {
  //
}

const autocompleteQuery = (q: string): Record<string, string> => ({ q })
const optionText = (record: any): string => (record?.id ? `${record.name} (${record.sicma})` : 'Tutti i Punti Vendita')

const UserFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca per email" source="email" alwaysOn />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <ReferenceInput
        label="Punto Vendita"
        source="salesPointId"
        reference="SalesPoint"
        link={false}
        filterToQuery={autocompleteQuery}
      >
        <AutocompleteInputInDrawer optionText={optionText} fullWidth />
      </ReferenceInput>
      <RadioButtonGroupInput
        label={'Attivo - Inattivo'}
        source="active"
        choices={[
          { id: true, name: 'Attivo' },
          { id: false, name: 'Inattivo' },
        ]}
      />
    </Filter>
  )
}

const UserList: FC<Props> = (props) => {
  return (
    <RAList
      {...props}
      sort={{ field: 'lastName', order: 'ASC' }}
      title="Utenti"
      titleIcon={<FaUsers />}
      actions={<CreateButton label="Nuovo Utente" />}
      filters={<UserFilters variant="outlined" />}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" textAlign="left" />
        <TextField source="fullName" />
        <DateField source="createdAt" />
        <TextField source="email" />
        <ReferenceField link={false} label="Punto Vendita" source="salesPointId" reference="SalesPoint">
          <TextField source="name" />
        </ReferenceField>
        <BooleanStatusField source="active" trueValue="Attivo" falseValue="Inattivo" label="Stato" />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default UserList
