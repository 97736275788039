import React from 'react'
import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { RecoilRoot } from 'recoil'
import { ChakraProvider } from '@chakra-ui/react'
import * as serviceWorker from './serviceWorker'
import './assets/fonts/Avenir-Book.ttf'
import './assets/fonts/Avenir-Heavy.ttf'
import './assets/fonts/Avenir-Medium.ttf'
// import { Font } from '@react-pdf/renderer'
import { theme } from './chakra-theme'

// Font.register({
//   family: 'Libre Barcode 128',
//   src: LibreBarcode,
//   format: 'truetype',
//   fontStyle: 'normal',
//   fontWeight: 'normal',
//   // src: '/fonts/LibreBarcode128-Regular.ttf',
// })

// Font.register({
//   family: 'Avenir',
//   fonts: [
//     {
//       src: '/fonts/Avenir-Book.ttf',
//       fontWeight: 300,
//     },
//     {
//       src: '/fonts/Avenir-Medium.ttf',
//       fontWeight: 400,
//     },
//     {
//       src: '/fonts/Avenir-Heavy.ttf',
//       fontWeight: 700,
//     },
//   ],
// })

Sentry.init({
  // dsn: 'https://3ddc73ef79b7421a9e7a6afa385fe027@bug-tracker.hyperting.it/1',
  dsn: 'https://3ddc73ef79b7421a9e7a6afa385fe027@bug-tracker.hyperting.it/1',
  beforeBreadcrumb(breadcrumb, hint) {
    // remove console logs that start with: "Warning: React does not recognize" or "Warning: findDOMNode is deprecated in StrictMode"
    if (
      breadcrumb.category === 'console' &&
      (breadcrumb?.message?.startsWith('Warning: React does not recognize') ||
        breadcrumb?.message?.startsWith('Warning: findDOMNode is deprecated in StrictMode'))
    ) {
      return null
    }

    return breadcrumb
  },
})

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
