import React from 'react'
import { Children, cloneElement, FC } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer, useMediaQuery, Theme, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import lodashGet from 'lodash/get'
import { setSidebarVisibility, ReduxState, useLocale } from 'ra-core'

export const DRAWER_WIDTH = 270
export const CLOSED_DRAWER_WIDTH = 55

const useStyles = makeStyles(
  (theme) => ({
    mobileDrawer: {
      // width: (props: { open?: boolean }): string | number =>
      //   props.open
      //     ? lodashGet(theme, 'sidebar.width', DRAWER_WIDTH)
      //     : lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
    },
    desktopDrawer: {
      // width: (props: { open?: boolean }): string | number =>
      //   props.open
      //     ? lodashGet(theme, 'sidebar.width', DRAWER_WIDTH)
      //     : lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
      // top: 64,
      height: 'calc(100% - 55px)',
      overflowX: 'hidden',
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(6),
      borderRightWidth: '0 !important',
      // transition: theme.transitions.create('width', {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
    },
    avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64,
    },

    root: {},
    docked: {},
    paper: {},
    desktopDrawerRoot: {
      paddingTop: theme.spacing(11),
    },
    paperAnchorLeft: {},
    paperAnchorRight: {},
    paperAnchorTop: {},
    paperAnchorBottom: {},
    paperAnchorDockedLeft: {},
    paperAnchorDockedTop: {},
    paperAnchorDockedRight: {},
    paperAnchorDockedBottom: {},
    modal: {},
    drawerPaper: {
      position: 'relative',
      // height: '100%',
      overflowX: 'hidden',
      width: (props: { open?: boolean }): string | number => lodashGet(theme, 'sidebar.width', DRAWER_WIDTH),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // backgroundColor: 'transparent',
      // borderRight: 'none',
      // [theme.breakpoints.only('xs')]: {
      //   marginTop: 0,
      //   height: '100vh',
      //   position: 'inherit',
      //   backgroundColor: theme.palette.background.default,
      // },
      // [theme.breakpoints.up('md')]: {
      //   border: 'none',
      // },
      zIndex: 'inherit',
    },
    '@media print': {
      mobileDrawer: {
        display: 'none',
      },
      desktopDrawer: {
        display: 'none',
      },
      drawerPaper: {
        width: 0,
        height: 0,
        display: 'none',
      },
      desktopDrawerRoot: {
        display: 'none',
      },
    },
  }),
  { name: 'RaSidebar' }
)

const Sidebar: FC<any> = (props) => {
  const { children, closedSize, size, classes: classesOverride, ...rest } = props
  const dispatch = useDispatch()
  // const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))
  // const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const open: any = useSelector<ReduxState>((state) => state.admin.ui.sidebarOpen)
  useLocale() // force redraw on locale change
  const handleClose = (): any => dispatch(setSidebarVisibility(false))
  const toggleSidebar = (): any => dispatch(setSidebarVisibility(!open))
  const { drawerPaper, ...classes } = useStyles({ ...props, open })

  return (
    <>
      {/* <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={toggleSidebar}
          open={open}
          variant="temporary"
        >
          {cloneElement(Children.only(children), {
            logout: props.logout,
            onMenuClick: handleClose,
          })}
        </Drawer>
      </Hidden> */}
      {/* <Hidden mdDown> */}
      <Drawer
        anchor="left"
        PaperProps={{
          className: drawerPaper,
        }}
        classes={{ root: classes.desktopDrawerRoot, paper: classes.desktopDrawer }}
        open
        variant="permanent"
      >
        {children}
      </Drawer>
      {/* </Hidden> */}
    </>
  )

  // return isXSmall ? (
  //   <Drawer
  //     variant="temporary"
  //     open={open}
  //     PaperProps={{
  //       className: drawerPaper,
  //     }}
  //     onClose={toggleSidebar}
  //     classes={classes}
  //     {...rest}
  //   >
  //     {cloneElement(Children.only(children), {
  //       onMenuClick: handleClose,
  //     })}
  //   </Drawer>
  // ) : isSmall ? (
  //   <Drawer
  //     variant="permanent"
  //     open={open}
  //     PaperProps={{
  //       className: drawerPaper,
  //     }}
  //     onClose={toggleSidebar}
  //     classes={classes}
  //     {...rest}
  //   >
  //     {cloneElement(Children.only(children), {
  //       onMenuClick: handleClose,
  //     })}
  //   </Drawer>
  // ) : (
  //   <Drawer
  //     variant="permanent"
  //     open={open}
  //     PaperProps={{
  //       className: drawerPaper,
  //     }}
  //     onClose={toggleSidebar}
  //     classes={classes}
  //     {...rest}
  //   >
  //     {children}
  //   </Drawer>
  // )
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Sidebar
