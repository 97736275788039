import React, { FC, useEffect } from 'react'
import { ReferenceInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import SectionedForm from '../../components/forms/SectionedForm'
import FormSection from '../../components/forms/FormSection'
import Edit from '../../components/details/Edit'
import FormTextInput from '../../components/FormTextInput'
import FormSelectInput from '../../components/FormSelectInput'
import FormAutoCompleteInput from '../../components/FormAutoCompleteInput'
import SaveUserButton from './SaveUserButton'

type Props = {
  //
}

const autocompleteQuery = (name: string): Record<string, any> => ({ name })
const sort = { field: 'name', order: 'ASC' }

const RoleSelector: FC<any> = (props) => {
  const form = useForm()
  const { values, touched } = useFormState()

  useEffect(() => {
    if (props.record.roleIds && props.record.roleIds.length > 0 && !touched!['roleId'] && !values.roleId) {
      form.change('roleId', props.record.roleIds[0])
    }
  }, [])

  return (
    <ReferenceInput {...props} label="Seleziona ruolo" source="roleId" reference="Role">
      <FormSelectInput optionText="name" fullWidth helperText="Seleziona il ruolo per cambiare i permessi di accesso" />
    </ReferenceInput>
  )
}

const SalePointSelector: FC<any> = (props) => {
  return (
    <ReferenceInput
      {...props}
      label="Seleziona punto vendita"
      source="salesPointId"
      reference="SalesPoint"
      filterToQuery={autocompleteQuery}
      sort={sort}
    >
      <FormAutoCompleteInput optionText="name" helperText="Seleziona punto vendita di appartenenza" />
    </ReferenceInput>
  )
}

const UserEdit: FC<Props> = (props) => (
  <Edit {...props} component="div">
    <SectionedForm
      variant="outlined"
      redirect="show"
      title="Modifica Utente"
      saveButtonComponent={<SaveUserButton />}
      // asideUpperContent={<SwitchCardInput source="myBoolean" label="Vuoi notificare l'utente?" startingValue={true} />}
    >
      <FormSection title="Utente">
        <FormTextInput {...props} source="firstName" />
        <FormTextInput {...props} source="lastName" helperText="" />
        <FormTextInput {...props} source="email" />
        <RoleSelector />
      </FormSection>
      <FormSection title="Punto Vendita">
        <SalePointSelector />
      </FormSection>
    </SectionedForm>
  </Edit>
)

export default UserEdit
