import React, { FC, useCallback, useMemo } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { useFieldArray } from 'react-final-form-arrays'
import AddIcon from '@material-ui/icons/Add'
import { ShortCollectionRulesBox } from './ShortCollectionRulesBox'
import get from 'lodash/get'

type Props = {
  record?: any
  source?: string
  defaultValue?: any[]
}

export const ShortCollectionRulesFormSection: FC<Props> = ({
  record,
  source = 'collectionRules',
  defaultValue,
  ...props
}) => {
  const classes = useStyles()
  const { fields, meta } = useFieldArray(source, { defaultValue })

  const handleAddRule = useCallback(() => {
    fields.push(undefined)
  }, [fields])

  const handleRemoveRule = useCallback(
    (index: number) => {
      fields.remove(index)
    },
    [fields]
  )

  const alreadyTakenSalesPointIds = useMemo(() => {
    if (fields?.value?.length) {
      return fields.value.reduce((acc, item) => {
        if (item?.salesPointIds) {
          return [...acc, ...item.salesPointIds]
        }

        return acc
      }, [])
    }

    return []
  }, [fields?.value])

  const records = useMemo(() => get(record, source), [record, source])

  return (
    <div className={classes.root}>
      {fields.map((item, index) => {
        return (
          <ShortCollectionRulesBox
            key={`collection-rule-${index}`}
            index={index}
            source={source}
            record={(records && records[index]) || {}}
            disableRemove={(fields && fields.length && fields.length <= 1) || !fields}
            onRemove={handleRemoveRule}
            alreadyTakenSalesPointIds={alreadyTakenSalesPointIds}
          />
        )
      })}
      <Button
        onClick={handleAddRule}
        startIcon={<AddIcon />}
        className={classes.addRulesButton}
        size="large"
        variant="text"
        fullWidth
      >
        Aggiungi Blocco di Regole
      </Button>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  addRulesButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))
