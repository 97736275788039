// in src/MyError.js
import * as React from 'react'
import ErrorIcon from '@material-ui/icons/Report'
import History from '@material-ui/icons/History'
import { Title, useTranslate } from 'react-admin'
import BaseButton from './button/BaseButton'
import BaseTypography from './BaseTypography'
import { Box, Card, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { BiMessageError } from 'react-icons/bi'

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    '& > details': {
      outline: 'none',
    },
  },
}))

const PageNotFound: React.FC<any> = ({ error, errorInfo, ...rest }) => {
  const translate = useTranslate()
  const history = useHistory()
  const classes = useStyles()

  return (
    <Box display="flex" width="100%" height="100%" paddingTop={10} overflow="visible">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        paddingTop={10}
        component={Card}
      >
        <Box textAlign="center" padding={5} alignSelf="center">
          <BaseTypography color="textSecondary">
            <BiMessageError fontSize="8rem" />
          </BaseTypography>
          <BaseTypography color="textSecondary" variant="h1">
            Something Went Wrong
          </BaseTypography>
          <BaseTypography color="secondary">
            A client error occurred and your request couldn't be completed.
          </BaseTypography>
          <Box mt={3}>
            {process.env.NODE_ENV !== 'production' && (
              <Box
                border="1px solid"
                borderColor="grey.200"
                borderRadius={3}
                p={2}
                overflow="hidden"
                className={classes.detailsWrapper}
              >
                <details>
                  <BaseTypography variant="h2">{error && translate(error.toString())}</BaseTypography>
                  {errorInfo && errorInfo.componentStack}
                </details>
              </Box>
            )}
            <Box mt={3}>
              <BaseButton variant="contained" startIcon={<History />} onClick={(): void => history.go(-1)}>
                Go Back
              </BaseButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PageNotFound
