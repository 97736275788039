import React, { FC, useEffect, useState } from 'react'
import { Box, Container, Fade, makeStyles } from '@material-ui/core'
import { ReferenceInput, ReferenceField, TextField, NumberField } from 'react-admin'
import { useQuery } from '@apollo/client'
import { useListController, ListContextProvider, usePermissions, downloadCSV, DataProvider } from 'ra-core'
import StatCard from '../StatCard'
import Title from '../layout/Title'
import { FaChartLine, FaFileAlt } from 'react-icons/fa'
import { QUERY_ALREADY_ASSIGNED_PRODUCTS_TOTAL } from '../../queries'
import Datagrid from '../Datagrid'
import Filter from '../../components/list/filter/Filter'
import RAList from '../../components/list/List'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import { parse } from 'query-string'
import SearchAutoCompleteInput from '../SearchAutoCompleteInput'
import { hasPermission } from '../../utils/hasPermission'
import jsonExport from 'jsonexport/dist'
import ExportButton from '../button/ExportButtont'

type Props = {
  [x: string]: any
}

const exporter = async (records: any[], fetchRelatedRecords: any, dataProvider: DataProvider): Promise<any> => {
  let recordsToExport = []
  const products = await dataProvider.getMany('Product', { ids: records.map((record) => record.productId) })
  const productsHashMap: Record<string, any> | false =
    products && products.data && Array.isArray(products.data)
      ? products.data.reduce((map: any, obj: any) => ((map[obj.id] = { ...obj }), map), {})
      : false

  const shortCollections = await dataProvider.getMany('ShortCollection', {
    ids: records.map((record) => record.shortCollectionId),
  })
  const shortCollectionsHashMap: Record<string, any> | false =
    shortCollections && shortCollections.data && Array.isArray(shortCollections.data)
      ? shortCollections.data.reduce((map: any, obj: any) => ((map[obj.id] = { ...obj }), map), {})
      : false

  const salesPoints = await dataProvider.getMany('SalesPoint', { ids: records.map((record) => record.salesPointId) })
  const salesPointsHashMap: Record<string, any> | false =
    salesPoints && salesPoints.data && Array.isArray(salesPoints.data)
      ? salesPoints.data.reduce((map: any, obj: any) => ((map[obj.id] = { ...obj }), map), {})
      : false

  recordsToExport = records.map((record) => ({
    // ...record,
    Prodotto: productsHashMap ? productsHashMap[record.productId].name : undefined,
    'Short Collection': shortCollectionsHashMap ? shortCollectionsHashMap[record.shortCollectionId].name : undefined,
    'Punto Vendita': salesPointsHashMap ? salesPointsHashMap[record.salesPointId].name : undefined,
    'Stock in attesa': record.productReservationsCount,
  }))

  jsonExport(
    recordsToExport,
    {
      headers: ['Prodotto', 'Short Collection', 'Punto Vendita', 'Stock in attesa'],
      rowDelimiter: ';',
    },
    (err, csv) => {
      downloadCSV(csv, 'tosco-lista_stock_in_attesa')
    }
  )
}

const filterToQuery = (name: string): Record<string, any> => ({ name })

const CustomActions: FC = () => {
  return <ExportButton resource="WaitingListProduct" maxResults={10000} exporter={exporter} />
}

const CustomFilters: FC<any> = (props) => {
  const { loaded, permissions } = usePermissions()

  return (
    <Filter {...props} variant="outlined">
      <ReferenceInput
        filterToQuery={filterToQuery}
        source="shortCollectionId"
        reference="ShortCollection"
        label="Short Collection"
        alwaysOn
      >
        <SearchAutoCompleteInput />
      </ReferenceInput>
      {loaded && hasPermission(permissions) && (
        <ReferenceInput
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={filterToQuery}
          source="salesPointId"
          reference="SalesPoint"
          label="Punto vendita"
        >
          <AutocompleteInputInDrawer optionText="name" fullWidth />
        </ReferenceInput>
      )}
      <ReferenceInput
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={filterToQuery}
        source="productId"
        reference="Product"
        label="Prodotto"
      >
        <AutocompleteInputInDrawer optionText="name" fullWidth />
      </ReferenceInput>
    </Filter>
  )
}

const WaitingListProduct: FC<any> = (props) => {
  return (
    <RAList
      {...props}
      title="Lista d'attesa"
      resource="WaitingListProduct"
      basePath="/"
      filters={<CustomFilters />}
      sort={{ productId: 'ASC' }}
      actions={<CustomActions />}
      bulkActionButtons={false}
      titleIcon={<FaFileAlt />}
      exporter={exporter}
    >
      <Datagrid>
        <ReferenceField source="productId" reference="Product" link={false} label="Prodotto">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="shortCollectionId" reference="ShortCollection" link={false} label="Collection">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="salesPointId" reference="SalesPoint" link={false} label="Punto Vendita">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="productReservationsCount" label="Stock in attesa" />
      </Datagrid>
    </RAList>
  )
}

const Dashboard: FC<Props> = (props) => {
  const { filter } = parse(props?.location?.search)
  const [currentFilters, setCurrentFilters] = useState<Record<string, any>>({})

  const {
    loading: totalLoading,
    error: totalError,
    data: totalData,
    refetch: totalRefetch,
  } = useQuery(QUERY_ALREADY_ASSIGNED_PRODUCTS_TOTAL, {
    variables: { filters: { productReservationStatus: 'DELIVERED' } },
  })

  const {
    loading: assignedLoading,
    error: assignedError,
    data: assignedData,
    refetch: assignedRefetch,
  } = useQuery(QUERY_ALREADY_ASSIGNED_PRODUCTS_TOTAL, {
    variables: { filters: { productReservationStatus: ['CUSTOMER_NOTIFIED', 'WAITING_FOR_AVAILABLE_NOTIFICATION'] } },
  })

  const {
    loading: waitingLoading,
    error: waitingError,
    data: waitingData,
    refetch: waitingRefetch,
  } = useQuery(QUERY_ALREADY_ASSIGNED_PRODUCTS_TOTAL, {
    variables: { filters: { productReservationStatus: ['WAITING', 'WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION'] } },
  })

  const listContextValue = useListController({
    resource: 'WaitingListProduct',
    basePath: '/',
    sort: { field: 'productId', order: 'ASC' },
    filters: <CustomFilters />,
  })

  useEffect(() => {
    if (filter && typeof filter === 'string') {
      const x = JSON.parse(filter)
      setCurrentFilters(x)
    }
  }, [filter])

  useEffect(() => {
    totalRefetch({ filters: { ...currentFilters, productReservationStatus: 'DELIVERED' } })
    assignedRefetch({
      filters: {
        ...currentFilters,
        productReservationStatus: ['CUSTOMER_NOTIFIED', 'WAITING_FOR_AVAILABLE_NOTIFICATION'],
      },
    })
    waitingRefetch({
      filters: {
        ...currentFilters,
        productReservationStatus: ['WAITING', 'WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION'],
      },
    })
  }, [currentFilters])

  return (
    <Fade in={true}>
      <Container maxWidth={false} className={'dashboard-page'}>
        <Box pt={11} pl={3}>
          <Title title="Dashboard" icon={<FaChartLine />} />
          <Box display="flex" mt={5}>
            <StatCard
              count={totalData && totalData.productReservationsCount ? totalData.productReservationsCount : 0}
              title="Prenotazioni consegnate"
              status="positive"
              loading={totalLoading}
              error={!!totalError}
            />
            <Box pr={3} />
            <StatCard
              count={assignedData && assignedData.productReservationsCount ? assignedData.productReservationsCount : 0}
              title="Prenotazioni assegnate"
              loading={assignedLoading}
              error={!!assignedError}
            />
            <Box pr={3} />
            <StatCard
              count={waitingData && waitingData.productReservationsCount ? waitingData.productReservationsCount : 0}
              title="Prenotazioni in attesa"
              status="negative"
              loading={waitingLoading}
              error={!!waitingError}
            />
          </Box>
          <Box mt={8}>
            <ListContextProvider value={listContextValue}>
              <WaitingListProduct />
            </ListContextProvider>
          </Box>
        </Box>
      </Container>
    </Fade>
  )
}

export default Dashboard
