import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Avatar, Box, Button, Divider, makeStyles, Typography } from '@material-ui/core'
import { FaUsers } from 'react-icons/fa'
import BooleanStatusField from '../../components/BooleanStatusField'
import ToggleStatusButton from '../../components/button/ToggleStatusButton'
import { ReferenceField, TextField, ReferenceManyField } from 'react-admin'
import { useDataProvider, useNotify, useShowController, useVersion } from 'ra-core'
import ShowView from '../../components/details/ShowView'
import ChipField from '../../components/ChipField'
import Datagrid from '../../components/Datagrid'
import { DateField } from 'ra-ui-materialui'
import { FunctionField } from 'ra-ui-materialui'
import moment from 'moment'
import Pagination from '../../components/list/pagination/Pagination'
import Loading from '../../components/Loading'
import Empty from '../../components/list/Empty'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailValue: {
    fontWeight: 'bold',
  },
  userCard: {
    // width: '40%', // TODO: DELETE ME
    flexBasis: '50%',
    maxWidth: 375,
    position: 'sticky',
    top: theme.spacing(3),
  },
  cardValue: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  contentContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
  },
  userLogsContainer: {
    flex: 1,
    marginLeft: theme.spacing(3),
    //    overflow: 'scroll',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    alignSelf: 'flex-start',
  },
}))

const UserShow = (props: any): any => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const layoutRecord = useMemo(() => ({ id: props.id }), [props.id])
  const [loading, setLoading] = useState<boolean>(true)
  const [loaded, setLoaded] = useState<boolean>(true)
  const [user, setUser] = useState<any>(undefined)
  const layoutTitle = useMemo(() => (user && user.fullName ? user.fullName : 'Utente'), [user])
  const notify = useNotify()
  const version = useVersion()

  const fetchData = useCallback(async () => {
    if (!loaded) {
      setLoading(true)
    }
    try {
      const userResult = await dataProvider.getOne('User', {
        id: props.id,
      })

      if (!userResult.data || !userResult.data.id) {
        throw new Error('User not found')
      }
      setUser(userResult.data)
    } catch (e) {
      notify('ra.message.error')
    } finally {
      setLoading(false)
    }
  }, [props.id, loaded])

  // useEffect(() => {
  //   fetchData()
  // }, [])

  useEffect(() => {
    fetchData()
  }, [version])

  return (
    <ShowView
      {...props}
      record={layoutRecord}
      title={layoutTitle}
      loaded={!loading && user}
      titleBar={
        <ReferenceField {...props} record={user} source="salesPointId" reference="SalesPoint" link={false}>
          <ChipField source="name" />
        </ReferenceField>
      }
    >
      {loading || !user ? (
        <Loading />
      ) : (
        <div className={classes.contentContainer}>
          <InfoCardWrapper>
            <InfoCard>
              <InfoCardItem direction="row">
                <InfoCardAvatar>
                  <FaUsers size={20} />
                </InfoCardAvatar>
                <InfoCardTypography variant="title">{`${user.fullName}`}</InfoCardTypography>
              </InfoCardItem>
              <InfoCardItem>
                <InfoCardTypography variant="label">Email:</InfoCardTypography>
                <InfoCardTypography variant="value">{user.email}</InfoCardTypography>
              </InfoCardItem>
              <InfoCardItem>
                <InfoCardTypography variant="label">Ruolo:</InfoCardTypography>
                {/* <Typography variant="body1" className={classes.emailValue}>
            {JSON.stringify(user.roles)}
          </Typography> */}
                {user && user.roles && user.roles.length > 0 ? (
                  <ReferenceField {...props} link={false} reference="Role" record={user.roles[0]} source="id">
                    <TextField className={classes.cardValue} variant="body1" source="name" />
                  </ReferenceField>
                ) : (
                  <InfoCardTypography variant="value" color="error">
                    Ruolo non assegnato
                  </InfoCardTypography>
                )}
              </InfoCardItem>
              <InfoCardItem direction="row" justifyContent="space-between">
                <div>
                  <InfoCardTypography variant="label">Status:</InfoCardTypography>
                  <InfoCardTypography variant="value">
                    <BooleanStatusField className={classes.cardValue} variant="body1" source="active" record={user} />
                  </InfoCardTypography>
                </div>
                <ToggleStatusButton record={user} resource={props.resource} source="active" />
              </InfoCardItem>
            </InfoCard>
          </InfoCardWrapper>

          <Box className={classes.userLogsContainer}>
            <ReferenceManyField
              {...props}
              pagination={<Pagination />}
              record={user}
              reference="ServiceLog"
              target="userId"
              label="Comments"
              sort={{ field: 'createdAt', order: 'DESC' }}
            >
              <Datagrid emptyComponent={<Empty buttonComponent={<></>} />}>
                <TextField source="id" label="ID" />
                <TextField source="action" label="Attività" />
                {/* <TextField source="domain" /> */}
                <FunctionField
                  label="Data"
                  source="createdAt"
                  render={(record: any): React.ReactNode => moment(record.createdAt).fromNow()}
                />
                {/* <TextField source="data" /> */}
              </Datagrid>
            </ReferenceManyField>
          </Box>
        </div>
      )}
    </ShowView>
  )
}

export default UserShow
