import { gql, useApolloClient } from '@apollo/client'
import { useDataProvider, useNotify, useRedirect } from 'ra-core'
import React, { FC, useCallback } from 'react'
import SaveButton from '../../components/button/SaveButton'

type Props = {
  [x: string]: any
}

const SaveUserButton: FC<Props> = (props) => {
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const notify = useNotify()
  const handleSave = useCallback(async (data?: any) => {
    let userResult: any = {}
    try {
      if (data.roleId) {
        data.roleIds = [data.roleId]
      }

      const userMutationResult = data.id
        ? await dataProvider.update('User', {
            data,
            id: data.id,
            previousData: data,
          })
        : await dataProvider.create('User', {
            data,
          })

      if (!userMutationResult || !userMutationResult.data || !userMutationResult.data.id) {
        throw new Error('Unexpected Error')
      }
      userResult = userMutationResult.data

      notify('ra.message.success')
      redirect(`${props.basePath}/${userResult.id}/show`)

      return
    } catch (e) {
      console.error(e)
      notify((e as any).message)
      return
    }
  }, [])

  return <SaveButton {...props} onSave={handleSave} />
}

export default SaveUserButton
