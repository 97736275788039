import list from './SalesPointList'
import create from './SalesPointCreate'
import edit from './SalesPointEdit'
import show from './SalesPointShow'

export default {
  name: 'SalesPoint',
  list,
  create,
  edit,
  show,
}
