import React, { Children, cloneElement, FC, isValidElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router-dom'
import Title from '../layout/Title'
import EditButton from '../button/EditButton'
import { Fade } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingRight: 50,
    marginTop: theme.spacing(10),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(6),
  },
  title: {
    marginLeft: theme.spacing(4),
  },
  iconTitleWrapper: {
    backgroundColor: 'white',
    color: '#b9a7a6',
    width: '45px',
    height: '45px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleBarWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleBarContent: {
    marginLeft: 65,
    marginTop: theme.spacing(1),
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

type Props = {
  basePath?: string
  record?: any
  resource?: string
  // title?: string
  defaultTitle?: string
  titleBar?: React.ReactNode
  version?: any
  additionalActions?: React.ReactNode
  hideEdit?: boolean
  [x: string]: any
}

const ShowView: FC<Props> = ({
  basePath,
  record,
  title,
  defaultTitle,
  resource,
  children,
  titleBar,
  version,
  loaded,
  additionalActions,
  hideEdit = false,
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Fade in={loaded}>
      <Container maxWidth={false} className={classes.root}>
        <div className={classes.header}>
          <div className={classes.titleBarWrapper}>
            <Title
              icon={<HiArrowNarrowLeft fontSize={24} fontWeight="bold" />}
              avatarButton
              avatarOnClick={history.goBack}
              defaultTitle={defaultTitle}
              title={title}
            />
            {titleBar && <div className={classes.titleBarContent}>{titleBar}</div>}
          </div>
          <div>
            {additionalActions}
            {!hideEdit && <EditButton basePath={basePath} record={record} resource={resource} />}
          </div>
        </div>
        {record &&
          children &&
          isValidElement(children) &&
          cloneElement(Children.only(children), {
            resource,
            basePath,
            record,
            version,
          })}
      </Container>
    </Fade>
  )
}

export default ShowView
