import React, { FC } from 'react'
import RAChipField, { ChipFieldProps } from 'ra-ui-materialui/lib/field/ChipField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chip: (props: any): any => ({
    color: props.color === 'primary' ? theme.palette.primary.contrastText : theme.palette.text.primary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 14,
    textTransform: 'none',
    backgroundColor: props.color === 'primary' ? theme.palette.primary.main : theme.palette.common.white,
  }),
}))

type Props = ChipFieldProps

const ChipField: FC<Props> = (props) => {
  const classes = useStyles(props)
  return <RAChipField color="default" variant="outlined" className={classes.chip} {...props} />
}

export default ChipField
