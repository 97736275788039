import { createMuiTheme, colors } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'

const theme = createMuiTheme({
  spacing: 5,
  palette: {
    background: {
      default: '#fbfbfb',
      paper: colors.common.white,
    },
    primary: {
      // main: colors.indigo[500],
      main: '#ed1d23',
    },
    secondary: {
      main: '#999999',
    },
    text: {
      primary: '#333',
      secondary: '#5e5555', //colors.blueGrey[600],
    },
    success: {
      main: 'rgb(14, 221, 77)',
    },
  },
  shadows,
  typography: {
    ...typography,
    body2: {
      fontSize: 14,
      fontWeight: 'unset',
    },
    fontFamily: ['Avenir', '-apple-system', 'sans-serif'].join(','),
  },
  props: {
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
})

//THEME OVERRIDES
theme.overrides = {
  MuiButton: {
    // Name of the rule
    root: {
      // Some CSS
      fontWeight: 900,
      textTransform: 'capitalize',
    },
  },
  MuiCheckbox: {
    root: {
      borderColor: '#edd6d6',
    },
  },
  MuiLinearProgress: {
    root: {
      borderRadius: '50px',
      overflow: 'hidden',
    },
    bar: {
      borderRadius: '50px',
    },
  },
  MuiCircularProgress: {
    root: {
      borderRadius: '50px',
      overflow: 'hidden',
    },
    indeterminate: {
      strokeLinecap: 'round',
    },
  },

  MuiInputLabel: {
    root: {
      color: theme.palette.secondary.light,
      '&$focused': {
        color: theme.palette.secondary.dark,
      },
    },
    focused: {
      color: theme.palette.secondary.main,
    },
  },
  MuiOutlinedInput: {
    root: {
      position: 'relative',
      '& $notchedOutline': {
        borderColor: '#bdbdbd',
      },
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: '#bdbdbd',
      },
      '&$focused $notchedOutline': {
        borderColor: theme.palette.secondary,
        borderWidth: 1,
      },
      '&$error $notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: 1,
      },
      '&$disabled $notchedOutline': {
        borderColor: theme.palette.action.disabled,
        borderWidth: 1,
      },
    },
  },
  MuiTableCell: {
    root: {},
    body: {
      fontWeight: 'lighter',
    },
  },
  MuiMenu: {
    paper: {},
    list: {},
  },
  MuiListItem: {
    root: {
      /*    display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(5), */
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
  },
  MuiPaper: {
    root: {
      //   borderRadius: theme.shape.borderRadius,
    },
  },
  MuiIconButton: {
    root: {
      borderRadius: theme.shape.borderRadius,
    },
  },

  // MuiFormControl: {
  //   root: {
  //     flexDirection: 'row',
  //   },
  // },
  // MuiListItemRoot: {
  //   '&$checked': {
  //     backgroundColor: 'red',
  //   }
  // },
  // MuiPickersCalendarHeader: {
  //   iconButton: {
  //     backgroundColor: 'transparent',
  //   },
  //   transitionContainer: {
  //     '& p': {
  //       textAlign: 'left',
  //     },
  //   },
  //   switchHeader: {
  //     '& div': {
  //       order: 0,
  //     },
  //     '& button': {
  //       order: 1,
  //     },
  //     '& svg': {
  //       width: '0.985rem',
  //       height: '0.985rem',
  //     },
  //   },
  // },
  // MuiPickersDay: {
  //   day: {
  //     fontSize: 8,
  //   },
  // },
}

export default theme
