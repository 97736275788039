import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useApolloClient, useQuery } from '@apollo/client'
import FormSection from '../../components/forms/FormSection'
import { TextInput, ImageInput, TextField, ReferenceManyField, useMutation, ImageField } from 'react-admin'
import WizardSectionedForm, { WizardStep } from '../../components/forms/WizardSectionedForm'
import FormDateInput from '../../components/FormDateInput'
import { Drawer, makeStyles, Typography, TextField as MuiTextInput, Box, Card } from '@material-ui/core'
import WizardRightControls from '../../components/forms/WizardSectionedForm/WizardRightControls'
import { useForm } from 'react-final-form'
import Button from '@material-ui/core/Button'
import {
  FormDataConsumer,
  required,
  useDataProvider,
  useNotify,
  useQueryWithStore,
  useRedirect,
  useRefresh,
} from 'ra-core'
import Datagrid from '../../components/Datagrid'
import IconButton from '@material-ui/core/IconButton'
import { Confirm } from 'ra-ui-materialui'
import SaveButton from '../../components/button/SaveButton'
import SwitchCardInput from '../../components/SwitchCardInput'
import BaseButton from '../../components/button/BaseButton'
import Empty from '../../components/list/Empty'
import Edit from '../../components/details/Edit'
import { MUTATION_CREATE_PRODUCT, QUERY_GET_COLLECTION_RULES } from '../../queries'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import BarcodeField from '../../components/BarcodeField'
import { FaTrashAlt } from 'react-icons/fa'
import MuiNumberInput, { formatNumberInput } from '../../components/MuiNumberInput'
import MuiCurrencyInput, { formatCurrencyInput } from '../../components/MuiCurrencyInput'
import { ShortCollectionRulesFormSection } from './ShortCollectionRules/ShortCollectionRulesFormSection'
import { SingleMechanicField } from '../../components/SIngleMechanicField'
import { ContributionMechanicField } from '../../components/ContributionMechanicField'

type Props = {
  [x: string]: any
}

type AddProductButtonProps = {
  size?: 'small' | 'medium' | 'large'
} & Props

const AddProductButtonDrawer: FC<AddProductButtonProps> = (props) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const client = useApolloClient()
  const classes = useStyles()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [productRecord, setProductRecord] = useState<Record<string, any>>({})
  const [updating, setUpdating] = useState<boolean>(false)

  const onButtonClick = useCallback((): void => setIsDrawerOpen(true), [])
  const onClose = useCallback((): void => setIsDrawerOpen(false), [])

  console.log(props, 'sono PROPS AddProductButtonDrawer')

  const onUndoClick = useCallback((): void => {
    setProductRecord({})
    onClose()
  }, [])

  const onInputChange = useCallback(
    (event: any): void => setProductRecord({ ...productRecord, [event.target.name]: event.target.value }),
    [productRecord]
  )

  // add localstorage saving logics here
  const onAddClick = useCallback(async (): Promise<void> => {
    setUpdating(true)
    try {
      const { data: productData } = await client.mutate({
        mutation: MUTATION_CREATE_PRODUCT,
        variables: {
          data: {
            name: productRecord.name,
            shortCollectionId: props.id,
            description: productRecord.description,
            eanStamps: productRecord.eanStamps,
            eanStampsContribution: productRecord.eanStampsContribution,
            eanPoints: productRecord.eanPoints,
            eanPointsContribution: productRecord.eanPointsContribution,
            eanContribution: productRecord.eanContribution,
            numStampsStamps: formatNumberInput(productRecord.numStampsStamps),
            numStampsStampsContribution: formatNumberInput(productRecord.numStampsStampsContribution),
            contributionStampsContribution: formatCurrencyInput(productRecord.contributionStampsContribution),
            numPointsPoints: formatNumberInput(productRecord.numPointsPoints),
            numPointsPointsContribution: formatNumberInput(productRecord.numPointsPointsContribution),
            contributionPointsContribution: formatCurrencyInput(productRecord.contributionPointsContribution),
            contributionContribution: formatCurrencyInput(productRecord.contributionContribution),
            eanStampsPointsContribution: productRecord.eanStampsPointsContribution,
            numStampsStampsPointsContribution: formatNumberInput(productRecord.numStampsStampsPointsContribution),
            numPointsStampsPointsContribution: formatNumberInput(productRecord.numPointsStampsPointsContribution),
            contributionStampsPointsContribution: formatCurrencyInput(
              productRecord.contributionStampsPointsContribution
            ),
          },
        },
      })
      if (!productData?.createProduct?.id) {
        throw new Error('Error creating Product')
      }

      notify('Short Collection aggiornata con successo!')
      refresh()
      onClose()
    } catch (error) {
      console.log('ShortScollection update error: ', error)
      // eslint-disable-next-line quotes
      notify("Errore nell'aggiornamento della short collection", 'error')
    } finally {
      setUpdating(false)
    }
  }, [productRecord])

  const disableAddProductButton = useMemo(
    (): boolean =>
      !productRecord.name ||
      !productRecord.eanStamps ||
      !productRecord.eanStampsContribution ||
      !productRecord.eanPoints ||
      !productRecord.eanPointsContribution ||
      !productRecord.eanContribution ||
      !productRecord.numStampsStamps ||
      !productRecord.numStampsStampsContribution ||
      !productRecord.contributionStampsContribution ||
      !productRecord.numPointsPoints ||
      !productRecord.numPointsPointsContribution ||
      !productRecord.contributionPointsContribution ||
      !productRecord.contributionContribution ||
      !productRecord.eanStampsPointsContribution ||
      !productRecord.numStampsStampsPointsContribution ||
      !productRecord.numPointsStampsPointsContribution ||
      !productRecord.contributionStampsPointsContribution,
    [productRecord]
  )

  return (
    <Box pt={3}>
      <BaseButton
        fullWidth
        onClick={onButtonClick}
        variant="contained"
        size="xl"
        className={classes.addButton}
        {...props}
      >
        Aggiungi Prodotto
      </BaseButton>
      <Drawer open={isDrawerOpen} anchor="right" classes={{ paper: classes.drawer }} onClose={onClose}>
        <div className={classes.drawerContainer}>
          <div className={classes.row}>
            <Typography variant="h2">Aggiungi Prodotto</Typography>
            <BaseButton onClick={onUndoClick}>Annulla</BaseButton>
          </div>

          <Typography variant="h4" className={classes.subtitle}>
            Codici
          </Typography>
          <div className={classes.row}>
            <MuiNumberInput
              label="Codice Bollini"
              name="eanStamps"
              value={productRecord.eanStamps}
              onChange={onInputChange}
              fullWidth
            />
            <Box pl={2} />
            <MuiNumberInput
              label="Codice Bollini + Contributo"
              name="eanStampsContribution"
              value={productRecord.eanStampsContribution}
              onChange={onInputChange}
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <MuiNumberInput
              label="Codice Punti"
              name="eanPoints"
              value={productRecord.eanPoints}
              onChange={onInputChange}
              fullWidth
            />
            <Box pl={2} />
            <MuiNumberInput
              label="Codice Punti + Contributo"
              name="eanPointsContribution"
              value={productRecord.eanPointsContribution}
              onChange={onInputChange}
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <MuiNumberInput
              label="Codice Contributo"
              name="eanContribution"
              value={productRecord.eanContribution}
              onChange={onInputChange}
              fullWidth
              style={{ maxWidth: '49%' }}
            />
            <MuiNumberInput
              label="Codice Bollini + Punti + Contributo"
              name="eanStampsPointsContribution"
              value={productRecord.eanStampsPointsContribution}
              onChange={onInputChange}
              fullWidth
              style={{ maxWidth: '49%' }}
            />
          </div>

          <div className={classes.row}>
            <MuiTextInput
              variant="outlined"
              label="Nome Prodotto"
              name="name"
              fullWidth
              value={productRecord.name}
              onChange={onInputChange}
            />
          </div>
          <div className={classes.row}>
            <MuiTextInput
              variant="outlined"
              label="Descrizione"
              name="description"
              fullWidth
              multiline
              value={productRecord.description}
              onChange={onInputChange}
            />
          </div>

          <>
            <Typography variant="h4" className={classes.subtitle}>
              Meccanica Bollini
            </Typography>
            <div className={classes.row}>
              <MuiNumberInput
                label="Bollini"
                name="numStampsStamps"
                value={productRecord.numStampsStamps}
                onChange={onInputChange}
                classes={{ root: classes.halfWidth }}
                fullWidth
              />
            </div>
            <Typography variant="h4" className={classes.subtitle}>
              Meccanica Bollini + Contributo
            </Typography>
            <div className={classes.row}>
              <MuiNumberInput
                label="Bollini"
                name="numStampsStampsContribution"
                value={productRecord.numStampsStampsContribution}
                onChange={onInputChange}
                fullWidth
              />
              <Box pl={2} />
              <MuiCurrencyInput
                label="Contributo (€)"
                name="contributionStampsContribution"
                value={productRecord.contributionStampsContribution}
                onChange={onInputChange}
                fullWidth
              />
            </div>
            <Typography variant="h4" className={classes.subtitle}>
              Meccanica Punti
            </Typography>
            <div className={classes.row}>
              <MuiNumberInput
                label="Punti"
                name="numPointsPoints"
                value={productRecord.numPointsPoints}
                onChange={onInputChange}
                classes={{ root: classes.halfWidth }}
                fullWidth
              />
            </div>
            <Typography variant="h4" className={classes.subtitle}>
              Meccanica Punti + Contributo
            </Typography>
            <div className={classes.row}>
              <MuiNumberInput
                label="Punti"
                name="numPointsPointsContribution"
                value={productRecord.numPointsPointsContribution}
                onChange={onInputChange}
                fullWidth
              />
              <Box pl={2} />
              <MuiCurrencyInput
                label="Contributo (€)"
                name="contributionPointsContribution"
                value={productRecord.contributionPointsContribution}
                onChange={onInputChange}
                fullWidth
              />
            </div>
            <Typography variant="h4" className={classes.subtitle}>
              Meccanica Contributo
            </Typography>
            <div className={classes.row}>
              <MuiCurrencyInput
                label="Contributo (€)"
                name="contributionContribution"
                value={productRecord.contributionContribution}
                onChange={onInputChange}
                classes={{ root: classes.halfWidth }}
                fullWidth
              />
            </div>

            <Typography variant="h4" className={classes.subtitle}>
              Meccanica Bollini + Punti + Contributo
            </Typography>
            <div className={classes.row}>
              <MuiNumberInput
                label="Bollini"
                name="numStampsStampsPointsContribution"
                value={productRecord.numStampsStampsPointsContribution}
                onChange={onInputChange}
                fullWidth
              />
              <Box pl={2} />
              <MuiNumberInput
                label="Punti"
                name="numPointsStampsPointsContribution"
                value={productRecord.numPointsStampsPointsContribution}
                onChange={onInputChange}
                fullWidth
              />
            </div>
            <div className={classes.row}>
              <MuiCurrencyInput
                label="Contributo (€)"
                name="contributionStampsPointsContribution"
                value={productRecord.contributionStampsPointsContribution}
                onChange={onInputChange}
                classes={{ root: classes.halfWidth }}
                fullWidth
              />
            </div>
          </>

          <Button
            color="primary"
            variant="contained"
            className={classes.createButton}
            onClick={onAddClick}
            disabled={disableAddProductButton || updating}
          >
            Aggiungi
          </Button>
        </div>
      </Drawer>
    </Box>
  )
}

const ProductsTable: FC<any> = ({ formDataProps }) => {
  return (
    <ReferenceManyField
      record={formDataProps.record}
      reference="Product"
      target="shortCollectionId"
      basePath="/ShortCollection"
    >
      <Datagrid emptyComponent={<Empty buttonComponent={<></>} />}>
        <TextField source="id" label="ID" />
        <TextField source="name" />

        <BarcodeField
          label="Ean Bol"
          source="eanStamps"
          sortable={false}
          record={formDataProps.record}
          height={25}
          preKey={''}
        />
        <SingleMechanicField label="Mecc. Bollini" record={formDataProps.record} source="numStampsStamps" />
        <BarcodeField
          label="EAN Bol + Contr."
          record={formDataProps.record}
          source="eanStampsContribution"
          sortable={false}
          height={25}
          preKey={''}
        />
        <ContributionMechanicField
          label="Mecc. Bol + Contr."
          record={formDataProps.record}
          stampsQuantitySource="numStampsStampsContribution"
          contributionSource="contributionStampsContribution"
        />
        <BarcodeField
          label="EAN Pt"
          source="eanPoints"
          sortable={false}
          record={formDataProps.record}
          height={25}
          preKey={''}
        />
        <SingleMechanicField label="Mecc. Pt" record={formDataProps.record} appendix="pt" source="numPointsPoints" />
        <BarcodeField
          label="EAN Pt + Contrib."
          source="eanPointsContribution"
          sortable={false}
          record={formDataProps.record}
          height={25}
          preKey={''}
        />
        <ContributionMechanicField
          record={formDataProps.record}
          label="Mecc. Pt + Contrib."
          pointsQuantitySource="numPointsPointsContribution"
          contributionSource="contributionPointsContribution"
        />
        <BarcodeField
          label="EAN Contr."
          source="eanContribution"
          sortable={false}
          record={formDataProps.record}
          height={25}
          preKey={''}
        />
        <SingleMechanicField
          label="Mecc. Contr."
          record={formDataProps.record}
          source="contributionContribution"
          type="currency"
          appendix="€"
        />
        <BarcodeField
          label="EAN Bol + Pt + Contr."
          source="eanStampsPointsContribution"
          sortable={false}
          record={formDataProps.formData}
          height={25}
          preKey={''}
        />

        <ContributionMechanicField
          label="Mecc. Bol + Pt + Contrib."
          stampsQuantitySource="numStampsStampsPointsContribution"
          pointsQuantitySource="numPointsStampsPointsContribution"
          contributionSource="contributionStampsPointsContribution"
        />
        <DeleteProductButton />
      </Datagrid>
    </ReferenceManyField>
  )
}

const DeleteProductButton: FC<any> = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [handleDelete, { error, data, loading }] = useMutation({
    type: 'delete',
    resource: 'Product',
    payload: { id: props.record?.id },
  })

  const onClose = useCallback((): void => setIsDialogOpen(false), [])
  const onClick = useCallback((): void => setIsDialogOpen(true), [])

  useEffect(() => {
    if (error) {
      onClose()
      notify(`Errore nella cancellazione del prodotto ${props?.record?.name}`, 'error')
    } else if (data) {
      onClose()
      refresh()
    }
  }, [error, data])

  if (!props.record) {
    return null
  }

  return (
    <>
      <IconButton onClick={onClick}>
        <FaTrashAlt size="1rem" />
      </IconButton>
      <Confirm
        isOpen={isDialogOpen}
        loading={loading}
        title={`Cancellare il prodotto ${props.record.name} ?`}
        content="ra.message.delete_content"
        onConfirm={handleDelete}
        onClose={onClose}
      />
    </>
  )
}

const UpdateShortCollection: FC<any> = (props) => {
  const { values, touched, dirtyFields } = useFormState()
  const [saving, setSaving] = useState(false)
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()

  const handleSave = async (): Promise<any> => {
    try {
      setSaving(true)
      const updateShortCollection = await dataProvider.update('ShortCollection', {
        id: values.id,
        previousData: { id: values.id },
        data: {
          name: values.name,
          active: values.active,
          description: values.description,
          startDate: values.startDate,
          endDate: values.endDate,
          cleanUpDate: values.cleanUpDate,
          brand: values.brand,
          picture: values.picture ? { file: values.picture } : null,
          ...{
            rules: values.collectionRules.map((item: any) => {
              const { areaIds, zoneIds, shortCollectionId, id, ...rest } = item
              return {
                coopAreaIds: areaIds,
                coopZoneIds: zoneIds,
                ...rest,
              }
            }),
          },
        },
      })
      // }

      notify('Short Collection aggiornata con successo')
      redirect('/ShortCollection')
    } catch (error) {
      console.log('BAD THINGS HAPPENED: ', error)
      notify('Errore nella creazione della Short Collection', 'error')
    } finally {
      setSaving(false)
    }
  }

  return (
    <SaveButton
      {...props}
      variant="contained"
      saving={saving}
      disabled={saving}
      handleSubmitWithRedirect={handleSave}
    />
  )
}

const ShortCollectionActiveSwitch: FC<any> = (props) => {
  const { values } = useFormState()

  return (
    <SwitchCardInput
      {...props}
      record={values}
      source="active"
      label="Vuoi pubblicare subito la collection?"
      startingValue={true}
    />
  )
}

const ShortCollectionEdit: FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <Edit {...props} component="div">
      <WizardSectionedForm variant="outlined" redirect="list" title="Modifica Short Collection">
        <WizardStep
          formRightControls={<WizardRightControls asideUpperContent={<ShortCollectionActiveSwitch {...props} />} />}
        >
          <FormSection title="Short Collection">
            <TextInput multiline source="name" label="Nome" helperText="Modifica il nome della short collection" />
            <TextInput multiline source="description" className={classes.description} />
          </FormSection>
          {/* <FormSection title="Iniziativa bruciapunti">
            <FormSwitchInput
              {...props}
              source="isPointsBurner"
              helperText="Attivando questo flag la meccanica EAN-13 visualizzerà i punti anzichè i bollini"
            />
          </FormSection> */}
          <RulesForm />

          <FormSection title="Validità">
            <FormDateInput
              source="startDate"
              validate={required()}
              helperText="Data di inizio validità short collection"
            />
            <FormDateInput source="endDate" validate={required()} helperText="Data di fine validità short collection" />
            <FormDateInput source="cleanUpDate" validate={required()} helperText="Data di clean-up short collection" />
          </FormSection>
          <FormSection title="Brand">
            <BrandForm />
          </FormSection>
        </WizardStep>
        <WizardStep
          formRightControls={
            <WizardRightControls
              saveButtonComponent={<UpdateShortCollection />}
              asideLowerContent={<AddProductButtonDrawer {...props} />}
            />
          }
        >
          <FormDataConsumer>
            {(formDataProps: any): React.ReactNode => (
              <Card>
                <ProductsTable formDataProps={formDataProps} />
              </Card>
            )}
          </FormDataConsumer>
        </WizardStep>
      </WizardSectionedForm>
    </Edit>
  )
}

const BrandForm: FC<any> = (props: any): any => {
  const classes = useStyles()

  return (
    <Box alignSelf="center">
      <TextInput {...props} source="brand" label="Nome brand" helperText="Modifica il nome del brand associato" />
      <Box display="flex">
        <ImageInput
          source="brandImage"
          label="Immagine brand"
          accept="image/*"
          classes={{
            root: classes.imgRoot,
            dropZone: classes.imgDropZoneLarge,
            removeButton: classes.imgRemoveButton,
          }}
          helperText=""
        >
          <ImageField source="src" title="title" classes={{ image: classes.imageLarge }} />
        </ImageInput>
        <BrandImageWrapper {...props} />
      </Box>
    </Box>
  )
}

const BrandImageWrapper: FC<any> = (props) => {
  const { values } = useFormState()
  const classes = useStyles()

  if (!values.brandImage && props?.record?.pictureId)
    return (
      <Box alignSelf="center">
        <BrandImageField
          id={props.record?.pictureId}
          classes={{
            image: classes.imageLarge,
          }}
        />
      </Box>
    )
  else return null
}

const BrandImageField: FC<any> = ({ id }) => {
  const classes = useStyles()
  const { loading, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'Picture',
    payload: { id },
  })

  if (loading) return <LinearProgress />
  if (data) return <ImageField record={data} source="urlSmall" classes={{ image: classes.imageLarge }} />
  return null
}

const RulesForm: FC<any> = (props) => {
  const classes = useStyles()
  const [initialized, setInitialized] = useState<boolean>(false)
  const { change } = useForm()
  const { record, id } = props

  const { loading, data } = useQuery(QUERY_GET_COLLECTION_RULES, {
    variables: {
      filters: {
        shortCollectionId: record.id,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.shortCollectionRules?.data?.length) {
        const newFormData = data.shortCollectionRules.data.map((item: any) => {
          const { coopAreas, coopZones, __typename, ...rest } = item
          return {
            areaIds: coopAreas.map((area: any) => area.id),
            zoneIds: coopZones.map((zone: any) => zone.id),
            ...rest,
          }
        })
        change('collectionRules', newFormData)
      }
      setInitialized(true)
    },
  })

  if (!record) {
    return null
  }

  if (loading) {
    return <div>LOADING</div>
  }

  return (
    <FormSection
      title="Regole Short Collection"
      hideCardHeader
      wrapperComponent={<React.Fragment />}
      contentComponent={<React.Fragment />}
      {...props}
    >
      <ShortCollectionRulesFormSection />
    </FormSection>
  )
}

const useStyles = makeStyles((theme) => ({
  description: {
    width: '100%',
  },
  drawer: {
    width: '560px',
    minWidth: 350,
  },
  drawerContainer: {
    padding: theme.spacing(10),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  input: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  rootInput: {
    maxWidth: '130px',
    marginRight: theme.spacing(3),
  },
  createButton: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '100%',
  },
  addButton: {
    backgroundColor: theme.palette.common.white,
  },
  imgDropZoneLarge: {
    padding: theme.spacing(8),
    outline: 'none',
    width: '100%',
  },
  imgRoot: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .previews': {
      display: 'flex',
      alignSelf: 'flex-start',
      width: '100%',
      overflow: 'scroll',
      marginLeft: theme.spacing(6),
    },
  },
  imageLarge: {
    borderRadius: theme.shape.borderRadius,
    maxWidth: '150px',
    margin: 0,
  },
  halfWidth: {
    maxWidth: '48%',
  },
  imgRemoveButton: {
    position: 'relative',
    '& button': {
      position: 'absolute',
      left: '5px',
      top: '5px',
      padding: 0,
      backgroundColor: theme.palette.background.paper,
    },
    '& button svg': {
      width: '0.8em',
      height: '0.8em',
    },
  },
}))

export default ShortCollectionEdit
