import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReferenceField, TextField } from 'ra-ui-materialui'

const SalesPointAreaField: FC<any> = (props) => {
  const classes = useStyles()
  return (
    <ReferenceField link={false} label="Area" source="areaId" reference="CoopArea" {...props}>
      <TextField variant="body2" className={classes.root} source="name" />
    </ReferenceField>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 'bold',
  },
}))

export default SalesPointAreaField
