import React, { FC } from 'react'
import { FieldProps } from 'ra-ui-materialui'
import { makeStyles } from '@material-ui/core/styles'
import { FaCircle } from 'react-icons/fa'
import Typography from '@material-ui/core/Typography'
import { Variant } from '@material-ui/core/styles/createTypography'

type Props = {
  color?: string
  label?: string
  typography?: Variant
}

export enum StatusInfoLabelDefaultColors {
  SUCCESS = '#0edd4d',
  INACTIVE = '#d8d8d8',
  PROGRESS = '#5365ff',
}

const StatusInfoLabel: FC<Props> = ({
  color = StatusInfoLabelDefaultColors.INACTIVE,
  label = 'N.D.',
  typography = 'body2',
}) => {
  const classes = useStyles()

  return (
    <span className={classes.root}>
      <FaCircle size="0.6rem" color={color} />
      <Typography variant={typography} className={classes.label} noWrap>
        {label}
      </Typography>
    </span>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: theme.spacing(2),
  },
}))

export default StatusInfoLabel
