import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryWithStore } from 'ra-core'
import { useApolloClient } from '@apollo/client'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Barcode from 'react-barcode'
import { ReferenceField, TextField } from 'react-admin'

import { QUERY_GET_PRODUCTSRESERVATION_AVANCED } from '../../queries'
import BarcodeField from '../../components/BarcodeField'
import ProductReservationStatusField from '../../components/ProductReservationStatusField'
import { FaPrint } from 'react-icons/fa'
import BaseButton from '../../components/button/BaseButton'
import Box from '@material-ui/core/Box'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { SingleMechanicField } from '../../components/SIngleMechanicField'
import { ContributionMechanicField } from '../../components/ContributionMechanicField'
import Encoder from 'code-128-encoder'

import ReactToPdf from '../../components/react-to-pdf/ReactToPdf'
import classNames from 'classnames'

const encoder = new Encoder()

type Props = {
  [x: string]: any
}

const LIMIT = 5 // max result to show in a single page

const ReservationPrint: FC<Props> = ({ record, shortCollection }) => {
  const client = useApolloClient()
  const [open, setOpen] = useState<boolean>(false)
  const [products, setProducts] = useState<Record<string, any>>({ data: [] })
  const [loading, setLoading] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const isStatic = useMemo(() => !!shortCollection?.isStaticCollection, [shortCollection?.isStaticCollection])
  const rules = useMemo(() => shortCollection?.rules || {}, [shortCollection?.rules])
  const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>('vertical')
  const { loading: prodCatLoading, data: prodCatData } = useQueryWithStore({
    type: 'getOne',
    resource: 'ProductCategory',
    payload: { id: record.productCategoryId },
  })

  const classes = useStyles()

  const handleClickOpen = useCallback((): void => {
    setOpen(true)
  }, [])
  const handleClose = useCallback((): void => {
    setCurrentPage(1)
    setOpen(false)
  }, [])

  const onCompletePrint = useCallback((): void => {
    if (currentPage === totalPages) handleClose()
    else setCurrentPage(currentPage + 1)
  }, [currentPage, totalPages])

  const footerMargin = useMemo((): number => {
    if (open) {
      let currentElements = 0
      products.data.forEach((x: any, i: number) => {
        if (i >= LIMIT * (currentPage - 1) && i < LIMIT * currentPage) ++currentElements
      })
      return 0.5 + (LIMIT - currentElements) * 2
    } else return 1
  }, [products.data.length, currentPage, open])

  useEffect(() => {
    const initializeData = async (): Promise<void> => {
      setLoading(true)
      try {
        const { data } = await client.query({
          query: QUERY_GET_PRODUCTSRESERVATION_AVANCED,
          variables: {
            filters: { reservationId: record.id },
            pagination: {
              disabled: true,
            },
          },
        })

        if (data?.productReservations?.data?.length && data.productReservations.data.length > 0) {
          const mappedResult = data.productReservations.data.map((elem: any) => ({
            ...elem.product,
            id: elem.id,
            productReservationStatus: elem.productReservationStatus,
            shortCollectionProduct: elem.product,
          }))

          setTotalPages(Math.ceil(mappedResult.length / LIMIT))
          setProducts({ data: mappedResult })
        }
      } catch (error) {
        console.error('Error fetching products', error)
      } finally {
        setLoading(false)
      }
    }

    initializeData()
  }, [])

  return (
    <>
      <Box display="inline" mr={2}>
        <BaseButton onClick={handleClickOpen} icon={<FaPrint />} />
      </Box>
      <ReactToPdf
        filename={`prenotazione-${record.id}${totalPages > 1 ? `-pag${currentPage}di${totalPages}` : ''}.pdf`}
        onComplete={onCompletePrint}
      >
        {({ toPdf, targetRef }: any): React.ReactNode => (
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            classes={{ root: classes.wrapper, container: classes.dialog }}
          >
            <AppBar color="default">
              <Toolbar className={classes.toolbar}>
                <BaseButton onClick={handleClose} color="secondary" startIcon={<HiArrowNarrowLeft />}>
                  Annulla Stampa
                </BaseButton>
                {totalPages > 1 && <Typography variant="body1">{`Pagina ${currentPage}`}</Typography>}
                <BaseButton onClick={toPdf} endIcon={<FaPrint />} color="primary" variant="contained">
                  {totalPages === 1 ? 'Stampa' : `Stampa pagina ${currentPage}`}
                </BaseButton>
              </Toolbar>
            </AppBar>
            {products?.data?.length && products?.data?.length > 0 && (
              <div
                ref={targetRef}
                style={{
                  backgroundColor: 'white',
                  width: orientation === 'horizontal' ? '297mm' : '21cm',
                  padding: '1cm 1cm',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
                      <Typography variant="h1">{`Prenotazione #${record.id}`}</Typography>
                      <ReferenceField
                        record={record}
                        basePath="/Reservation"
                        source="productCategoryId"
                        reference="ProductCategory"
                        link={false}
                      >
                        <TextField source="name" variant="h5" />
                      </ReferenceField>
                      <Typography variant="caption">{`Data stampa: ${new Date().toLocaleString()}`}</Typography>
                    </div>
                    <div>
                      <Typography variant="body2">{`Pagina ${currentPage} di ${totalPages}`}</Typography>
                      <Barcode value={'r' + record.id} displayValue={false} height={50} width={1} />
                    </div>
                  </div>
                  <div style={{ margin: '0.5cm 0 1cm' }}>
                    <Typography variant="body2">{`Nome: ${record.clientFirstName}`}</Typography>
                    <Typography variant="body2">{`Cognome: ${record.clientLastName}`}</Typography>
                    {record?.clientEmail && <Typography variant="body2">{`E-mail: ${record.clientEmail}`}</Typography>}
                    {record?.clientPhone && (
                      <Typography variant="body2">{`Telefono: ${record.clientPhone}`}</Typography>
                    )}
                    {record?.clientEan && <Typography variant="body2">{`EAN: ${record.clientEan}`}</Typography>}
                  </div>
                  <table style={{ fontSize: '8pt', width: '19cm' }} className={classes.table}>
                    <thead className={classes.tableHead}>
                      <th>ID / Nome / Status</th>
                      {!isStatic && rules.stamps && <th className={classes.eanHead}>bollini</th>}
                      {!isStatic && rules.stampsContribution && (
                        <th className={classes.eanHead}>bollini + contributo</th>
                      )}
                      {!isStatic && rules.points && <th className={classes.eanHead}>punti</th>}
                      {!isStatic && rules.pointsContribution && <th className={classes.eanHead}>punti + contributo</th>}
                      {!isStatic && rules.contribution && <th className={classes.eanHead}>contributo</th>}
                      {!isStatic && rules.stampsPointsContribution && (
                        <th className={classes.eanHead}>bollini + punti + contributo</th>
                      )}
                    </thead>
                    <tbody className={classes.table}>
                      {products.data.map((prod: any, index: number) => {
                        const shouldRender = index >= LIMIT * (currentPage - 1) && index < LIMIT * currentPage

                        if (!shouldRender) {
                          return null
                        }

                        return [
                          <tr className={classes.tableRow} key={`${prod.id}-conditions`}>
                            <td className={classNames(classes.mechanicsBox, classes.firstColumn)}>
                              <div className={classes.center}>
                                <span style={{ fontWeight: 700 }}>#{prod.id}</span>
                                <ProductReservationStatusField record={prod} typography="caption" />
                              </div>
                            </td>
                            {!isStatic && rules.stamps && (
                              <td className={classes.mechanicsBox}>
                                <div className={classes.center}>
                                  <SingleMechanicField record={prod.shortCollectionProduct} source="numStampsStamps" />
                                </div>
                              </td>
                            )}
                            {!isStatic && rules.stampsContribution && (
                              <td className={classes.mechanicsBox}>
                                <div className={classes.center}>
                                  <ContributionMechanicField
                                    record={prod.shortCollectionProduct}
                                    stampsQuantitySource="numStampsStampsContribution"
                                    contributionSource="contributionStampsContribution"
                                  />
                                </div>
                              </td>
                            )}
                            {!isStatic && rules.points && (
                              <td className={classes.mechanicsBox}>
                                <div className={classes.center}>
                                  <SingleMechanicField record={prod.shortCollectionProduct} source="numPointsPoints" />
                                </div>
                              </td>
                            )}
                            {!isStatic && rules.pointsContribution && (
                              <td className={classes.mechanicsBox}>
                                <div className={classes.center}>
                                  <ContributionMechanicField
                                    record={prod.shortCollectionProduct}
                                    pointsQuantitySource="numPointsPointsContribution"
                                    contributionSource="contributionPointsContribution"
                                  />
                                </div>
                              </td>
                            )}
                            {!isStatic && rules.contribution && (
                              <td className={classes.mechanicsBox}>
                                <div className={classes.center}>
                                  <SingleMechanicField
                                    record={prod.shortCollectionProduct}
                                    source="contributionContribution"
                                    type="currency"
                                    appendix="€"
                                  />
                                </div>
                              </td>
                            )}
                            {!isStatic && rules.stampsPointsContribution && (
                              <td className={classes.mechanicsBox}>
                                <div className={classes.center}>
                                  <ContributionMechanicField
                                    record={prod.shortCollectionProduct}
                                    stampsQuantitySource="numStampsStampsPointsContribution"
                                    pointsQuantitySource="numPointsStampsPointsContribution"
                                    contributionSource="contributionStampsPointsContribution"
                                  />
                                </div>
                              </td>
                            )}
                          </tr>,
                          // CODE BARS
                          <tr className={classes.tableRow} key={`${prod.id}-qrcode`}>
                            <td className={classNames(classes.eanBox, classes.firstColumn)}>{prod.name}</td>
                            {!isStatic && rules.stamps && (
                              <td className={classes.eanBox}>
                                <BarcodeField
                                  height={50}
                                  record={prod.shortCollectionProduct}
                                  source="eanStamps"
                                  preKey={''}
                                />
                              </td>
                            )}
                            {!isStatic && rules.stampsContribution && (
                              <td className={classes.eanBox}>
                                <BarcodeField
                                  height={50}
                                  record={prod.shortCollectionProduct}
                                  source="eanStampsContribution"
                                  preKey={''}
                                />
                              </td>
                            )}
                            {!isStatic && rules.points && (
                              <td className={classes.eanBox}>
                                <BarcodeField
                                  height={50}
                                  record={prod.shortCollectionProduct}
                                  source="eanPoints"
                                  preKey={''}
                                />
                              </td>
                            )}
                            {!isStatic && rules.pointsContribution && (
                              <td className={classes.eanBox}>
                                <BarcodeField
                                  height={50}
                                  record={prod.shortCollectionProduct}
                                  source="eanPointsContribution"
                                  preKey={''}
                                />
                              </td>
                            )}
                            {!isStatic && rules.contribution && (
                              <td className={classes.eanBox}>
                                <BarcodeField
                                  height={50}
                                  record={prod.shortCollectionProduct}
                                  source="eanContribution"
                                  preKey={''}
                                />
                              </td>
                            )}
                            {!isStatic && rules.stampsPointsContribution && (
                              <td className={classes.eanBox}>
                                <BarcodeField
                                  height={50}
                                  record={prod.shortCollectionProduct}
                                  source="eanStampsPointsContribution"
                                  preKey={''}
                                />
                              </td>
                            )}
                          </tr>,
                        ]
                      })}
                    </tbody>
                  </table>
                  <div style={{ maxWidth: '19cm', marginTop: `${footerMargin}cm` }}>
                    <Typography variant="caption">
                      Autorizzo il trattamento dei dati personali esclusivamente per la consegna del prodotto sotto
                      prenotato, in conformità al Reg. UE 2016/679
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </Dialog>
        )}
      </ReactToPdf>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  pdfViewer: {
    width: '100%',
    frameborder: '0',
    border: 'none',
  },
  wrapper: {
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,.25)',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'scroll',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialog: {
    marginTop: '64px',
    // marginBottom: theme.spacing(3),
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
  },
  table: {
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    borderCollapse: 'collapse',
    '& th': {
      fontSize: '6pt',
    },
  },
  tableHead: {
    textAlign: 'left',
    boxShadow: '0px 1px rgba(0,0,0,0.1);',
    '& > th': {
      paddingBottom: theme.spacing(2),
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
      backgroundColor: 'white',
      border: 'none',
      outline: 'none',
    },
  },
  tableRow: {
    border: 0,
    borderColor: theme.palette.grey[300],
    boxShadow: '0px 1px rgba(0,0,0,0.1);',
    backgroundColor: 'white',
    // '& > td > div': {
    //   display: 'inline-flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   textAlign: 'center',
    //   backgroundColor: 'white',
    //   border: 'none',
    //   outline: 'none',
    // },
  },
  eanHead: { textAlign: 'center' },
  firstColumn: {
    backgroundColor: 'white',
    padding: '5px',
    textAlign: 'center',
  },
  mechanicsBox: {
    backgroundColor: 'white',
    border: '1px solid #999',
    borderBottom: 'none',
    padding: '2px',
    borderCollapse: 'collapse',
  },
  eanBox: {
    backgroundColor: 'white',
    border: '1px solid #999',
    borderTop: 'none',
    padding: '1px',
    borderCollapse: 'collapse',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default ReservationPrint
