import React, { FC, useMemo } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { fade } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import { createSelectable, TSelectableItemProps } from 'react-selectable-fast'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.dark, theme.palette.action.focusOpacity) + ' !important',
    },
  },
  selectingBackground: {
    backgroundColor: fade(theme.palette.primary.main, theme.palette.action.focusOpacity),
  },
  selectedBackground: {
    backgroundColor: fade(theme.palette.primary.main, theme.palette.action.activatedOpacity) + ' !important',
  },
}))

export type SalesPointListItemViewProps = {
  record: Record<string, any>
  selectedResourceIds: string[]
  selected?: boolean
  disabled?: boolean
}

type Props = TSelectableItemProps & SalesPointListItemViewProps

export const SalesPointListItemView: FC<Props> = (props) => {
  const { selectableRef, isSelected, isSelecting, selected, record, selectedResourceIds } = props
  // const isResourceSelected = useMemo(() => props.record.id && selectedResourceIds.indexOf(props.record.id), [
  //   props.record,
  //   selectedResourceIds,
  // ])
  const classes = useStyles(props)

  return (
    <ListItem
      ref={selectableRef}
      className={clsx(classes.root, `sales-point-selectable-list-${record.id}`, {
        // [classes.selectedBackground]: isSelected,
        [classes.selectingBackground]: isSelecting,
      })}
      // classes={{
      //   selected: classes.selectedBackground,
      // }}
      color="primary"
      button
      disableRipple
      // selected={isSelected}
    >
      <ListItemText
        primary={record.name}
        // secondary={record.vehicleModel ? record.vehicleModel.name : undefined}
      />

      <ListItemSecondaryAction>
        <Checkbox
          color={isSelecting ? 'default' : 'primary'}
          // edge="end"
          checked={isSelected}
          indeterminate={isSelecting}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const SalesPointListItem = createSelectable(SalesPointListItemView)

SalesPointListItem.prototype.componentWillReceiveProps = function (props: any): any {
  if (props.isSelected != this.state.isSelected) this.setState({ ...this.state, isSelected: props.isSelected })
}

export default SalesPointListItem
