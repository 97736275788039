import { useTranslate } from 'ra-core'
import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Barcode from 'react-barcode'
import { TextField } from 'react-admin'
import BaseTypography from './BaseTypography'
import { makeStyles } from '@material-ui/core'

type BarcodeFieldProps = {
  record?: any
  source?: string
  preKey?: string
  height?: number
  sortable?: boolean
  label?: string
}

const BarcodeField: FC<BarcodeFieldProps> = (props) => {
  const { record, source, height = 50, preKey = 'r', label, ...rest } = props
  const classes = useStyles()

  if (!record) {
    return null
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.root}>
      <TextField source={source} style={{ display: 'none' }} {...rest} />
      {record?.[`${source}`] && (
        <div>
          <Barcode value={preKey + record?.[`${source}`]} displayValue={false} height={height} width={1.1} />
        </div>
      )}
      <BaseTypography variant="caption">{preKey + (record?.[`${source}`] || '')}</BaseTypography>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      backgroundColor: 'transparent',
      padding: 0,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    },
    '& > svg > rect': {
      fill: 'transparent !important',
    },
    '& > svg > g': {
      transform: 'translate(0, 10px)',
    },
  },
}))

export default BarcodeField
