import React, { useCallback, useMemo, FC, ReactElement } from 'react'
import { TablePagination, TablePaginationBaseProps, Toolbar, useMediaQuery, Theme } from '@material-ui/core'
import { useTranslate, useListPaginationContext, sanitizeListRestProps, ComponentPropType } from 'ra-core'

import DefaultPaginationActions from './PaginationActions'
import DefaultPaginationLimit from './PaginationLimit'

const emptyArray: any = []

const Pagination: FC<PaginationProps> = (props) => {
  const { rowsPerPageOptions, actions = DefaultPaginationActions, limit = null, ...rest } = props
  const { loading, page, perPage, total, setPage, setPerPage } = useListPaginationContext(props)
  const translate = useTranslate()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const totalPages = useMemo(() => {
    return Math.ceil(total / perPage) || 1
  }, [perPage, total])

  /**
   * Warning: material-ui's page is 0-based
   */
  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation()
      if (page < 0 || page > totalPages - 1) {
        throw new Error(
          translate('ra.navigation.page_out_of_boundaries', {
            page: page + 1,
          })
        )
      }
      setPage(page + 1)
    },
    [totalPages, setPage, translate]
  )

  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value)
    },
    [setPerPage]
  )

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) => (
      <>
        Visualizzando <strong>{from}</strong>-<strong>{to}</strong> di <strong>{count}</strong>
        {/* {translate('ra.navigation.page_range_info', {
          offsetBegin: from,
          offsetEnd: to,
          total: count,
        })} */}
      </>
    ),
    [translate]
  )

  // Avoid rendering TablePagination if "page" value is invalid
  if (total === null || total === 0 || page < 1 || page > totalPages) {
    return loading ? <Toolbar variant="dense" /> : limit
  }

  if (isSmall) {
    return (
      <TablePagination
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={handlePageChange}
        rowsPerPageOptions={emptyArray}
        component="span"
        labelDisplayedRows={labelDisplayedRows}
        {...sanitizeListRestProps(rest)}
      />
    )
  }

  return (
    <>
      <TablePagination
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        ActionsComponent={actions as any}
        component="span"
        labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
        labelDisplayedRows={labelDisplayedRows}
        rowsPerPageOptions={emptyArray}
        {...sanitizeListRestProps(rest)}
      />
    </>
  )
}

export interface PaginationProps extends TablePaginationBaseProps {
  rowsPerPageOptions?: number[]
  actions?: React.ReactNode
  limit?: ReactElement
}

export default React.memo(Pagination)
