import React, { FC } from 'react'
import List from '../../components/list/List'
import { TextField, ReferenceField, Filter } from 'react-admin'
import FilterTextInput from '../../components/FilterTextInput'
import { FaShoppingBasket } from 'react-icons/fa'
import Datagrid from '../../components/Datagrid'

const ProductFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca" source="name" alwaysOn />
    </Filter>
  )
}

const ProductList: FC = (props) => {
  return (
    <List
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      filters={<ProductFilters variant="outlined" />}
      titleIcon={<FaShoppingBasket />}
      actions={<></>}
    >
      <Datagrid rowClick={false}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="shortCollectionId" reference="ShortCollection" linkType={false}>
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

export default ProductList
