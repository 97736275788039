import React, { FC } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import ErrorIcon from '@material-ui/icons/Error'

type Props = {
  count: number
  title: string
  caption?: string
  status?: 'positive' | 'negative'
  loading?: boolean
  error?: boolean
}

const StatCard: FC<Props> = ({ count, title, caption, status, loading, error }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box
          px={3}
          py={3}
          color={status === 'positive' ? 'success.main' : status && 'primary.main'}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <>
              <ErrorIcon color="error" />
              <Typography variant="body2" color="textPrimary">
                (Aggiorna la pagina per riprovare)
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h2">{count}</Typography>
              <Typography variant="body1" color="textPrimary">
                {title}
              </Typography>
            </>
          )}
          {caption && (
            <Box display="flex" justifyContent="center" alignItems="center">
              {status == 'positive' && <MdArrowDropUp size="2rem"></MdArrowDropUp>}
              {status == 'negative' && <MdArrowDropDown size="2rem"></MdArrowDropDown>}
              <Typography variant="subtitle2" color="textPrimary">
                {caption}
              </Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
  },
}))

export default StatCard
