import React, { FC } from 'react'
import TextField from '@material-ui/core/TextField'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

const defaultMaskOptions = {
  prefix: '€ ',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 4, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

type Props = {
  variant?: string
  label?: string
  name?: string
  value: string
  onChange: (event: any) => void
  classes?: Record<string, any>
  [x: string]: any
}

const MuiCurrencyInput: FC<Props> = ({ variant = 'outlined', label, name, value, onChange, classes, ...rest }) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      InputProps={{
        classes: { ...classes },
        inputComponent: TextMaskCustom as any,
      }}
      {...rest}
    />
  )
}

const TextMaskCustom: FC<any> = (props: any) => {
  const { inputRef, ...other } = props
  const currencyMask = createNumberMask(defaultMaskOptions)

  return <MaskedInput {...other} ref={(ref: any): any => inputRef(ref ? ref.inputElement : null)} mask={currencyMask} />
}

export default MuiCurrencyInput

export const formatCurrencyInput = (value: string): any =>
  value ? parseFloat(value.replace('€', '').replace(',', '.').trim()) : value
