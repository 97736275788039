import * as React from 'react'
import { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { EditControllerProps, ComponentPropType, useEditContext } from 'ra-core'
import DefaultActions from './EditActions'
import { FC } from 'react'
import { EditProps } from 'ra-ui-materialui'
import { Fade } from '@material-ui/core'

interface EditViewProps extends EditProps, Omit<EditControllerProps, 'resource'> {}

export const EditView: FC<EditViewProps> = (props) => {
  const { actions, aside, children, classes: classesOverride, className, component, title, undoable, ...rest } = props

  const classes = useStyles(props)
  const Content = component || Card

  const {
    basePath,
    defaultTitle,
    hasList,
    hasShow,
    record,
    redirect,
    resource,
    save,
    saving,
    version,
    loaded,
    loading,
  } = useEditContext(props)

  const finalActions = typeof actions === 'undefined' && hasShow ? <DefaultActions /> : actions
  if (!children) {
    return null
  }
  return (
    <Fade in={loaded}>
      <div className={classnames('edit-page', classes.root, className)} {...sanitizeRestProps(rest)}>
        {finalActions &&
          cloneElement(finalActions, {
            basePath,
            data: record,
            hasShow,
            hasList,
            resource,
            //  Ensure we don't override any user provided props
            ...finalActions.props,
          })}
        <div
          className={classnames(classes.main, {
            [classes.noActions]: !finalActions,
          })}
        >
          <Content className={classes.card}>
            {record ? (
              cloneElement(Children.only(children as any), {
                basePath,
                record,
                redirect:
                  typeof (children as any).props.redirect === 'undefined' ? redirect : (children as any).props.redirect,
                resource,
                save,
                saving,
                undoable,
                version,
              })
            ) : (
              <CardContent>&nbsp;</CardContent>
            )}
          </Content>
          {aside &&
            React.cloneElement(aside, {
              basePath,
              record,
              resource,
              version,
              save,
              saving,
            })}
        </div>
      </div>
    </Fade>
  )
}

EditView.defaultProps = {
  classes: {},
  component: Card,
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(10),
    },
    main: {
      display: 'flex',
    },
    noActions: {
      marginTop: '1em',
    },
    card: {
      flex: '1 1 auto',
      maxWidth: '100%',
    },
  }),
  { name: 'RaEdit' }
)

const sanitizeRestProps = ({
  basePath = null,
  defaultTitle = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  history = null,
  id = null,
  loaded = null,
  loading = null,
  location = null,
  match = null,
  onFailure = null,
  onSuccess = null,
  options = null,
  permissions = null,
  save = null,
  saving = null,
  setOnFailure = null,
  setOnSuccess = null,
  setTransform = null,
  successMessage = null,
  transform = null,
  ...rest
}: any): any => rest
