import React, { forwardRef } from 'react'
import { useWebMask } from 'react-hook-mask'
import { TextPhoneInput } from './FormPhoneNumberInput'
import { useInput } from 'react-admin'
import { Typography, makeStyles } from '@material-ui/core'

export const MaskedInput = forwardRef(({ maskGenerator, keepMask, ...props }: Record<string, any>, ref) => {
  const classes = useStyles()
  const {
    input: { name, onChange, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const maskProps = useWebMask({
    maskGenerator,
    value: value != null ? `${value}` : undefined,
    onChange,
    keepMask,
    ref,
  })

  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputWrapper}>
        <TextPhoneInput {...props} {...(maskProps as any)} />
      </div>
      <Typography className={classes.helper} variant="body2">
        {props.helperText}
      </Typography>
    </div>
  )
})

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    width: '50%',
    alignSelf: 'stretch',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  helper: {
    width: '50%',
    marginLeft: theme.spacing(8),
  },
}))
