import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Edit from '../../components/details/Edit'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'

type Props = {
  [x: string]: any
}

const CoopAreaEdit: FC<Props> = (props) => {
  return (
    <Edit {...props} component="div">
      <SectionedForm variant="outlined" redirect="list" title="Modifica Area">
        <FormSection title="Nome Area">
          <FormTextInput {...props} source="name" helperText="" />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default CoopAreaEdit
