import { required, sanitizeEmptyValues, usePermissions } from 'ra-core'
import React, { FC, useCallback } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { ReferenceInput, SelectInput } from 'react-admin'
import { useFormState, FormRenderProps } from 'react-final-form'
import { useQuery } from '@apollo/client'
import { QUERY_GET_SHORT_COLLECTION } from '../../queries'
import Loading from '../../components/Loading'
import { useLocation } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import ProductStockAvailabilityField from './ProductStockAvailabilityField'
import QuantityChooserInput from '../../components/QuantityChooserInput'
import BaseButton from '../../components/button/BaseButton'
import { hasPermission } from '../../utils/hasPermission'

type Props = {
  basePath?: string
  record?: any
  save?: any
  saving?: boolean
  version?: number | string
  shortCollectionId?: string
  onSubmit?: (values: { productId: string; quantity: number }) => void
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

const useQueryParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search)
}

const FormView: FC<FormRenderProps<any, any>> = (props) => {
  const { values, valid } = useFormState()
  const query = useQueryParams()
  const { permissions, loading: permissionsLoading } = usePermissions()
  const { loading, data } = useQuery(QUERY_GET_SHORT_COLLECTION, {
    variables: {
      id: query.get('shortCollectionId'),
    },
    skip: !query.has('shortCollectionId'),
  })

  if (loading || permissionsLoading) {
    return <Loading />
  }

  if (
    (hasPermission(permissions) && (!query.has('shortCollectionId') || !query.has('salesPointId'))) ||
    (!hasPermission(permissions) && !query.has('shortCollectionId'))
  ) {
    return <Typography color="error">Short Collection o Punto Vendita non selezionati</Typography>
  }

  return (
    <>
      <Box mb={4}>
        <ReferenceInput
          {...props}
          label="Seleziona premio"
          source="productId"
          reference="Product"
          validate={required()}
          filter={{ shortCollectionId: data.shortCollection.id }}
          fullWidth
        >
          <SelectInput variant="outlined" optionText="name" helperText="" />
        </ReferenceInput>
        {values.productId && (
          <ProductStockAvailabilityField productId={values.productId} salesPointId={query.get('salesPointId')} />
        )}
      </Box>
      <Box mb={4}>
        <Typography variant="h4">Quantità</Typography>
        <QuantityChooserInput source="quantity" defaultValue={'0'} />
      </Box>
      <Box>
        <BaseButton
          size="large"
          type="submit"
          onClick={props.handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
          disabled={!values.productId || values.quantity <= 0}
        >
          Aggiungi
        </BaseButton>
      </Box>
    </>
  )
}

const AddProductToReservationForm: FC<Props> = (props) => {
  const submit = useCallback((values: any) => {
    if (props.onSubmit) {
      props.onSubmit(sanitizeEmptyValues(props.record, values))
    }
  }, [])

  return (
    <Form
      initialValues={props.record}
      onSubmit={submit}
      mutators={{ ...arrayMutators }}
      subscription={defaultSubscription}
      key={props.version}
      keepDirtyOnReinitialize
      render={(formProps): React.ReactElement => <FormView {...formProps} />}
    />
  )
}

export default AddProductToReservationForm
