import React, { FC, useCallback, useMemo } from 'react'
import { PerimeterAccordion, PerimeterAccordionProps } from './PerimeterAccordion'
import { useFieldArray } from 'react-final-form-arrays'
import { useForm, useFormState } from 'react-final-form'
import { useApolloClient, useQuery } from '@apollo/client'
import { QUERY_GET_ALL_COOP_AREAS, QUERY_GET_COOP_ZONES, QUERY_GET_SALES_POINTS } from '../../../queries'
import { SelectableGroup, SelectAll, DeselectAll } from 'react-selectable-fast'
import { Box, Button, IconButton, List, ListItem, ListSubheader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import CoopAreaListItem from './CoopAreaListItem'
import { useNotify } from 'ra-core'
import delve from 'dlv'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

type Props = {
  source: string
  zoneIdsSource: string
  salesPointIdsSource: string
  defaultValue?: any
  onGoToNextStep: () => void
  alreadyTakenSalesPointIds: string[]
} & Partial<PerimeterAccordionProps>

export const CoopAreaSelectabeListInput: FC<Props> = ({
  source,
  defaultValue,
  onGoToNextStep,
  zoneIdsSource,
  salesPointIdsSource,
  alreadyTakenSalesPointIds,
  ...props
}) => {
  const classes = useStyles()
  const { fields, meta } = useFieldArray(source, { defaultValue })
  const { change } = useForm()
  const { values } = useFormState()
  const { data, loading } = useQuery(QUERY_GET_ALL_COOP_AREAS)
  const client = useApolloClient()
  const notify = useNotify()
  const salesPointIds = useMemo(() => delve(values, salesPointIdsSource.replaceAll('[', '.').replaceAll(']', '')), [
    values,
  ])

  const handleResourcesSelected = useCallback(
    async (selectedComponents: any[]) => {
      //
      const selectedIds = selectedComponents
        .filter((item: any) => item.props && item.props.record)
        .map((item) => item.props.record.id)

      change(source, selectedIds)

      if (selectedIds && selectedIds.length > 0) {
        try {
          // select all zones available
          const { data: zonesData } = await client.query({
            query: QUERY_GET_COOP_ZONES,
            variables: {
              filters: {
                coopAreaIds: selectedIds,
              },
              pagination: {
                disabled: true,
              },
              sort: {
                name: 'ASC',
              },
            },
          })

          if (!zonesData?.coopZones?.data) {
            throw new Error('Errore nella ricezione della zona')
          }

          const newZoneIds = (zonesData.coopZones.data as any[]).map((item) => item.id)

          change(zoneIdsSource, newZoneIds)

          if (newZoneIds && newZoneIds.length > 0) {
            // select all salesPoints available
            const { data: salesPointsData } = await client.query({
              query: QUERY_GET_SALES_POINTS,
              variables: {
                filters: {
                  coopZoneIds: newZoneIds,
                },
                pagination: {
                  disabled: true,
                },
                sort: {
                  name: 'ASC',
                },
              },
            })

            if (!salesPointsData?.salesPoints?.data) {
              throw new Error('Errore nella ricezione dei Punti Vendita')
            }

            const currentValues = salesPointIds || []
            const filteredAlreadyTakenSalesPoints = (alreadyTakenSalesPointIds || []).filter(
              (item) => !(currentValues.indexOf(item) > -1)
            )
            change(
              salesPointIdsSource,
              (salesPointsData.salesPoints.data as any[])
                .filter((item) => filteredAlreadyTakenSalesPoints.indexOf(item.id) <= -1)
                .map((item) => item.id)
            )
          } else {
            change(salesPointIdsSource, [])
          }
        } catch (e) {
          console.error('Error setting zones or salespoint values', e)
          notify((e as any).message, 'error')
          change(zoneIdsSource, [])
          change(salesPointIdsSource, [])
        }
      } else {
        change(zoneIdsSource, [])
        change(salesPointIdsSource, [])
      }
    },
    [change, alreadyTakenSalesPointIds, salesPointIds]
  )

  return (
    <PerimeterAccordion {...props}>
      <SelectableGroup
        enableDeselect
        globalMouse={true}
        allowClickWithoutSelected={true}
        onSelectionFinish={handleResourcesSelected}
        className={classes.root}
        deselectOnEsc={false}
      >
        <List
          subheader={
            <ListSubheader className={classes.subheader}>
              <div className={classes.selectAllContainer}>
                {data &&
                data.coopAreas &&
                data?.coopAreas?.data &&
                data.coopAreas.data.length === (fields.value || []).length ? (
                  <DeselectAll>
                    <IconButton className={classes.selectAllButton} size="small">
                      <CheckBoxIcon />
                    </IconButton>
                  </DeselectAll>
                ) : (
                  <SelectAll>
                    <IconButton className={classes.selectAllButton} size="small">
                      <CheckBoxOutlineBlankIcon />
                    </IconButton>
                  </SelectAll>
                )}
              </div>
            </ListSubheader>
          }
          className={classes.list}
        >
          {loading ? (
            <Skeleton>
              <ListItem />
              <ListItem />
              <ListItem />
            </Skeleton>
          ) : (
            data?.coopAreas?.data?.map((area: any, index: number) => {
              return (
                <CoopAreaListItem
                  key={`coop-area-list-item-${area.id}`}
                  selectedResourceIds={fields?.value || []}
                  record={area}
                  isSelected={fields?.value?.indexOf(area.id) > -1 || false}
                />
              )
            })
          )}
        </List>
      </SelectableGroup>

      <div className={classes.bottomActionsContainer}>
        <Box />
        <Button
          // variant="contained"
          // color="default"
          className={classes.nextStepButton}
          onClick={onGoToNextStep}
          disabled={!fields || !fields.length || fields.length <= 0}
          endIcon={<NavigateNextIcon />}
          size="small"
        >
          Seleziona zone
        </Button>
      </div>
    </PerimeterAccordion>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  list: {
    width: '100%',
  },
  accordionDetails: {},
  subheader: {
    // backgroundColor: theme.palette.background.paper,
    // padding: 0,
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
  selectAllContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  selectAllButton: {
    width: 42,
    height: 42,
  },
  nextStepButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(5),
  },
  bottomActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))
