import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

type Props = {
  label?: string
  source: string
  record?: Record<string, any>
  appendix?: string
  type?: 'decimal' | 'currency'
}

/**
 * @description Custom field to show single mechanic in a chip-like field
 * @param label - mandatory, otherwise no header will be shown inside datagrid
 * @param source
 * @param record
 */
export const SingleMechanicField: FC<Props> = ({ source, record = {}, appendix = 'bol', type = 'decimal' }) => {
  const classes = useStyles()

  if (record[source] === undefined)
    return (
      <Typography variant="body2" color="textSecondary">
        N.D.
      </Typography>
    )
  else
    return (
      <span className={classes.wrapper}>
        <Typography variant="h6" className={classes.text}>
          {`${type === 'decimal' ? record[source] : (record[source] || 0).toFixed(2).replace('.', ',')} ${appendix}`}
        </Typography>
      </span>
    )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // border: '1px solid #c6cbd4',
    // borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: theme.spacing(3),
    maxWidth: 80,
  },
  text: {
    color: '#481a1a',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
}))
