import React, { FC, useCallback, useMemo } from 'react'
import { PerimeterAccordion, PerimeterAccordionProps } from './PerimeterAccordion'
import { useFieldArray } from 'react-final-form-arrays'
import { useForm, useFormState } from 'react-final-form'
import { useQuery } from '@apollo/client'
import { QUERY_GET_SALES_POINTS } from '../../../queries'
import { SelectableGroup, SelectAll, DeselectAll } from 'react-selectable-fast'
import {
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import CoopSalesPointListItem from './CoopSalesPointListItem'
import delve from 'dlv'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import clsx from 'clsx'
import useFuse from '../../../utils/useFuse'
import ClearIcon from '@material-ui/icons/Clear'

type Props = {
  source: string
  areaIdsSource: string
  zoneIdsSource: string
  defaultValue?: any
  alreadyTakenSalesPointIds: string[]
} & Partial<PerimeterAccordionProps>

const CustomTextField = withStyles({
  // Override default CSS for input
  root: {
    '& .MuiInput-underline': {
      // Remove the ripple effect on input
      '&:after': {
        borderBottom: 'initial',
      },
      '&:hover': {
        '&:not(.Mui-disabled)': {
          '&:before': {
            borderBottomWidth: '1px',
          },
        },
      },
      '&:before': {},
    },
  },
})(TextField)

export const CoopSalesPointSelectabeListInput: FC<Props> = ({
  source,
  defaultValue,
  areaIdsSource,
  zoneIdsSource,
  alreadyTakenSalesPointIds,
  ...props
}) => {
  const classes = useStyles()
  const { fields, meta } = useFieldArray(source, { defaultValue })
  const { change } = useForm()
  const { values } = useFormState()
  const zoneIds = useMemo(() => delve(values, zoneIdsSource.replaceAll('[', '.').replaceAll(']', '')), [values])

  const { data, loading } = useQuery(QUERY_GET_SALES_POINTS, {
    variables: {
      pagination: {
        disabled: true,
      },
      filters: {
        coopZoneIds: zoneIds,
      },
      sort: {
        name: 'ASC',
      },
    },
    skip: !zoneIds?.length || zoneIds.length === 0,
  })

  const enabledSalesPoints = useMemo(() => {
    if (data?.salesPoints?.data?.length && data.salesPoints.data.length > 0) {
      const currentValues = fields?.value || []
      const filteredAlreadyTakenSalesPoints = (alreadyTakenSalesPointIds || []).filter(
        (item) => !(currentValues.indexOf(item) > -1)
      )

      return data.salesPoints.data.filter((item: any) => filteredAlreadyTakenSalesPoints?.indexOf(item.id) <= -1)
    }

    return []
  }, [alreadyTakenSalesPointIds, data?.salesPoints?.data, fields?.value])

  const { result: searchResult, search, term, reset } = useFuse<{ id: string; name: string }>({
    data: enabledSalesPoints,
    options: {
      keys: ['name'],
    },
  })

  const handleInputSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      search(event.target.value)
    },
    [search]
  )

  const handleMouseDownResetSearch = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }, [])

  const handleResourcesSelected = useCallback(
    (selectedComponents: any[]) => {
      //
      const selectedIds = selectedComponents
        .filter((item: any) => item.props && item.props.record)
        .map((item) => item.props.record.id)

      if (term) {
        const toRemoveItems = searchResult.map((item) => item.item.id).filter((item) => selectedIds.indexOf(item) <= -1)
        // console.log(
        //   searchResult.map((item) => item.item.id),
        //   selectedIds,
        //   toRemoveItems,
        //   fields?.value,
        //   'fields?.value'
        // )
        change(
          source,
          Array.from(new Set([...selectedIds, ...(fields?.value || [])])).filter(
            (item) => toRemoveItems.indexOf(item) <= -1
          )
        )
      } else {
        change(source, selectedIds)
      }
    },
    [change, fields?.value, searchResult, term]
  )

  return (
    <PerimeterAccordion disabled={!zoneIds?.length || zoneIds.length <= 0} {...props}>
      <SelectableGroup
        enableDeselect
        globalMouse={true}
        allowClickWithoutSelected={true}
        onSelectionFinish={handleResourcesSelected}
        className={classes.root}
        deselectOnEsc={false}
        ignoreList={['.ignored-in-list']}
      >
        <List
          subheader={
            enabledSalesPoints.length > 0 ? (
              <ListSubheader className={classes.subheader}>
                {/* {enabledSalesPoints.length === (fields.value || []).length ? (
                  <DeselectAll>
                    <Button size="small" variant="outlined">
                      Deseleziona tutti
                    </Button>
                  </DeselectAll>
                ) : (
                  <SelectAll>
                    <Button size="small" variant="outlined">
                      Seleziona tutti
                    </Button>
                  </SelectAll>
                )} */}

                <div className={classes.selectAllContainer}>
                  <CustomTextField
                    // size="small"
                    placeholder="Cerca PV ..."
                    fullWidth
                    color="secondary"
                    // variant="outlined"
                    className={clsx('ignored-in-list', classes.searchInput, { [classes.searchInputWithMargin]: !term })}
                    onChange={handleInputSearchChange}
                    value={term}
                    InputProps={{
                      className: classes.internalSearchInput,
                      endAdornment: term ? (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="reset search"
                            onClick={reset}
                            onMouseDown={handleMouseDownResetSearch}
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : undefined,
                    }}
                  />
                  {!term && (
                    <>
                      {enabledSalesPoints.length === (fields.value || []).length ? (
                        <DeselectAll className={classes.selectAllButtonContainer}>
                          <IconButton className={classes.selectAllButton} size="small">
                            <CheckBoxIcon />
                          </IconButton>
                        </DeselectAll>
                      ) : (
                        <SelectAll className={classes.selectAllButtonContainer}>
                          <IconButton className={classes.selectAllButton} size="small">
                            <CheckBoxOutlineBlankIcon />
                          </IconButton>
                        </SelectAll>
                      )}
                    </>
                  )}
                </div>
              </ListSubheader>
            ) : undefined
          }
          className={classes.list}
        >
          {loading ? (
            <Skeleton>
              <ListItem />
              <ListItem />
              <ListItem />
            </Skeleton>
          ) : (
            <>
              {enabledSalesPoints.length <= 0 && (
                <Typography className={classes.emptyLabel}>Nessun Punto Vendita disponibile</Typography>
              )}
              {searchResult.map((salesPoint, index) => {
                return (
                  <CoopSalesPointListItem
                    key={`coop-salesPoint-list-item-${salesPoint.item.id}`}
                    selectedResourceIds={fields?.value || []}
                    record={salesPoint.item}
                    isSelected={fields?.value?.indexOf(salesPoint.item.id) > -1 || false}
                  />
                )
              })}
            </>
          )}
        </List>
      </SelectableGroup>
    </PerimeterAccordion>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  list: {
    width: '100%',
  },
  subheader: {
    backgroundColor: (theme as any).palette.background.paper,
    // backgroundColor: theme.palette.background.paper,
  },
  emptyLabel: {
    opacity: 0.6,
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectAllContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    minHeight: 58,
  },
  selectAllButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  selectAllButton: {
    width: 42,
    height: 42,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  searchInput: {
    flexGrow: 1,
    // paddingRight: theme.spacing(2),
  },
  internalSearchInput: {
    paddingLeft: theme.spacing(1),
    paddingRight: 8,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  searchInputWithMargin: {
    marginRight: theme.spacing(5),
  },
}))
