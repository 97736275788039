import React, { FC, useEffect } from 'react'
import userManager from '../userManager'
import { useRedirect } from 'ra-core'

type Props = {
  //
}

const AuthCallback: FC<Props> = () => {
  const redirect = useRedirect()
  useEffect(() => {
    const loginCallback = async (): Promise<void> => {
      console.log('loginCallback() siamo qua')
      const result = await userManager.signinRedirectCallback()
      if (result && result.access_token) {
        redirect('/')
      }
    }

    loginCallback()
  }, [])

  return <div></div>
}

export default AuthCallback
