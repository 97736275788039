import React, { FC } from 'react'
import { useQueryWithStore } from 'ra-core'
import { ImageField } from 'react-admin'
import { Box, makeStyles } from '@material-ui/core'
import { FaEyeSlash } from 'react-icons/fa'

type Props = {
  [x: string]: any
}

const ProductGalleryField: FC<Props> = ({ source = 'galleryId', record = {} }) => {
  const classes = useStyles()
  const { loading, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'Gallery',
    payload: { id: record[source] },
  })

  if (data && data.picturesIds && data.picturesIds.length)
    return (
      <div className={classes.wrapper}>
        {data.picturesIds.map((elem: any) => (
          <Img id={elem} />
        ))}
      </div>
    )

  return (
    <div className={classes.wrapper}>
      <Box
        borderRadius={2}
        bgcolor="grey.300"
        color="grey.500"
        width="40px"
        height="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <FaEyeSlash fontSize="1rem" />
      </Box>
    </div>
  )
}

const Img: FC<Props> = ({ id }) => {
  const classes = useStyles()
  const { loading, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'Picture',
    payload: { id },
  })
  if (data) return <ImageField source="urlSmall" record={data} classes={{ image: classes.image }} />
  return null
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },
  image: {
    borderRadius: 4,
    minWidth: '40px',
    minHeight: '40px',
    maxWidth: '40px',
    maxHeight: '40px',
  },
}))

export default ProductGalleryField
