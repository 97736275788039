import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import { ReferenceInput } from 'react-admin'
import FormSelectInput from '../../components/FormSelectInput'
import FormAutoCompleteInput from '../../components/FormAutoCompleteInput'
import SaveUserButton from './SaveUserButton'

type Props = {
  [x: string]: any
}

const autocompleteQuery = (name: string): Record<string, any> => ({ name })
const sort = { field: 'name', order: 'ASC' }

const UserCreate: FC<Props> = (props) => {
  return (
    <Create {...props} component="div">
      <SectionedForm
        variant="outlined"
        redirect="show"
        title="Nuovo Utente"
        saveButtonComponent={<SaveUserButton />}
        // asideUpperContent={
        //   <SwitchCardInput source="myBoolean" label="Vuoi notificare l'utente?" startingValue={true} />
        // }
      >
        <FormSection title="Utente">
          <FormTextInput {...props} source="firstName" />
          <FormTextInput {...props} source="lastName" helperText="" />
          <FormTextInput {...props} source="email" />
          <FormTextInput {...props} type="password" source="password" />
          <ReferenceInput label="Seleziona ruolo" source="roleId" reference="Role">
            <FormSelectInput
              optionText="name"
              fullWidth
              helperText="Seleziona il ruolo per cambiare i permessi di accesso"
            />
          </ReferenceInput>
          {/* <FormTextInput {...props} source="password" /> */}
        </FormSection>
        <FormSection title="Punto Vendita">
          <ReferenceInput
            label="Seleziona punto vendita"
            source="salesPointId"
            reference="SalesPoint"
            filterToQuery={autocompleteQuery}
            sort={sort}
          >
            <FormAutoCompleteInput optionText="name" helperText="Seleziona punto vendita di appartenenza" />
          </ReferenceInput>
        </FormSection>
      </SectionedForm>
    </Create>
  )
}

export default UserCreate
