import React, { FC, useMemo } from 'react'
import StatusInfoLabel, { StatusInfoLabelDefaultColors } from './StatusInfoLabel'
import { Variant } from '@material-ui/core/styles/createTypography'
import { ProductReservationStatusColors, ProductReservationStatusEnum } from '../customEnums'

type Props = {
  label?: string
  record?: any
  source?: string
  typography?: Variant
}

const ProductReservationStatusField: FC<Props> = ({
  label,
  record,
  source = 'productReservationStatus',
  typography = 'body2',
}) => {
  const currentStatus = useMemo(() => (record && source && record[source] ? record[source] : undefined), [
    record,
    source,
  ])

  if (!currentStatus) {
    return <StatusInfoLabel color={StatusInfoLabelDefaultColors.INACTIVE} label="N.D." typography={typography} />
  }

  return (
    <StatusInfoLabel
      color={ProductReservationStatusColors[currentStatus]}
      label={ProductReservationStatusEnum[currentStatus] || 'N.D.'}
      typography={typography}
    />
  )
}

export default ProductReservationStatusField
