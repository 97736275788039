import React, { FC, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client/react'
import FormSelectInput from './FormSelectInput'
import { QUERY_GET_SHORT_COLLECTIONS } from '../queries'
import { useVersion } from 'ra-core'

type Props = {
  source?: string
  resource?: string
  record?: any
  activeOnly?: boolean
  [x: string]: any
}

const ShortCollectionFormSelectInput: FC<Props> = ({ activeOnly = false, ...rest }) => {
  const version = useVersion()
  const { loading, data, error, refetch } = useQuery(QUERY_GET_SHORT_COLLECTIONS, {
    variables: activeOnly
      ? {
          filters: {
            active: true,
          },
        }
      : undefined,
  })
  const choices = useMemo(
    () =>
      data?.shortCollections?.data
        ? data.shortCollections.data.map((item: any) => ({
            id: item.id,
            name: item.name,
          }))
        : [],
    [data]
  )

  useEffect(() => {
    refetch()
  }, [version])

  return <FormSelectInput {...rest} choices={choices} />
}

export default ShortCollectionFormSelectInput
