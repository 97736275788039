import React, { FC, useCallback, useEffect, useState } from 'react'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import { useFormState } from 'react-final-form'
import { useDataProvider, useNotify, useRedirect, useTranslate } from 'ra-core'
import { WizardStepProps } from '../../components/forms/WizardSectionedForm/WizardStep'
import { createPortal } from 'react-dom'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Loading from '../../components/Loading'
import { AddProductButton as CreateAddProductButton } from './ReservationCreate'
import SaveButton from '../../components/button/SaveButton'
import Typography from '@material-ui/core/Typography'
import TitleBarSubtitle from '../../components/layout/TitleBarSubtitle'
import ProductStockAvailabilityField from './ProductStockAvailabilityField'
import { QUERY_GET_SHORT_COLLECTION } from '../../queries'
import Empty from '../../components/list/Empty'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import BaseButton from '../../components/button/BaseButton'
import { NavLink } from 'react-router-dom'
import ProductReservationStatusField from '../../components/ProductReservationStatusField'
import StatusInfoLabel, { StatusInfoLabelDefaultColors } from '../../components/StatusInfoLabel'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { Confirm } from 'ra-ui-materialui'
import inflection from 'inflection'
import * as Sentry from '@sentry/browser'

type Props = WizardStepProps & {
  newReservationProductIds: string[]
  newReservationProductsTable: Record<string, any>
  reservationProductIds?: string[]
  reservationProductsTable?: Record<string, any>
  productsToRemoveIds?: string[]
  onRemoveNewProduct: (index: number) => void
  onRemoveProduct?: (index: number) => void
}

const MUTATION_CREATE_RESERVATION = gql`
  mutation CreateReservation($data: ReservationCreateInput!) {
    createReservation(data: $data) {
      id
    }
  }
`

// const QUERY_GET_SHORT_COLLECTION_CATEGORY = gql`
//   query GetShortCollectionCategory($id: ID!) {
//     shortCollection(id: $id) {
//       productCategory {
//         id
//         name
//       }
//     }
//   }
// `

// const QUERY_GET_STOCK_PRODUCTS_FROM_SHORT_COLLECTION = gql`
//   query GetStockProductsFromShortCollection($filters: CatalogFilterInput) {
//     catalogProducts(filters: $filters, pagination: { disabled: true }) {
//       total
//       error
//       offset
//       data {
//         id
//         name
//         galleryId
//       }
//     }
//   }
// `

const ReservationProductMoreMenu: FC<{
  onRemoveProduct: (index: number) => void
  index: number
}> = ({ onRemoveProduct, index }) => {
  const translate = useTranslate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOpenModal = useCallback(() => {
    setOpen(true)
    onMoreClose()
  }, [])

  const handleCloseModal = useCallback(() => {
    setOpen(false)
  }, [])

  const handleRemoveProduct = useCallback(() => {
    onRemoveProduct(index)
    handleCloseModal()
  }, [index, onRemoveProduct])

  return (
    <>
      <IconButton edge="start" onClick={onMoreClick} className={classes.iconMore}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={'reservation-product-more-menu'}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
      >
        <MenuItem button onClick={handleOpenModal}>
          <Box textAlign="left">
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Rimuovi Premio
          </Box>
        </MenuItem>
      </Menu>

      <Confirm
        isOpen={open}
        loading={loading}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        translateOptions={{
          name: translate('resources.ReservationProduct.forcedCaseName', {
            smart_count: 1,
            _: inflection.humanize(
              translate('resources.ReservationProduct.name', {
                smart_count: 1,
                _: inflection.singularize('ReservationProduct'),
              }),
              true
            ),
          }),
          // id: record.id,
        }}
        onConfirm={handleRemoveProduct}
        onClose={handleCloseModal}
      />
    </>
  )
}

const AddProductButtonView: FC<any> = (props) => {
  const { values } = useFormState()
  const classes = useStyles()

  return (
    <BaseButton
      className={classes.addProductButton}
      variant="contained"
      disabled={!values.shortCollectionId || !values.salesPointId}
      fullWidth
      component={NavLink as any}
      to={`/Reservation/${props.record.id}/2/addProduct?shortCollectionId=${values.shortCollectionId}${
        values.salesPointId ? `&salesPointId=${values.salesPointId}` : ''
      }`}
      size="xl"
      {...props}
    >
      Aggiungi Premio
    </BaseButton>
  )
}

const AddProductButton: FC<any> = (props) => {
  const element = document.getElementById('add-product-button')

  return !props.disablePortal ? (
    element ? (
      createPortal(<AddProductButtonView {...props} />, element)
    ) : null
  ) : (
    <AddProductButtonView {...props} />
  )
}

const TitleBar: FC<{ currentStep?: string | number; [x: string]: any }> = ({
  currentStep,
  shortCollectionData,
  ...props
}) => {
  const { values } = useFormState()
  const element = document.getElementById('reservation-stock-subtitle')

  return element
    ? createPortal(
        <TitleBarSubtitle>
          Aggiungi premi nella prenotazione per{' '}
          <strong>
            “{values.clientFirstName} {values.clientLastName}“
          </strong>
        </TitleBarSubtitle>,
        element
      )
    : null
}

const SaveReservationButton: FC<{
  newReservationProductsTable: Record<string, any>
  newReservationProductIds: string[]
  reservationProductsTable?: Record<string, any>
  reservationProductIds?: string[]
  productsToRemoveIds?: string[]
  shortCollection?: any
  resource?: string
  record?: any
  basePath?: string
}> = (props) => {
  const element = document.getElementById('save-reservation-button')
  const [saving, setSaving] = useState<boolean>(false)
  const client = useApolloClient()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const redirectTo = useRedirect()

  const handleSave = useCallback(
    async (values, redirect) => {
      setSaving(true)

      try {
        if (props.record.id) {
          const result = await dataProvider.update('Reservation', {
            id: props.record.id,
            previousData: props.record,
            data: {
              salesPointId: values.salesPointId,
              clientEan: values.ean,
              clientFirstName: values.clientFirstName,
              clientLastName: values.clientLastName,
              clientEmail: values.clientEmail,
              clientPhone:
                !values.clientPhone?.startsWith('+') && values.clientPhone?.length > 11
                  ? `+${values.clientPhone}`
                  : values.clientPhone,
              shortCollectionId: props.shortCollection.id,
              note: values.note,
              toAddProductReservations: (props.newReservationProductIds || []).map((id) => ({
                productId: id,
                quantity: 1,
              })),
              toRemoveProductReservationIds: props.productsToRemoveIds || [],
            },
          })
          redirectTo('show', `/${props.resource}`, props.record.id)
        } else {
          const result = await client.mutate({
            mutation: MUTATION_CREATE_RESERVATION,
            variables: {
              data: {
                salesPointId: values.salesPointId,
                clientEan: values.ean,
                clientFirstName: values.clientFirstName,
                clientLastName: values.clientLastName,
                clientEmail: values.clientEmail,
                clientPhone:
                  !values.clientPhone?.startsWith('+') && values.clientPhone?.length > 11
                    ? `+${values.clientPhone}`
                    : values.clientPhone,
                shortCollectionId: props.shortCollection.id,
                note: values.note,
                productReservations: props.newReservationProductIds.map((id) => ({
                  productId: id,
                  quantity: 1,
                })),
              },
            },
          })
          localStorage.removeItem(`_wizard_${props.resource}_create`)
          redirectTo('list', `/${props.resource}`, result.data.createReservation.id)
        }

        Sentry.captureEvent({
          message: 'Reservation saved',
          level: 'info',
          extra: {
            values,
            record: props.record,
          },
        })
      } catch (e) {
        notify((e as any).message, 'warning')
        Sentry.captureException(e)
      } finally {
        setSaving(false)
      }
    },
    [props]
  )

  if (element) {
    return createPortal(
      <SaveButton
        {...props}
        basePath={props.basePath}
        resource={props.resource}
        variant="contained"
        saving={saving}
        disabled={
          saving ||
          (props.newReservationProductIds.length === 0 &&
            (!props.reservationProductIds || props.reservationProductIds.length === 0)) ||
          !props.shortCollection
        }
        onSave={handleSave}
      />,
      element
    )
  }

  return null
}

const useStyles = makeStyles((theme) => ({
  root: {},
  rowEven: {
    backgroundColor: '#eeeeee',
  },
  rowOdd: {},
  addProductButton: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.common.white + ' !important',
  },
  iconMore: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 29,
    height: 33,
    borderRadius: theme.shape.borderRadius,
    left: '-6px',
  },
}))

const ReservationProductsTable: FC<Props> = ({
  goToStep = (): void => {
    console.error('Method not implemented')
  },
  newReservationProductIds,
  newReservationProductsTable,
  reservationProductIds,
  reservationProductsTable,
  onRemoveNewProduct,
  onRemoveProduct,
  productsToRemoveIds,
  record = {},
  ...rest
}) => {
  const { values } = useFormState()
  const classes = useStyles()
  const { loading: loadingShort, data: dataShort } = useQuery(QUERY_GET_SHORT_COLLECTION, {
    variables: {
      id: values.shortCollectionId,
    },
    skip: !values.shortCollectionId,
  })

  const handleRemoveProduct = useCallback(
    (index: number) => {
      if (onRemoveProduct) {
        onRemoveProduct(index)
      }
    },
    [onRemoveProduct]
  )

  const handleRemoveNewProduct = useCallback(
    (index: number) => {
      onRemoveNewProduct(index)
    },
    [onRemoveNewProduct]
  )

  useEffect(() => {
    if (!record.id && !values.shortCollectionId) {
      goToStep(1)
    }
  }, [])

  if (!values.shortCollectionId && !record.id) {
    return (
      <Typography variant="body2" color="error">
        Dati necessari mancanti
      </Typography>
    )
  }

  if (loadingShort) {
    return <Loading />
  }

  return (
    <Box>
      <TitleBar />
      {record && record.id && <AddProductButton record={record} />}
      <SaveReservationButton
        newReservationProductsTable={newReservationProductsTable}
        newReservationProductIds={newReservationProductIds}
        reservationProductsTable={reservationProductsTable}
        reservationProductIds={reservationProductIds}
        productsToRemoveIds={productsToRemoveIds}
        shortCollection={dataShort?.shortCollection}
        record={record}
        {...rest}
      />
      {newReservationProductIds.length === 0 && (!reservationProductIds || reservationProductIds.length === 0) ? (
        <Box>
          <Empty
            buttonComponent={
              record && record.id ? (
                <AddProductButton record={record} size="medium" disablePortal />
              ) : (
                <CreateAddProductButton size="medium" />
              )
            }
            component={Card}
            messageComponent={<Typography>Clicca "Aggiungi Premio"</Typography>}
          />
        </Box>
      ) : (
        <Card>
          <TableContainer>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Premio</TableCell>
                  <TableCell>Collection</TableCell>
                  <TableCell>Note Prenotazione</TableCell>
                  {record && record.id && <TableCell>Stato</TableCell>}
                  {/* TODO: readd this */}
                  <TableCell>Disponibilità</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {reservationProductIds &&
                  reservationProductsTable &&
                  reservationProductIds.map((id, index) => {
                    return (
                      <TableRow
                        hover
                        className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                        key={`prize-${id}-${index}`}
                      >
                        <TableCell>{reservationProductsTable[id].product.name}</TableCell>
                        <TableCell>{dataShort?.shortCollection?.name}</TableCell>
                        <TableCell>{values.note}</TableCell>
                        <TableCell>
                          <ProductReservationStatusField record={reservationProductsTable[id]} />
                        </TableCell>
                        {/* TODO: readd this */}
                        <TableCell>
                          <ProductStockAvailabilityField salesPointId={values.salesPointId} productId={id} />
                        </TableCell>
                        <TableCell>
                          <ReservationProductMoreMenu onRemoveProduct={handleRemoveProduct} index={index} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {newReservationProductIds.map((id, index) => {
                  return (
                    <TableRow
                      hover
                      className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                      key={`prize-${id}-${index}`}
                    >
                      <TableCell>{newReservationProductsTable[id].name}</TableCell>
                      <TableCell>{dataShort?.shortCollection?.name}</TableCell>
                      <TableCell>{values.note}</TableCell>
                      {record && record.id && (
                        <TableCell>
                          <StatusInfoLabel color={StatusInfoLabelDefaultColors.INACTIVE} label="Da creare" />
                        </TableCell>
                      )}
                      {/** TODO: readd this */}
                      <TableCell>
                        <ProductStockAvailabilityField salesPointId={values.salesPointId} productId={id} />
                      </TableCell>
                      <TableCell>
                        <ReservationProductMoreMenu onRemoveProduct={handleRemoveNewProduct} index={index} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </Box>
  )
}

export default ReservationProductsTable
