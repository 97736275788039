import { ReactElement, ElementType } from 'react'
import { Identifier, Exporter, Record as RaRecord } from 'ra-core'

export interface ListProps {
  children?: ReactElement
  actions?: ReactElement | false
  aside?: ReactElement
  bulkActionButtons?: ReactElement | false
  classes?: any
  className?: string
  component?: React.ElementType
  empty?: ReactElement | false
  exporter?: Exporter | false
  filter?: any
  filterDefaultValues?: any
  filters?: ReactElement
  pagination?: ReactElement | false
  perPage?: number
  sort?: any
  title?: string | ReactElement
}

export interface EditProps {
  children?: ReactElement
  actions?: ReactElement | false
  aside?: ReactElement
  classes?: any
  className?: string
  component?: ElementType
  undoable?: boolean
  onSuccess?: (data: RaRecord) => void
  onFailure?: (error: any) => void
  transform?: (data: RaRecord) => RaRecord
  title?: string | ReactElement
}

export interface CreateProps {
  children?: ReactElement
  actions?: ReactElement | false
  aside?: ReactElement
  classes?: any
  className?: string
  component?: ElementType
  record?: Partial<RaRecord>
  onSuccess?: (data: RaRecord) => void
  onFailure?: (error: any) => void
  transform?: (data: RaRecord) => RaRecord
  title?: string | ReactElement
}

export interface ShowProps {
  children?: ReactElement
  actions?: ReactElement | false
  aside?: ReactElement
  classes?: any
  className?: string
  component?: ElementType
  title?: string | ReactElement
}

export interface BulkActionProps {
  basePath?: string
  filterValues?: any
  resource?: string
  selectedIds?: Identifier[]
}

/**
 * Generic type for the classes prop allowing to override material-ui styles
 *
 * @see https://github.com/mui-org/material-ui/issues/17973#issuecomment-639281445
 *
 * @example
 *
 * const useStyles = makeStyles({
 *     root: {
 *         ...
 *     }
 * })
 *
 * const DummyComponent: FC<DummyComponentProps> = (props) => {
 *     const classes = useStyles();
 *     // ...
 *     return (
 *         <div className={classes.root}>
 *             // ...
 *         </div>
 *     );
 * }
 *
 * interface DummyComponentProps {
 *	    classes?: ClassesOverride<typeof useStyles>;
 * }
 */
export type ClassesOverride<UseStyles extends (props: any) => Record<string, string>> = Partial<
  Record<keyof ReturnType<UseStyles>, string>
>

export enum ProductReservationStatus {
  WAITING = 'WAITING',
  CUSTOMER_NOTIFIED = 'CUSTOMER_NOTIFIED',
  DELIVERED = 'DELIVERED',
  WAITING_FOR_AVAILABLE_NOTIFICATION = 'WAITING_FOR_AVAILABLE_NOTIFICATION',
  WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION = 'WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION',
}
