import React, { FC, useCallback, useState } from 'react'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import { useFormState } from 'react-final-form'
import WizardSectionedForm, { WizardStep } from '../../components/forms/WizardSectionedForm'
import { NavLink, Route, useHistory, useLocation } from 'react-router-dom'
import { useNotify } from 'ra-core'
import ReservationProductsTable from './ReservationProductsTable'
import WizardRightControls from '../../components/forms/WizardSectionedForm/WizardRightControls'
import ReservationFormSection from './ReservationFormSection'
import ReservationCustomerFormSection from './ReservationCustomerFormSection'
import { makeStyles } from '@material-ui/core/styles'
import AddProductToReservationForm from './AddProductToReservationForm'
import DrawerHeader from '../../components/DrawerHeader'
import BaseButton from '../../components/button/BaseButton'
import { useApolloClient } from '@apollo/client'
import Drawer from '../../components/Drawer'
import { QUERY_GET_PRODUCT_FOR_RESERVATION_TABLE } from '../../queries'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '30vw',
    minWidth: 300,
    paddingTop: theme.spacing(9),
    paddingRight: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    paddingLeft: theme.spacing(9),
  },
  addProductButton: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.common.white + ' !important',
  },
}))

type Props = {
  record?: any
  [x: string]: any
}

const TitleBar: FC = () => {
  return <div id="reservation-stock-subtitle" />
}

export const AddProductButton: FC<any> = (props) => {
  const { values } = useFormState()
  const classes = useStyles()

  return (
    <BaseButton
      className={classes.addProductButton}
      variant="contained"
      fullWidth
      component={NavLink as any}
      to={`/Reservation/create/2/addProduct?shortCollectionId=${values.shortCollectionId}${
        values.salesPointId ? `&salesPointId=${values.salesPointId}` : ''
      }`}
      size="xl"
      {...props}
    >
      Aggiungi Premio
    </BaseButton>
  )
}

const SaveReservationButton: FC = () => {
  return <div id="save-reservation-button" />
}

const ReservationCreate: FC<Props> = (props) => {
  const classes = useStyles(props)
  const history = useHistory()
  const { search, pathname } = useLocation()
  const client = useApolloClient()
  const notify = useNotify()
  const [reservationProductIds, setReservationProductIds] = useState<string[]>([])
  const [reservationProductsTable, setReservationProductsTable] = useState<Record<string, any>>([])

  const handleClose = useCallback(() => {
    history.goBack()
  }, [search])

  const handleAddReservationProduct = useCallback(
    async (values: any) => {
      try {
        if (!reservationProductsTable[values.productId]) {
          const result = await client.query({
            query: QUERY_GET_PRODUCT_FOR_RESERVATION_TABLE,
            variables: {
              id: values.productId,
            },
          })

          if (!result.data || !result.data.product) {
            throw new Error('Error fetching product')
          }

          setReservationProductsTable({ ...reservationProductsTable, [values.productId]: result.data.product })
        }

        setReservationProductIds([
          ...reservationProductIds,
          ...Array.from({ length: parseInt(values.quantity) }, () => {
            return values.productId
          }),
        ])
        handleClose()
      } catch (e) {
        console.error('Error adding ReservationProduct', e)
        notify('ra.message.error', 'warning')
      }
    },
    [reservationProductIds, reservationProductsTable, handleClose]
  )

  const handleRemoveProduct = useCallback(
    (index: number) => {
      const newReservationProducts = [...reservationProductIds]
      newReservationProducts.splice(index, 1)
      setReservationProductIds(newReservationProducts)
    },
    [reservationProductIds, reservationProductsTable]
  )

  return (
    <>
      <Create {...props} component="div">
        <WizardSectionedForm
          variant="outlined"
          redirect="show"
          title="Nuova Prenotazione"
          titleBar={<TitleBar />}
          // defaultValues={{
          //   clientPhone: '+39',
          // }}
        >
          <WizardStep>
            <FormSection title="Prenotazione">
              <ReservationFormSection />
            </FormSection>

            <FormSection title="Destinatario">
              <ReservationCustomerFormSection />
            </FormSection>
          </WizardStep>

          <WizardStep
            formRightControls={
              <WizardRightControls
                saveButtonComponent={<SaveReservationButton />}
                asideLowerContent={<AddProductButton />}
              />
            }
          >
            <ReservationProductsTable
              onRemoveNewProduct={handleRemoveProduct}
              newReservationProductsTable={reservationProductsTable}
              newReservationProductIds={reservationProductIds}
            />
          </WizardStep>
        </WizardSectionedForm>
      </Create>

      <Route path="/Reservation/create/:step/:drawer">
        {({ match }: any): React.ReactNode => {
          const isCreate = match && match.params && match.params.drawer === 'addProduct'
          return (
            <Drawer open={isCreate} anchor="right" onClose={handleClose}>
              {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
              {isCreate && (
                <>
                  <DrawerHeader title="Aggiungi Premio" />
                  <AddProductToReservationForm {...props} onSubmit={handleAddReservationProduct} />
                </>
              )}
            </Drawer>
          )
        }}
      </Route>
    </>
  )
}

export default ReservationCreate
