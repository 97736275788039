import React, { FC, useCallback, useState } from 'react'
import List from '../../components/list/List'
import {
  DateInput,
  DateField,
  ReferenceInput,
  TextInput,
  FunctionField,
  TextField,
  ReferenceField,
  downloadCSV,
} from 'react-admin'
import { useApolloClient } from '@apollo/client'
import jsonExport from 'jsonexport/dist'
import { parse } from 'query-string'

import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import { FaFileAlt } from 'react-icons/fa'
import Filter from '../../components/list/filter/Filter'
import StatusInfoLabel, { StatusInfoLabelDefaultColors } from '../../components/StatusInfoLabel'
import AutocompleteInputInDrawer from '../../components/AutocompleteInDrawer'
import { useDataProvider, useNotify, usePermissions, useRedirect } from 'ra-core'
import { hasPermission } from '../../utils/hasPermission'
import ExportButton from '../../components/button/ExportButtont'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import LinearProgress from '@material-ui/core/LinearProgress'
import {
  QUERY_GET_PREVIEW_SMS_JOB,
  QUERY_GET_PRODUCTSRESERVATION_CSV,
  QUERY_GET_PRODUCTSRESERVATION_CSV_TOTAL,
} from './../../queries'
import { SalesPointAreaEnum, ProductReservationStatusEnum } from '../../customEnums'
import { Typography } from '@material-ui/core'
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate'
import BarcodeReader from 'react-barcode-reader'
import { useShortCollections } from '../../hooks/useShortCollections'
import { Tooltip } from '@chakra-ui/react'

type Props = {
  [x: string]: any
}

const limit = 450
function timeout(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// const FilterToQuery = (searchText: string): any => ({ name: searchText })

const filterToQuery = (name: string): Record<string, any> => ({ name })

const ReservationFilters: FC<any> = (props) => {
  const { loaded, permissions } = usePermissions()
  return (
    <Filter {...props}>
      <FilterTextInput source="clientFullName" alwaysOn />
      <ReferenceInput filterToQuery={filterToQuery} source="shortCollectionId" reference="ShortCollection">
        <AutocompleteInputInDrawer optionText="name" fullWidth />
      </ReferenceInput>
      <TextInput source="id" />
      <TextInput source="clientEan" />
      {loaded && hasPermission(permissions) && (
        <ReferenceInput
          sort={{ field: 'name', order: 'ASC' }}
          filterToQuery={filterToQuery}
          source="salesPointId"
          reference="SalesPoint"
        >
          <AutocompleteInputInDrawer optionText="name" fullWidth />
        </ReferenceInput>
      )}
      <DateInput source="dateRangeStart" />
      <DateInput source="dateRangeEnd" />
    </Filter>
  )
}

const CustomCreateButton: FC<{ basePath: string }> = (props) => {
  const { isEmpty } = useShortCollections()

  return (
    <Tooltip
      label="Al momento non sono presenti short collection attive, siete pregati di non aprire ticket, ma di chiedere
    informazioni all’ufficio fidelizzazione"
      isDisabled={!isEmpty}
      marginTop={2}
      hasArrow
      bgColor="red.500"
    >
      <span>
        <CreateButton basePath={props.basePath} label="Crea una prenotazione" disabled={isEmpty} />
      </span>
    </Tooltip>
  )
}

const ReservationList: FC<Props> = (props) => {
  const classes = useStyles()
  const client = useApolloClient()
  const redirect = useRedirect()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const { loaded, permissions } = usePermissions()
  const { filter } = parse(props.location.search)
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [progress, setProgress] = useState<number>(0)
  const [smsExportLoading, setSmsExportLoading] = useState<boolean>(false)

  const exporter = useCallback(async (): Promise<any> => {
    setIsDialogOpen(true)
    try {
      setExportLoading(true)

      const filters = filter === null || !filter || typeof filter === 'object' ? undefined : JSON.parse(filter)
      if (filters?.id) {
        const id = filters.id
        delete filters.id
        filters.reservationId = id
      }

      const { data: total } = await client.query({
        query: QUERY_GET_PRODUCTSRESERVATION_CSV_TOTAL,
        variables: {
          pagination: {
            limit: 1,
            offset: 0,
          },
          sort: {
            id: 'DESC',
          },
          filters,
        },
      })

      if (!total || !total.productReservations || !total.productReservations.total) {
        throw new Error('Cannot get number of total results')
      }

      const maxId = total.productReservations.data?.[0]?.id

      if (!maxId) {
        throw new Error('Cannot get max id')
      }

      const realTotal = total.productReservations.total
      setTotalRows(realTotal)
      const totalPages = Math.ceil(realTotal / limit)
      let realResults: any[] = []

      for (let i = 0; i < totalPages; i++) {
        const { data } = await client.query({
          query: QUERY_GET_PRODUCTSRESERVATION_CSV,
          variables: {
            pagination: {
              limit,
              offset: i,
            },
            sort: { id: 'DESC' },
            filters: {
              ...filters,
              maxId,
            },
          },
        })
        if (
          !data ||
          !data.productReservations ||
          !data.productReservations.data ||
          data.productReservations.data.length === 0
        ) {
          throw new Error('Unable to fetch results')
        }
        const res = realResults.concat(
          data.productReservations.data.map((item: any) => ({
            'Punto Vendita': item.reservation.salesPoint.name,
            Area: SalesPointAreaEnum[item.reservation.salesPoint.coopAreaId],
            'EAN Socio': item.reservation.clientEan,
            Nome: item.reservation.clientFirstName,
            Cognome: item.reservation.clientLastName,
            Telefono: item.reservation.clientPhone,
            'ID Prenotazione': item.reservation.id,
            'ID Riga Prenotazione': item.id,
            'Data Prenotazione (creazione)': new Date(item.reservation.createdAt).toLocaleString(),
            'Data Prenotazione (aggiornamento)': new Date(item.reservation.updatedAt).toLocaleString(),
            Collection: item.reservation.shortCollection.name,
            'Nome Prodotto': item.product.name,
            Status: ProductReservationStatusEnum[item.productReservationStatus],
            'Data Chiusura':
              item.productReservationStatus === 'DELIVERED' ? new Date(item.updatedAt).toLocaleString() : '',
            SICMA: item.reservation.salesPoint.sicma,
            Note: item.reservation.note,
          }))
        )
        realResults = [...res]
        let prgrss = (limit * (i + 1) * 100) / realTotal
        if (prgrss > 100) prgrss = 100
        setProgress(prgrss)
        await timeout(2000)
      }

      jsonExport(realResults, { rowDelimiter: ';' }, (err: any, csv: any) => {
        if (err) throw new Error(err)
        downloadCSV(csv, 'lista_prenotazioni')
      })
    } catch (error) {
      console.log('Export error: ', error)
      notify('Errore nella generazione del file CSV', 'error')
    } finally {
      setExportLoading(false)
      setIsDialogOpen(false)
      setProgress(0)
    }
  }, [filter])

  const smsExporter = useCallback(async () => {
    try {
      setSmsExportLoading(true)
      const { data } = await client.query({
        query: QUERY_GET_PREVIEW_SMS_JOB,
        fetchPolicy: 'no-cache',
      })
      console.log('smsResult: ', data)
      if (!data || !data.previewSmsJob) throw new Error('Errore nel recupero del numero di SMS')

      let finalData: any[] = []
      const salesPointsDupedIds: string[] = []
      if (data && data.previewSmsJob && data.previewSmsJob.WAITING_FOR_EXCUSE_NOTIFICATION) {
        finalData = data.previewSmsJob.WAITING_FOR_EXCUSE_NOTIFICATION.map((row: any) => {
          salesPointsDupedIds.push(row.salesPointId)
          return {
            salesPointId: row.salesPointId,
            'ID prenotazione': row.reservationId,
            // 'EAN prodotto': row.ean,
            'ID prodotto': row.productId,
            'ID riga prodotto': row.productReservationId,
            Telefono: row.phone,
            Status: 'In attesa di SMS di scuse',
          }
        })
      }

      if (data && data.previewSmsJob && data.previewSmsJob.WAITING_FOR_PRICE_NOTIFICATION) {
        const toBeSentData = data.previewSmsJob.WAITING_FOR_PRICE_NOTIFICATION.map((row: any) => {
          salesPointsDupedIds.push(row.salesPointId)
          return {
            salesPointId: row.salesPointId,
            'ID prenotazione': row.reservationId,
            // 'EAN prodotto': row.ean,
            'ID prodotto': row.productId,
            'ID riga prodotto': row.productReservationId,
            Telefono: row.phone,
            Status: 'In attesa di invio SMS',
          }
        })
        const merged = finalData.concat(toBeSentData)
        finalData = [...merged]
      }

      const salesPointsSet = new Set(salesPointsDupedIds)

      const { data: salesPointsData } = await dataProvider.getMany('SalesPoint', { ids: Array.from(salesPointsSet) })
      console.log('salesPointsData: ', salesPointsData)

      const salesPointsMap: Record<string, any> = {}

      salesPointsData.map((salesPoint: any) => (salesPointsMap[salesPoint.id] = { ...salesPoint }))
      console.log('salesPointsMap: ', salesPointsMap)

      const realFinalData = finalData.map((row: any) => ({
        ...row,
        'Punto Vendita': salesPointsMap[row.salesPointId].name,
      }))

      realFinalData.forEach((element: any) => delete element.salesPointId)

      jsonExport(
        realFinalData,
        {
          rowDelimiter: ';',
        },
        (err: any, csv: any) => {
          if (err) throw new Error(err)
          downloadCSV(csv, 'lista_sms_da_inviare')
        }
      )
    } catch (error) {
      console.log('error::: ', error)
      notify((error as any).message, 'error')
    } finally {
      setSmsExportLoading(false)
    }
  }, [])

  const handleDialogClose = useCallback(() => {
    if (!exportLoading) setIsDialogOpen(false)
  }, [exportLoading])

  const handleScan = useCallback(async (data: string) => {
    if (data) {
      if (data.startsWith('r') || data.startsWith('R')) {
        try {
          const result = await dataProvider.getOne('Reservation', {
            id: data.substring(1),
          })
          if (result?.data?.id) {
            redirect(`/Reservation/${result.data.id}/show`)
          }
        } catch (error) {
          console.error('Error fetching reservation', error)
        }
      }
    }
  }, [])

  const handleScanError = (error: any): void => console.log('scan error: ', error)

  return (
    <>
      <BarcodeReader onScan={handleScan} onError={handleScanError} />
      {/* <Button onClick={(): Promise<void> => handleScan('r22')}>Scan</Button> */}
      <List
        {...props}
        // exporter={exporter}
        sort={{ field: 'createdAt', order: 'DESC' }}
        titleIcon={<FaFileAlt />}
        actions={
          <div>
            <CustomCreateButton basePath={props.basePath} />
            {loaded && hasPermission(permissions) && (
              <>
                <ExportButton {...props} className={classes.export} loading={exportLoading} onClick={exporter} />
                <ExportButton
                  {...props}
                  className={classes.export}
                  loading={smsExportLoading}
                  onClick={smsExporter}
                  icon={<SystemUpdateIcon />}
                />
              </>
            )}
          </div>
        }
        filters={<ReservationFilters variant="outlined" />}
      >
        <Datagrid rowClick="show">
          <TextField source="id" />
          <ReferenceField source="shortCollectionId" reference="ShortCollection" link={false} sortable={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="salesPointId" reference="SalesPoint" link={false} sortable={false}>
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            label="Cognome e Nome Cliente"
            render={(record: any): React.ReactNode => `${record.clientLastName} ${record.clientFirstName}`}
            sortable={false}
          />
          <FunctionField
            source="status"
            sortable={false}
            render={(record: any): React.ReactNode => (
              <StatusInfoLabel
                label={
                  !record?.status?.delivered || record?.status?.delivered <= 0
                    ? 'Non Consegnato'
                    : record?.status?.delivered === record?.status?.total
                    ? 'Consegnato'
                    : `${record?.status?.delivered}/${record?.status?.total} Consegnati`
                }
                color={
                  !record?.status?.delivered || record?.status?.delivered <= 0
                    ? StatusInfoLabelDefaultColors.INACTIVE
                    : record?.status?.delivered === record?.status?.total
                    ? StatusInfoLabelDefaultColors.SUCCESS
                    : StatusInfoLabelDefaultColors.PROGRESS
                }
              />
            )}
          />
          <DateField showTime source="createdAt" />
          <GenericMoreMenu />
        </Datagrid>
      </List>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant="h2">
            {`Generazione CSV in corso ${totalRows > 0 ? `(${totalRows} righe)` : ''}`}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Esportazione in corso, non chiudere o ricaricare la finestra del browser
          </DialogContentText>
        </DialogContent>
        <LinearProgress variant="determinate" value={progress} />
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme: any) => ({
  export: {
    marginLeft: theme.spacing(3),
  },
  dialogContent: {
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  dialogTitle: {
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}))

export default ReservationList
