import { Container, Avatar, Typography } from '@material-ui/core'
import React, { Children, cloneElement, FC, isValidElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { Route, Switch, useHistory } from 'react-router-dom'
import FormRightControls from '../FormRightControls'
import WizardRightControls from './WizardRightControls'
import Title from '../../layout/Title'

type Props = {
  title?: string
  basePath?: string
  wizardBasePath?: string
  currentStep: number
  hasNextStep: boolean
  hasPreviousStep: boolean
  record: Record<string, any>
  resource?: string
  children: React.ReactElement
  formRightControls?: React.ReactNode
  variant?: 'outlined' | 'standard' | 'filled'
  childrenCount?: number
  wizardChildren?: React.ReactNode
  goToStep?: (step: number, historyReplace?: boolean) => void
  titleBar?: React.ReactNode
  [x: string]: any
}

const WizardFormLayout: FC<Props> = ({
  title,
  wizardBasePath,
  basePath,
  currentStep,
  hasNextStep,
  hasPreviousStep,
  previousStepTo,
  children,
  record,
  resource,
  handleSubmit,
  handleSubmitWithRedirect,
  saveButtonComponent,
  asideUpperContent,
  wizardChildren,
  childrenCount = 0,
  variant,
  formRightControls = <WizardRightControls />,
  titleBar,
  nextStepTo,
  goToStep = (): void => {
    throw new Error('Method not implemented')
  },
}) => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <Container classes={{ root: classes.root }} maxWidth={false}>
      <div className={classes.fullWrapper}>
        <div className={classes.mainWrapper}>
          <Typography variant="body1">Step {currentStep}</Typography>
          <div className={classes.titleBarWrapper}>
            <Title title={title} icon={<HiArrowNarrowLeft />} avatarButton avatarOnClick={history.goBack} />
            {titleBar && isValidElement(titleBar) && (
              <div className={classes.titleBarContent}>
                {cloneElement(titleBar, {
                  resource,
                  record,
                  basePath,
                  wizardBasePath,
                  currentStep,
                  hasNextStep,
                  hasPreviousStep,
                  nextStepTo,
                  previousStepTo,
                  goToStep,
                })}
              </div>
            )}
          </div>
          {children}
        </div>

        <Switch>
          {Children.map(wizardChildren, (wizardStep, index) => {
            if (wizardStep && isValidElement(wizardStep)) {
              const hasNextStep = index < childrenCount - 1
              const hasPreviousStep = index > 0
              return (
                <Route
                  path={`${wizardBasePath}/${index + 1}`}
                  children={React.cloneElement(wizardStep.props.formRightControls || formRightControls, {
                    resource,
                    record,
                    basePath,
                    wizardBasePath,
                    variant: wizardStep.props.variant || variant,
                    currentStep: index + 1,
                    hasNextStep,
                    hasPreviousStep,
                    nextStepTo: hasNextStep ? `${wizardBasePath}/${index + 2}` : undefined,
                    previousStepTo: hasPreviousStep ? `${wizardBasePath}/${index}` : undefined,
                    goToStep,
                  })}
                />
              )
            }
          })}
        </Switch>

        {/* {isValidElement(formRightControls) &&
          cloneElement(
            formRightControls,
            {
              basePath: basePath,
              record: record,
              resource: resource,
              handleSubmit: handleSubmit,
              handleSubmitWithRedirect: handleSubmitWithRedirect,
              saveButtonComponent: saveButtonComponent,
              asideUpperContent: asideUpperContent,
            } as any,
            null
          )} */}
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  deleteButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0),
  },
  root: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  iconTitleWrapper: {
    backgroundColor: 'white',
    color: '#b9a7a6',
    width: '45px',
    height: '45px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleBarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(6),
  },
  titleBarContent: {
    // marginLeft: theme.spacing(13),
    marginTop: theme.spacing(1),
  },
  fullWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  mainWrapper: {
    // width: '85%',
    height: '100%',
    minWidth: 'calc(100% - 275px)',
    width: 'calc(100% - 275px)',
    maxWidth: 'calc(100% - 275px)',
  },
}))

export default WizardFormLayout
