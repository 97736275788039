import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { useGetManyReference } from 'ra-core'
import LinearProgress from '@material-ui/core/LinearProgress'

type Props = {
  record?: any
  [prop: string]: any
}

const OperatorProductStockField: FC<Props> = ({ record }) => {
  const { loading, data, ids } = useGetManyReference(
    'ProductStock',
    'productId',
    record.id,
    {
      page: 1,
      perPage: 1,
    },
    {
      field: 'id',
      order: 'ASC',
    },
    {},
    'OperatorProductStock'
  )

  if (loading) {
    return <LinearProgress />
  }

  if (!data || !ids || ids.length === 0) {
    return (
      <Typography style={{ fontWeight: 'bold' }} variant="body2">
        0 pezzi
      </Typography>
    )
  }

  if (!(data as any)[ids[0]]) {
    return (
      <Typography style={{ fontWeight: 'bold' }} variant="body2">
        0 pezzi
      </Typography>
    )
  }

  return (
    <Typography style={{ fontWeight: 'bold' }} variant="body2">{`${(data as any)[ids[0]].stock} pezzi`}</Typography>
  )
}

export default OperatorProductStockField
