import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import { TextInput, AutocompleteInput, ReferenceInput } from 'react-admin'
import ShortCollectionSelectInput from '../../components/ShortCollectionSelectInput'
import { LinearProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { required, usePermissions } from 'ra-core'
import { hasPermission } from '../../utils/hasPermission'

type Props = {
  record?: any
}

const autocompleteQuery = (name: string): Record<string, any> => ({ name })
const sort = { field: 'name', order: 'ASC' }

const useStyles = makeStyles((theme) => ({
  root: {},
  autocompleteContainer: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  shortCollectionInput: {
    marginRight: theme.spacing(6),
  },
}))

const ReservationFormSection: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { loaded, permissions } = usePermissions()

  return (
    <Box display="flex" flex="1" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="center">
        <ShortCollectionSelectInput
          {...props}
          className={clsx(classes.input, classes.shortCollectionInput)}
          label="Seleziona short collection"
          source="shortCollectionId"
          validate={required()}
          disabled={props && props.record && props.record.id}
        />
        {loaded ? (
          hasPermission(permissions) && (
            <ReferenceInput
              {...props}
              label="Seleziona punto vendita"
              source="salesPointId"
              reference="SalesPoint"
              validate={required()}
              filterToQuery={autocompleteQuery}
              className={classes.input}
              sort={sort}
              fullWidth
              classes={{ container: classes.autocompleteContainer }}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          )
        ) : (
          <LinearProgress />
        )}
      </Box>
      <TextInput {...props} className={classes.input} multiline source="note" />
    </Box>
  )
}

export default ReservationFormSection
