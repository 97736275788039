import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

type RequireAtLeastOne<T> = { [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>> }[keyof T]

type Props = {
  label?: string
  record?: Record<string, any>
  contributionSource: string
} & RequireAtLeastOne<{
  stampsQuantitySource?: string
  pointsQuantitySource?: string
}>

/**
 * @description Custom field to show 'Meccanica EAN-13' in a chip-like field
 * @param label - mandatory, otherwise no header will be shown inside datagrid
 * @param record
 */
export const ContributionMechanicField: FC<Props> = ({
  record = {},
  stampsQuantitySource,
  pointsQuantitySource,
  contributionSource,
}) => {
  const classes = useStyles()

  if (record[contributionSource] === undefined)
    return (
      <Typography variant="body2" color="textSecondary">
        N.D.
      </Typography>
    )
  else
    return (
      <span className={classes.wrapper}>
        {(!!stampsQuantitySource || !!pointsQuantitySource) && (
          <Typography variant="h6" className={classes.textStickers}>
            {stampsQuantitySource && `${record[stampsQuantitySource] || 0} bol`}
            {stampsQuantitySource && pointsQuantitySource && ' + '}
            {pointsQuantitySource && `${record[pointsQuantitySource] || 0} pt`}
          </Typography>
        )}
        <Typography variant="h6" className={classes.textCash}>
          {`${(record[contributionSource] || 0).toFixed(2).replace('.', ',')} €`}
        </Typography>
      </span>
    )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // border: '1px solid #c6cbd4',
    // borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    // padding: theme.spacing(3),
    minWidth: 60,
    // maxWidth: 100,
  },
  textStickers: {
    color: '#481a1a',
    fontWeight: 'bold',
  },
  textCash: {
    color: '#ed1d23',
    fontWeight: 'bold',
  },
}))
