/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, Children, isValidElement } from 'react'
import { FormWithRedirect, DeleteButton } from 'react-admin'
import AppBarFormToolbar from './AppBarFormToolbar'
import { makeStyles, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { Route, useHistory } from 'react-router-dom'
import FormRightControls from './FormRightControls'
import Title from '../layout/Title'

type Props = {
  children?: React.ReactNode
  initialValues?: any
  resource?: string
  basePath?: string
  record?: any
  redirect?: string | boolean | (() => string)
  save?: any
  saving?: any | boolean
  submitOnEnter?: boolean
  undoable?: boolean
  validate?: () => void
  version?: string
  variant?: 'outlined' | 'standard' | 'filled'
  handleSubmitWithRedirect?: any
  handleSubmit?: any
  invalid?: boolean
  pristine?: boolean
  toolbar?: React.ReactElement
  defaultTitle?: string
  title?: string
  asideUpperContent?: React.ReactNode
  asideLowerContent?: React.ReactNode
  saveButtonComponent?: React.ReactNode
}

const SectionedForm: FC<Props> = ({ variant = 'outlined', toolbar = <AppBarFormToolbar />, ...props }) => {
  return (
    <FormWithRedirect
      {...props}
      variant={variant}
      toolbar={toolbar}
      render={(formProps: any): React.ReactNode => <SectionedFormView {...formProps} />}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  deleteButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0),
  },
  root: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
  },
  iconTitleWrapper: {
    backgroundColor: 'white',
    color: '#b9a7a6',
    width: '45px',
    height: '45px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(6),
  },
  fullWrapper: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
  },
  mainWrapper: {
    minWidth: 'calc(100% - 275px)',
    width: 'calc(100% - 275px)',
    maxWidth: 'calc(100% - 275px)',
    // flexGrow: 1,
  },
}))

export const SectionedFormView: FC<Props> = ({
  children,
  resource,
  record,
  basePath,
  variant,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  saving,
  submitOnEnter,
  undoable,
  toolbar,
  redirect: defaultRedirect,
  defaultTitle,
  title,
  asideUpperContent,
  asideLowerContent,
  saveButtonComponent,
  ...rest
}) => {
  const classes = useStyles(rest)
  const history = useHistory()

  return (
    <Container className={classes.root} maxWidth={false}>
      <form {...sanitizeRestProps(rest)}>
        <div className={classes.fullWrapper}>
          <div className={classes.mainWrapper}>
            <Title
              title={title}
              defaultTitle={defaultTitle}
              icon={<HiArrowNarrowLeft />}
              avatarButton
              avatarOnClick={history.goBack}
            />
            {Children.map(children, (section) => {
              if (section && isValidElement(section)) {
                return React.cloneElement(section, {
                  resource,
                  record,
                  basePath,
                  variant: section.props.variant || variant,
                })
              }
            })}
          </div>
          <FormRightControls
            basePath={basePath}
            record={record}
            resource={resource}
            undoable={undoable}
            handleSubmit={handleSubmit}
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            saveButtonComponent={saveButtonComponent}
            asideUpperContent={asideUpperContent}
            asideLowerContent={asideLowerContent}
          />
        </div>

        {/* {toolbar &&
          React.cloneElement(toolbar, {
            basePath,
            className: 'toolbar',
            handleSubmitWithRedirect,
            handleSubmit,
            invalid,
            pristine,
            record,
            redirect: defaultRedirect,
            resource,
            saving,
            submitOnEnter,
            undoable,
          })} */}
        {/* {record && typeof record.id !== 'undefined' && (
          <div className={classes.deleteButtonContainer}>
            <DeleteButton basePath={basePath} record={record} resource={resource} undoable={undoable} />
          </div>
        )} */}
      </form>
    </Container>
  )
}

const sanitizeRestProps = ({
  anyTouched,
  array,
  asyncBlurFields,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  dispatch,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  initialize,
  initialized,
  initialValues,
  pristine,
  pure,
  redirect,
  reset,
  resetSection,
  save,
  staticContext,
  submit,
  submitAsSideEffect,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  translate,
  triggerSubmit,
  undoable,
  untouch,
  valid,
  validate,
  validating,
  _reduxForm,
  setRedirect,
  ...props
}: any): Props => props

export default SectionedForm
