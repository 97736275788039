import React, { FC, useState } from 'react'
import { chakra } from '@chakra-ui/system'
import { Alert, AlertIcon, Button, Checkbox, Input, Stack, Text } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useLogin } from 'ra-core'

type LoginData = {
  username: string
  password: string
  rememberMe?: boolean
}

type Props = {
  title?: string
  caption?: string
}
export const LoginForm: FC<Props> = ({ title = 'Login', caption = 'Inserisci i tuoi dati e accedi' }) => {
  const { register, handleSubmit, control } = useForm<LoginData>()
  const [error, setError] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const login = useLogin()

  const onSubmit = handleSubmit(async (credentials) => {
    try {
      setSubmitting(true)
      setError(false)
      await login(credentials)
      // history.replace('/')
    } catch (e) {
      console.error(e)
      setError(true)
    } finally {
      setSubmitting(false)
    }
  })

  return (
    <chakra.form
      w={{ base: '100%', lg: undefined }}
      onSubmit={onSubmit}
      display="flex"
      flexDir={{ base: 'column', lg: 'row' }}
    >
      <chakra.div
        minW={{ base: undefined, lg: '474px' }}
        w={{ base: '100%', lg: '474px' }}
        minH={{ lg: '394px' }}
        p={{ base: 8, lg: '60px' }}
        pb={{ base: '80px', lg: undefined }}
        borderRadius="4px"
        bgColor="white"
      >
        <Text fontWeight="800" fontSize="4xl">
          {title}
        </Text>
        <Text color="gray.300">{caption}</Text>
        <Stack mt={5} spacing={4}>
          <Input
            {...register('username', { required: true })}
            placeholder="Indirizzo email..."
            autoCapitalize="off"
            size="lg"
            _placeholder={{ color: 'rbga(0,0,0,0.5)' }}
            borderRadius="4px"
          />
          <Input
            {...register('password', { required: true })}
            type="password"
            placeholder="Password"
            size="lg"
            _placeholder={{ color: 'rbga(0,0,0,0.5)' }}
            borderRadius="4px"
          />

          <Controller
            name="rememberMe"
            control={control}
            defaultValue={true}
            render={({ field }): any => (
              <Checkbox
                pt={2}
                colorScheme="red"
                isChecked={field.value}
                ref={field.ref}
                onBlur={field.onBlur}
                name={field.name}
                onChange={field.onChange}
              >
                Ricordami la prossima volta
              </Checkbox>
            )}
          />
        </Stack>
        {error && (
          <Alert mt={4} status="error" variant="solid">
            <AlertIcon />
            Username o password errati
          </Alert>
        )}
      </chakra.div>

      <Button
        type="submit"
        colorScheme="red"
        alignSelf={{ base: undefined, lg: 'flex-end' }}
        ml={{ base: 0, lg: 8 }}
        mt={{ base: 5, lg: undefined }}
        size="lg"
        fontSize="22px"
        py="26px"
        minW="207px"
        h="80px"
        disabled={submitting}
        isLoading={submitting}
      >
        Entra
      </Button>
    </chakra.form>
  )
}
