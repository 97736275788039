import React, { FC } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField, Filter } from 'react-admin'

import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import { FaStore } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import BooleanStatusField from '../../components/BooleanStatusField'

type Props = {
  //
}

const RoleFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca" source="name" alwaysOn />
    </Filter>
  )
}

const RoleList: FC<Props> = (props) => {
  return (
    <RAList
      {...props}
      title="Ruoli"
      sort={{ field: 'name', order: 'ASC' }}
      titleIcon={<FaStore />}
      actions={<CreateButton label="Nuovo Ruolo" />}
      filters={<RoleFilters variant="outlined" />}
    >
      <Datagrid rowClick="show">
        {/* <TextField source="id" sortable={false} /> */}
        <TextField source="name" />
        <BooleanStatusField
          label="Ruolo Pubblico"
          source="isPublic"
          falseValue="Pubblico"
          trueValue="Privato"
          trueColor="grey"
          falseColor="success"
        />
        <BooleanStatusField
          label="Ruolo Bloccato"
          source="isLocked"
          falseValue="Aperto"
          trueValue="Bloccato"
          trueColor="warning"
          falseColor="success"
        />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default RoleList
