import React, { FC, useCallback, useMemo, useState } from 'react'
import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

type Props = {
  onRemove: () => void
  disableRemove?: boolean
  className?: string
}
export const RemoveRulesBoxMenu: FC<Props> = ({ onRemove, disableRemove, className }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleRemove = useCallback(() => {
    onRemove()
    handleClose()
  }, [onRemove, handleClose])

  return (
    <>
      <IconButton
        className={className}
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   style: {
        //     maxHeight: ITEM_HEIGHT * 4.5,
        //     width: '20ch',
        //   },
        // }}
      >
        <MenuItem disabled={disableRemove} onClick={handleRemove}>
          Rimuovi blocco di regole
        </MenuItem>
      </Menu>
    </>
  )
}
