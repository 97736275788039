import React, { FC } from 'react'
import Show from '../../components/details/Show'
import { Box, makeStyles } from '@material-ui/core'
import { FaStore } from 'react-icons/fa'
import { ReferenceManyField, TextField } from 'react-admin'
import Datagrid from '../../components/Datagrid'
import { Pagination } from '../../components/list/pagination'
import Empty from '../../components/list/Empty'
import { InfoCard, InfoCardItem, InfoCardWrapper, InfoCardTypography, InfoCardAvatar } from '../../components/InfoCard'
import { ReferenceField } from 'ra-ui-materialui'

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailValue: {
    fontWeight: 'bold',
  },
  contactButton: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: '#eeeeee',
  },
  card: {
    flexBasis: '50%',
    maxWidth: 375,
    position: 'sticky',
    top: theme.spacing(3),
  },
  userLogsContainer: {
    flex: 1,
    marginLeft: theme.spacing(3),
    // overflow: 'scroll',
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
  },
  cardValue: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  bold: {
    ...theme.typography.body1,
    fontWeight: 'bold',
  },
}))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SalesPointShowCard = (props: any): any => {
  const classes = useStyles()
  console.log('CIAO ', props)

  return props.record ? (
    <InfoCardWrapper>
      <InfoCard>
        <InfoCardItem direction="row">
          <InfoCardAvatar>
            <FaStore size={20} />
          </InfoCardAvatar>
          <InfoCardTypography variant="title">{props.record.name}</InfoCardTypography>
        </InfoCardItem>

        <InfoCardItem>
          <InfoCardTypography variant="label">Area:</InfoCardTypography>
          <InfoCardTypography variant="value">
            <ReferenceField {...props} label="Nome" source="coopAreaId" reference="CoopArea" link={false}>
              <TextField className={classes.bold} source="name" />
            </ReferenceField>
          </InfoCardTypography>
        </InfoCardItem>

        <InfoCardItem>
          <InfoCardTypography variant="label">Zona:</InfoCardTypography>
          <InfoCardTypography variant="value">
            <ReferenceField {...props} label="Nome" source="coopZoneId" reference="CoopZone" link={false}>
              <TextField className={classes.bold} source="name" />
            </ReferenceField>
          </InfoCardTypography>
        </InfoCardItem>
      </InfoCard>
    </InfoCardWrapper>
  ) : null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SalesPointUsersTable: FC<any> = (props) => {
  const classes = useStyles()

  return props.record ? (
    <Box className={classes.userLogsContainer}>
      <ReferenceManyField
        pagination={<Pagination />}
        record={props.record}
        reference="User"
        target="salesPointId"
        basePath="/User"
      >
        <Datagrid emptyComponent={<Empty buttonComponent={<></>} />} rowClick="show">
          <TextField source="id" label="ID" />
          <TextField source="fullName" />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  ) : (
    <p></p>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Wrapper: FC<any> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.contentContainer}>
      <SalesPointShowCard {...props} />
      <SalesPointUsersTable {...props} />
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SalesPointShow: FC<any> = (props) => {
  return (
    <Show {...props}>
      <Wrapper {...props} />
    </Show>
  )
}

export default SalesPointShow
