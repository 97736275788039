import React, { FC, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import InputBase from '@material-ui/core/InputBase'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'
import Input from '@material-ui/core/Input'
import BaseButton from './button/BaseButton'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { InputProps, useInput } from 'ra-core'

const QUANTITY_LIMIT = 100000

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void
}

const quantityMask = createNumberMask({
  prefix: '',
  suffix: '',
  // allowDecimal: false,
  // decimalLimit: 0,
  // integerLimit: 6,
  // requireDecimal: false,
  // allowNegative: false,
  // allowLeadingZeroes: false,
  // decimalSymbol: ',',
  // thousandsSeparatorSymbol: ',',
})

const TextMaskCustom: FC<TextMaskCustomProps> = (props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any): void => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={quantityMask}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      keepCharPositions={true}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    // flex: 1,
    width: '100px',
    paddingLeft: theme.spacing(3),
    flex: 1,
    overflow: 'hidden',
  },
  wrapper: {
    border: '1px solid',
    borderColor: theme.palette.secondary.light,
    borderRadius: theme.shape.borderRadius,
    minWidth: '120px',
    overflow: 'hidden',
  },
  stepper: {
    '& $stepperButton:not(:last-child)': {
      borderBottomWidth: '1px',
      borderStyle: 'solid',
      borderBottomColor: theme.palette.secondary.light,
    },
    '& $stepperButton:last-child:disabled': {
      borderRight: 'none !important',
      borderTop: 'none !important',
      borderBottom: 'none !important',
    },
    '& $stepperButton': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  stepperButton: {
    borderRadius: '0px',
    borderRight: '0px solid !important',
    borderLeft: '1px solid',
    borderColor: theme.palette.secondary.light,
  },
}))

type Props = InputProps<any>

const QuantityChooserInput: FC<Props> = (props) => {
  const {
    input: { name, onChange, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const classes = useStyles()
  const handleAddQuantity = useCallback(() => {
    if (!value) {
      onChange('0')
    }

    if (parseInt(value) < QUANTITY_LIMIT) {
      onChange(parseInt(value) + 1)
    }
  }, [value, onChange])

  const handleRemoveQuantity = useCallback(() => {
    if (parseInt(value) > 0) {
      onChange(parseInt(value) - 1)
    }
  }, [value, onChange])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // console.log(event.target.value)
      // let newValue
      // console.log('sono qua', event.target.value)
      // try {
      //   newValue = parseInt(event.target.value)
      // } catch (e) {
      //   console.log('sono qua 2', event.target.value)
      //   newValue = 0
      //   // return
      // }
      // if (newValue >= 0 && newValue < QUANTITY_LIMIT) {
      //   onChange(newValue)
      // }
      onChange(event.target.value)
    },
    [onChange]
  )

  return (
    <Box display="flex" className={classes.wrapper}>
      <InputBase className={classes.input} value={value} onChange={handleChange} />
      {/* {value} */}
      <Box display="flex" flexDirection="column" className={classes.stepper}>
        <BaseButton
          className={classes.stepperButton}
          size="xs"
          onClick={handleAddQuantity}
          icon={<FaPlus />}
          variant="outlined"
        />
        <BaseButton
          className={classes.stepperButton}
          size="xs"
          onClick={handleRemoveQuantity}
          disabled={value <= 0}
          icon={<FaMinus />}
          variant="outlined"
        />
      </Box>
    </Box>
  )
}

export default QuantityChooserInput
