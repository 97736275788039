import React, { FC, useMemo } from 'react'
import List from '../../components/list/List'
import { TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import FilterTextInput from '../../components/FilterTextInput'
import Datagrid from '../../components/Datagrid'
import Filter from 'ra-ui-materialui/lib/list/filter/Filter'
import { BsCircleFill } from 'react-icons/bs'
import GenericMoreMenu from '../../components/GenericMoreMenu'
import { ReferenceField } from 'ra-ui-materialui'

const useImageFieldStyles = makeStyles({
  image: {
    height: 80,
    margin: 0,
  },
})

type Props = {
  //
}

const CoopZoneFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca" source="name" alwaysOn />
    </Filter>
  )
}

const CoopZoneList: FC = (props) => {
  return (
    <List
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      filters={<CoopZoneFilters variant="outlined" />}
      titleIcon={<BsCircleFill />}
      title="Zone"
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField label="Area" source="coopAreaId" reference="CoopArea" linkType={false}>
          <TextField source="name" />
        </ReferenceField>
        <GenericMoreMenu />
      </Datagrid>
    </List>
  )
}

export default CoopZoneList
