import React, { FC } from 'react'
import Create from '../../components/details/Create'
import FormSection from '../../components/forms/FormSection'
import WizardSectionedForm, { WizardStep } from '../../components/forms/WizardSectionedForm'
import { ReferenceInput } from 'react-admin'
import FormAutoCompleteInput from '../../components/FormAutoCompleteInput'
import { required, usePermissions } from 'ra-core'
import ProductsStockTable from './ProductsStockTable'
import WizardRightControls from '../../components/forms/WizardSectionedForm/WizardRightControls'
import ShortCollectionFormSelectInput from '../../components/ShortCollectionFormSelectInput'
import { hasPermission } from '../../utils/hasPermission'

const autocompleteQuery = (name: string): Record<string, any> => ({ name })
const sort = { field: 'name', order: 'ASC' }

type Props = {
  [x: string]: any
}

const TitleBar: FC = () => {
  return <div id="incoming-stock-title-bar" />
}

const SendIncomingStockButon: FC = () => {
  return <div id="send-new-incoming-stock" />
}

const ProductStockCreate: FC<Props> = (props) => {
  const { loaded, permissions } = usePermissions()
  return (
    <Create {...props} component="div">
      <WizardSectionedForm variant="outlined" redirect="show" title="Nuova Consegna" titleBar={<TitleBar />}>
        <WizardStep>
          <FormSection title="Short Collection">
            <ShortCollectionFormSelectInput
              label="Seleziona short collection"
              source="shortCollectionId"
              fullWidth
              helperText="Seleziona la short collection da cui mostrarte i prodotti"
              activeOnly
            />
          </FormSection>

          {loaded && hasPermission(permissions) && (
            <FormSection title="Punto Vendita">
              <ReferenceInput
                label="Seleziona punto vendita"
                source="salesPointId"
                reference="SalesPoint"
                validate={required()}
                filterToQuery={autocompleteQuery}
                sort={sort}
              >
                <FormAutoCompleteInput optionText="name" helperText="Seleziona punto vendita di appartenenza" />
              </ReferenceInput>
            </FormSection>
          )}
        </WizardStep>

        <WizardStep formRightControls={<WizardRightControls saveButtonComponent={<SendIncomingStockButon />} />}>
          <ProductsStockTable />
        </WizardStep>
      </WizardSectionedForm>
    </Create>
  )
}

export default ProductStockCreate
