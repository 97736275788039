import React, { FC, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SplitPane from 'react-split-pane'
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { Button, CircularProgress } from '@material-ui/core'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded'
import { useNotify } from 'ra-core'
import { useApolloClient } from '@apollo/client'
import { QUERY_GET_SERVICE_LOGS } from '../../queries'
import { linter } from '@codemirror/lint'
import { useDebouncyEffect } from 'use-debouncy'
import { ResultPreview } from './ResultPreview'

const linterExtension = linter(jsonParseLinter())

type Props = {
  //
}

const isValidJson = (input: string): boolean => {
  let isValid = true
  try {
    JSON.parse(input)
  } catch (error) {
    isValid = false
  }

  return isValid
}

const safeJsonParse = (input: string): any => {
  try {
    return JSON.parse(input)
  } catch (e) {
    return {}
  }
}

export const LogsConsole: FC<Props> = () => {
  const classes = useStyles()
  const [code, setCode] = useState(localStorage.getItem('logs-console-code-default') || '{}')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<any>(undefined)
  const [result, setResult] = useState<any>(undefined)
  const notify = useNotify()
  const client = useApolloClient()
  const refs = React.useRef<ReactCodeMirrorRef>({})

  const handleCodeChange = useCallback((value: string) => {
    setCode(value)
  }, [])

  const handleSendRequest = useCallback(async () => {
    setLoading(true)
    setErrors(undefined)

    try {
      if (!isValidJson(code)) {
        throw new Error('Invalid JSON')
      }

      const { data, errors } = await client.query({
        query: QUERY_GET_SERVICE_LOGS,
        fetchPolicy: 'no-cache',
        variables: {
          filters: {
            ...(safeJsonParse(code) || {}),
          },
          sort: {
            createdAt: 'DESC',
          },
          pagination: {
            disabled: true,
          },
        },
      })

      if (!data?.serviceLogs && errors) {
        setErrors(data.errors)
        setResult(undefined)
      } else if (data?.serviceLogs) {
        setResult(data.serviceLogs)
      } else if (!data?.serviceLogs && !errors) {
        throw new Error('Unexpected error: no data')
      }
    } catch (error) {
      console.error('error running request', error)
      notify((error as any)?.message || 'error running request', 'error')
      setResult(undefined)
    } finally {
      setLoading(false)
    }
  }, [code, setResult, setLoading])

  useDebouncyEffect(
    () => {
      localStorage.setItem('logs-console-code-default', code)
    },
    400,
    [code]
  )

  return (
    <div className={classes.root}>
      <SplitPane
        pane2Style={{
          width: '100%',
          overflow: 'hidden',
        }}
        className={classes.panesContainer}
        split="vertical"
        defaultSize="50%"
        minSize={50}
        maxSize={-20}
      >
        <div className={classes.leftPanel}>
          <div className={classes.leftPanelToolbar}>
            <Button
              onClick={handleSendRequest}
              disabled={loading}
              size="small"
              variant="outlined"
              className={classes.sendButton}
              endIcon={!loading ? <PlayArrowRoundedIcon /> : <CircularProgress size={16} />}
            >
              Send
            </Button>
          </div>
          <CodeMirror
            ref={refs}
            onChange={handleCodeChange}
            value={code}
            height="calc(100vh - 40px)"
            extensions={[json(), linterExtension /*usersPlugin*/]}
          />
        </div>
        <ResultPreview result={errors || result} />
      </SplitPane>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 40px)',
    overflow: 'hidden',
  },
  panesContainer: {
    '& .Resizer': {
      background: '#000',
      opacity: 0.2,
      zIndex: 1,
      MozBoxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
      boxSizing: 'border-box',
      MozBackgroundClip: 'padding',
      WebkitBackgroundClip: 'padding',
      backgroundClip: 'padding-box',
      '& :hover': {
        WebkitTransition: 'all 2s ease',
        transition: 'all 2s ease',
      },
    },
    '& .Resizer.vertical': {
      width: '11px',
      margin: '0 -5px',
      borderLeft: '5px solid rgba(255, 255, 255, 0)',
      borderRight: '5px solid rgba(255, 255, 255, 0)',
      cursor: 'col-resize',
      '& :hover': {
        borderTop: '5px solid rgba(0, 0, 0, 0.5)',
        borderBottom: '5px solid rgba(0, 0, 0, 0.5)',
      },
    },
  },
  leftPanel: {
    height: 'calc(100vh - 40px)',
  },
  leftPanelToolbar: {
    display: 'flex',
    padding: theme.spacing(1, 2, 1, 2),
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  sendButton: {},
}))
