import React, { FC, useEffect, useMemo, useState } from 'react'
import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import buildGraphQLProvider from 'ra-hyperting-graphql'
import { myBuildQuery } from './dataProvider'
import { ApolloProvider } from '@apollo/client'
import { client } from './apolloClient'
import authProvider from './authProvider'
import customRoutes from './customRoutes'
import createHistory from 'history/createBrowserHistory'
import englishMessages from './i18n/en'
import italianMessages from './i18n/it'
import Loading from './components/Loading'
import Layout from './components/layout/Layout'
import SalesPoint from './resources/SalesPoint'
import Product from './resources/Product'
import addUploadFeature from './addUploadFeature'
import theme from './theme'
import './App.css'
import Role from './resources/Role'
import User from './resources/User'
import ProductStock from './resources/ProductStock'
import ShortCollection from './resources/ShortCollection'
import logo from './logo.svg'
import Reservation from './resources/Reservation'
import Dashboard from './components/Dashboard'
import PageNotFound from './components/PageNotFound'
import { hasPermission } from './utils/hasPermission'
import OperatorProductStockList from './resources/ProductStock/OperatorProductStockList'
import CoopArea from './resources/CoopArea'
import CoopZone from './resources/CoopZone'
import DummyWarning from './components/DummyWarning'
import { JobsQueueInfoInitializer } from './components/jobs-queue/JobsQueueInfoInitializer'
import { Login } from './components/login/Login'
import { isIE } from './utils/isIE'
import ProductReservation from './resources/ProductReservation'

const history = createHistory()

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'it') {
    return italianMessages
  }

  // Always fallback on english
  return englishMessages
}, 'it')

const App: FC = () => {
  const [dataProvider, setDataProvider] = useState(null)

  const isInternetExplorer = useMemo(() => isIE(), [])

  useEffect(() => {
    const buildProvider = async (): Promise<any> => {
      const dP = await buildGraphQLProvider({
        client,
        buildQuery: myBuildQuery,
      })
      setDataProvider(() => dP)
    }

    buildProvider()
  }, [])

  if (!dataProvider) {
    return (
      <>
        <DummyWarning />
        <Loading theme={theme} logo={logo} />
      </>
    )
  }

  const uploadCapableDataProvider = addUploadFeature(dataProvider)

  if (isInternetExplorer) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <h1>
          <strong>ATTENZIONE!</strong> Internet Explorer non è supportato. Utilizza un browser moderno come Chrome, Edge
          o Firefox.
        </h1>
      </div>
    )
  }

  return (
    <>
      <DummyWarning />
      <ApolloProvider client={client}>
        <JobsQueueInfoInitializer />
        <Admin
          history={history}
          layout={Layout}
          dataProvider={uploadCapableDataProvider}
          i18nProvider={i18nProvider}
          authProvider={authProvider}
          theme={theme}
          loginPage={Login}
          customRoutes={customRoutes}
          catchAll={PageNotFound}
          dashboard={Dashboard}
        >
          {(permissions: any): any => {
            return [
              <Resource {...Reservation} />,
              <Resource {...ProductReservation} />,
              hasPermission(permissions) ? <Resource {...ProductStock} /> : <Resource name={ProductStock.name} />,
              !hasPermission(permissions) ? (
                <Resource name="OperatorProductStock" list={OperatorProductStockList} create={ProductStock.create} />
              ) : null,
              <Resource
                name="ShortCollection"
                list={ShortCollection.list}
                create={hasPermission(permissions) ? ShortCollection.create : undefined}
                edit={hasPermission(permissions) ? ShortCollection.edit : undefined}
                show={ShortCollection.show}
              />,
              <Resource
                name="SalesPoint"
                list={hasPermission(permissions) ? SalesPoint.list : undefined}
                create={hasPermission(permissions) ? SalesPoint.create : undefined}
                edit={hasPermission(permissions) ? SalesPoint.edit : undefined}
                show={hasPermission(permissions) ? SalesPoint.show : undefined}
              />,
              <Resource name="Product" list={hasPermission(permissions) ? Product.list : undefined} />,
              <Resource name="ServiceLog" />,
              <Resource name="CoopCustomer" />,
              <Resource name="WaitingListProduct" />,
              <Resource {...CoopArea} />,
              <Resource {...CoopZone} />,
              <Resource name="ShortCollectionRule" />,
              <Resource name="Picture" />,
              <Resource {...Document} />,
              <Resource {...User} />,
              <Resource
                name="Role"
                list={hasPermission(permissions) ? Role.list : undefined}
                create={hasPermission(permissions) ? Role.create : undefined}
                edit={hasPermission(permissions) ? Role.edit : undefined}
                show={hasPermission(permissions) ? Role.show : undefined}
              />,
            ]
          }}
        </Admin>
      </ApolloProvider>
    </>
  )
}

export default App
