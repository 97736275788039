import React, { FC } from 'react'
import SectionedForm from '../../components/forms/SectionedForm'
import Edit from '../../components/details/Edit'
import FormSection from '../../components/forms/FormSection'
import FormTextInput from '../../components/FormTextInput'
import SwitchCardInput from '../../components/SwitchCardInput'
import SalesPointAreaInput from '../../components/SalesPointAreaInput'
import { SalesPointZoneInput } from '../../components/SalesPointZoneInput'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

const SalesPointEdit: FC<Props> = (props) => {
  return (
    <Edit {...props} component="div">
      <SectionedForm
        variant="outlined"
        redirect="show"
        title="Nuovo Punto Vendita"
        asideUpperContent={
          <SwitchCardInput source="myBoolean" label="Vuoi notificare il punto vendita?" startingValue={true} />
        }
      >
        <FormSection title="Punto Vendita">
          <FormTextInput {...props} source="name" helperText="" />
          <FormTextInput {...props} source="sicma" helperText="" />
        </FormSection>
        <FormSection title="Area geografica di appartenenza">
          <SalesPointAreaInput disabled label={false} />
          <SalesPointZoneInput label={false} />
        </FormSection>
      </SectionedForm>
    </Edit>
  )
}

export default SalesPointEdit
