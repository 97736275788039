import gql from 'graphql-tag'

export const QUERY_GET_SHORT_COLLECTIONS = gql`
  query GetShortCollections($filters: ShortCollectionFilterInput) {
    shortCollections(filters: $filters) {
      data {
        id
        name
      }
    }
  }
`

export const QUERY_GET_PERIMETER_AWARE_SHORT_COLLECTIONS = gql`
  query GetPerimeterAwareShortCollections($filters: ShortCollectionFilterInput) {
    shortCollections: perimeterAwareShortCollections(filters: $filters, sort: { name: ASC }) {
      data {
        id
        name
      }
    }
  }
`

export const QUERY_GET_SHORT_COLLECTION = gql`
  query GetShortCollection($id: ID!) {
    shortCollection(id: $id) {
      id
      name
    }
  }
`

export const QUERY_GET_PRODUCT_STOCK_AVAILABILITY = gql`
  query GetProductStockAvailability($salesPointId: ID, $productId: ID) {
    productStocks(
      filters: { salesPointId: $salesPointId, productId: $productId }
      pagination: { limit: 1, offset: 0 }
    ) {
      data {
        id
        stock
      }
    }

    productReservationsCount(
      filters: {
        productId: $productId
        productReservationStatus: [CUSTOMER_NOTIFIED, WAITING_FOR_AVAILABLE_NOTIFICATION]
        salesPointId: $salesPointId
      }
    )
  }
`

export const QUERY_GET_RESERVATION_STOCK_AVAILABILITY = gql`
  query GetReservationStockAvailability($salesPointId: ID, $productId: ID) {
    productReservations(
      filters: { productId: $productId, productReservationStatus: CUSTOMER_NOTIFIED, salesPointId: $salesPointId }
    ) {
      total
      data {
        id
        productReservationStatus
        reservationId
      }
    }
  }
`

export const MUTATION_CREATE_PRODUCT = gql`
  mutation CreateProduct($data: ProductCreateInput!) {
    createProduct(data: $data) {
      id
    }
  }
`

export const MUTATION_UPDATE_PRODUCT_STOCK = gql`
  mutation UpdateProductStock($data: ProductStockUpdateInput!) {
    updateProductStock(data: $data) {
      id
    }
  }
`

export const QUERY_GET_PRODUCT_FOR_RESERVATION_TABLE = gql`
  query GetProductForReservationTable($id: ID!) {
    product(id: $id) {
      id
      name
      shortCollection {
        id
        name
      }
    }
  }
`

export const QUERY_GET_PRODUCTSRESERVATION_AVANCED = gql`
  query GetProductReservationAdvanced($filters: ProductReservationFilterInput, $pagination: PaginationInputType) {
    productReservations(filters: $filters, pagination: $pagination) {
      data {
        id
        productReservationStatus
        product {
          id
          name
          eanStamps
          numStampsStamps
          eanStampsContribution
          numStampsStampsContribution
          contributionStampsContribution
          eanPoints
          numPointsPoints
          eanPointsContribution
          numPointsPointsContribution
          contributionPointsContribution
          eanContribution
          contributionContribution
          eanStampsPointsContribution
          numPointsStampsPointsContribution
          numStampsStampsPointsContribution
          contributionStampsPointsContribution
        }
      }
      total
    }
  }
`

export const QUERY_GET_PRODUCTSRESERVATION_CSV = gql`
  query GetProductReservationCsv(
    $filters: ProductReservationFilterInput
    $pagination: PaginationInputType
    $sort: ProductReservationSortInput
  ) {
    productReservations(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        productReservationStatus
        updatedAt
        product {
          id
          name
        }
        reservation {
          id
          createdAt
          updatedAt
          note
          clientEan
          clientFirstName
          clientLastName
          clientEmail
          clientPhone
          shortCollection {
            id
            name
          }
          salesPoint {
            id
            name
            coopAreaId
            sicma
          }
        }
      }
      total
    }
  }
`

export const QUERY_GET_PRODUCTSRESERVATION_CSV_TOTAL = gql`
  query GetProductReservationCsvTotal(
    $filters: ProductReservationFilterInput
    $pagination: PaginationInputType
    $sort: ProductReservationSortInput
  ) {
    productReservations(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
      }
      total
    }
  }
`

export const QUERY_GET_USER_ME = gql`
  query GetUserMe {
    userMe {
      id
      firstName
      lastName
      roles {
        id
        name
        params
      }
    }
  }
`

export const QUERY_GET_TEAMMEMBER_ME = gql`
  query GetTeamMemberMe {
    teamMemberMe {
      id
      accountId
      firstName
      lastName
      roles
      salesPoint {
        id
        areaId
      }
    }
  }
`

export const QUERY_ALREADY_ASSIGNED_PRODUCTS_TOTAL = gql`
  query AlreadyAssignedProductsTotal($filters: ProductReservationCountFilterInput!) {
    productReservationsCount(filters: $filters)
  }
`

export const QUERY_GET_PRODUCTSTOCKGROUPED_CSV_TOTAL = gql`
  query GetProductStocksCsvTotal($filters: ProductStockFilterInput, $pagination: PaginationInputType) {
    productStocks(filters: $filters, pagination: $pagination) {
      total
    }
  }
`

export const QUERY_GET_PREVIEW_SMS_JOB = gql`
  query GetPreviewSmsJob {
    previewSmsJob {
      price_NOTIFICATION_CONTENT
      excuse_NOTIFICATION_CONTENT
      total_WAITING_FOR_PRICE_NOTIFICATION
      WAITING_FOR_PRICE_NOTIFICATION {
        productReservationId
        salesPointId
        reservationId
        phone
        shortCollectionId
        productId
      }
      total_WAITING_FOR_EXCUSE_NOTIFICATION
      WAITING_FOR_EXCUSE_NOTIFICATION {
        productReservationId
        salesPointId
        reservationId
        phone
        shortCollectionId
        productId
      }
    }
  }
`

export const QUERY_GET_ALL_COOP_AREAS = gql`
  query GetCoopAllAreas {
    coopAreas(pagination: { disabled: true }, sort: { name: ASC }) {
      data {
        id
        name
      }
    }
  }
`

export const QUERY_GET_COOP_ZONES = gql`
  query GetCoopZones($filters: CoopZoneFilterInput, $pagination: PaginationInputType, $sort: CoopZoneSortInput) {
    coopZones(filters: $filters, pagination: $pagination, sort: $sort) {
      total
      data {
        id
        name
        coopAreaId
      }
    }
  }
`

export const QUERY_GET_SALES_POINTS = gql`
  query GetSalesPoints($filters: SalesPointFilterInput, $pagination: PaginationInputType, $sort: SalesPointSortInput) {
    salesPoints(filters: $filters, pagination: $pagination, sort: $sort) {
      total
      data {
        id
        name
        sicma
        coopAreaId
        coopZoneId
      }
    }
  }
`

export const QUERY_GET_COLLECTION_RULES = gql`
  query GetShortCollectionRules(
    $filters: ShortCollectionRuleFilterInput
    $pagination: PaginationInputType
    $sort: ShortCollectionRuleSortInput
  ) {
    shortCollectionRules(filters: $filters, pagination: $pagination, sort: $sort) {
      total
      data {
        id
        stamps
        stampsContribution
        points
        pointsContribution
        contribution
        stampsPointsContribution
        shortCollectionId
        coopZones {
          id
          name
        }
        coopAreas {
          id
          name
        }
        salesPointIds
      }
    }
  }
`

export const QUERY_GET_SHORT_COLLECTION_PRODUCT_BY_PRODUCT_ID = gql`
  query GetShortCollectionProductByProductId($productId: ID!) {
    shortCollectionProduct: shortCollectionProductByProductId(productId: $productId) {
      id
      eanStamps
      eanStampsContribution
      eanPoints
      eanPointsContribution
      eanContribution
      numStampsStamps
      numStampsStampsContribution
      contributionStampsContribution
      numPointsPoints
      numPointsPointsContribution
      contributionPointsContribution
      contributionContribution
      eanStampsPointsContribution
      numPointsStampsPointsContribution
      numStampsStampsPointsContribution
      contributionStampsPointsContribution
      productId

      # old fields for retro-compatibility
      numStickers
      numPoints
      additionalAmount
      areaId
    }
  }
`

export const QUERY_GET_SHORT_COLLECTION_PRODUCTs_BY_PRODUCT_IDs = gql`
  query GetShortCollectionProductsByProductId($productIds: [ID!]!) {
    shortCollectionProducts: shortCollectionProductByProductIds(productIds: $productIds) {
      id
      eanStamps
      eanStampsContribution
      eanPoints
      eanPointsContribution
      eanContribution
      numStampsStamps
      numStampsStampsContribution
      contributionStampsContribution
      numPointsPoints
      numPointsPointsContribution
      contributionPointsContribution
      contributionContribution
      eanStampsPointsContribution
      numPointsStampsPointsContribution
      numStampsStampsPointsContribution
      contributionStampsPointsContribution
      productId

      # old fields for retro-compatibility
      numStickers
      numPoints
      additionalAmount
      areaId
    }
  }
`

export const QUERY_GET_SHORT_COLLECTION_RULES_BY_PERIMETER = gql`
  query GetShortCollectionRulesByPerimeter($shortCollectionId: ID!, $salesPointId: ID, $reservationId: ID) {
    perimeterAwareShortCollectionRule(
      shortCollectionId: $shortCollectionId
      salesPointId: $salesPointId
      reservationId: $reservationId
    ) {
      id
      shortCollectionId
      stamps
      stampsContribution
      points
      pointsContribution
      contribution
      stampsPointsContribution
    }
  }
`

export const QUERY_GET_COOP_ZONE = gql`
  query GetCoopZone($id: ID!) {
    coopZone(id: $id) {
      id
      name
      coopArea {
        id
        name
      }
    }
  }
`

export const QUERY_GET_SERVICE_LOGS = gql`
  query GetServiceLogs($filters: JSONObject, $sort: ServiceLogSortInput, $pagination: PaginationInputType) {
    serviceLogs(filters: $filters, sort: $sort, pagination: $pagination) {
      total
      data {
        id
        userId
        category
        action
        data
        createdAt
        date: createdAt
      }
    }
  }
`
