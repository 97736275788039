import { Badge, Chip, createStyles, makeStyles, Typography } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import React, { FC, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { QUERY_GET_ALL_COOP_AREAS } from '../../../queries'
import delve from 'dlv'

type Props = {
  title?: string
  source: string
  color?: string
}

export const PerimeterAreaSummary: FC<Props> = ({ title = 'Aree selezionate:', source, ...props }) => {
  const classes = useStyles(props)
  const { values } = useFormState()
  const { loading, data } = useQuery(QUERY_GET_ALL_COOP_AREAS)
  const parsedSource = useMemo(() => source.replaceAll('[', '.').replaceAll(']', ''), [source])

  const selectedItems = useMemo(() => {
    if (
      delve(values, parsedSource)?.length > 0 &&
      data?.coopAreas?.data &&
      !loading &&
      data.coopAreas.data.length > 0
    ) {
      return delve(values, parsedSource)
        .map((item: string) => data.coopAreas.data.find((area: any) => area.id === item))
        .filter((item: any) => !!item)
    }

    return []
  }, [data?.coopAreas?.data, values, loading, parsedSource])

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>

      <div className={classes.chipsContainer}>
        {selectedItems.length <= 0 && (
          <Typography variant="body2" className={classes.emptyLabel}>
            Nessuna Area selezionata
          </Typography>
        )}
        {selectedItems.map((item: any, index: number) => {
          return <Chip size="small" key={`coop-area-badge-${item.id}`} label={item.name} className={classes.chip} />
        })}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
      marginRight: theme.spacing(20),
      marginTop: theme.spacing(4),
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    chipsContainer: {
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    chip: (props: any): any => ({
      color: theme.palette.common.black,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: 12,
      textTransform: 'none',
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.divider,
      border: '1px solid',
    }),
    emptyLabel: {
      opacity: 0.6,
    },
  })
)
