/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery, Theme, Fade } from '@material-ui/core'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { useTranslate, DashboardMenuItem } from 'react-admin'
import SubMenu from './SubMenu'
import MenuItemLink from './MenuItemLink'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { IoMdSettings } from 'react-icons/io'
import { BsCircleFill, BsSquareFill } from 'react-icons/bs'

import {
  FaEllipsisV,
  FaChartLine,
  FaFileAlt,
  FaGifts,
  FaUsers,
  FaShoppingBasket,
  FaUser,
  FaCogs,
  FaStore,
  FaBox,
} from 'react-icons/fa'
import AccountMoreMenu from '../AccountMoreMenu'
import { usePermissions } from 'ra-core'
import { hasPermission } from '../../utils/hasPermission'
import { JobsQueueInfoAlert } from '../jobs-queue/JobsQueueInfoAlert'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 45,
    height: 45,
    transition: theme.transitions.create(['width', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderRadius: '4px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.secondary.dark,
  },
  closedAvatar: {
    width: 32,
    height: 32,
  },
  name: {
    transition: theme.transitions.create(['opacity', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  role: {
    transition: theme.transitions.create(['opacity', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuContainer: {
    overflowX: 'hidden',
    marginLeft: theme.spacing(3),
  },
  userContainer: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  closedSidebarUserInfo: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },
  menuTitle: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(5),
  },
  truncatedText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '125px',
  },
}))

interface Props {
  dense: boolean
  logout: () => void
  onMenuClick: () => void
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout, ...rest }) => {
  const classes = useStyles()
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
  const translate = useTranslate()
  useSelector((state: any) => state.theme) // force rerender on theme change
  const avatarClassName = useMemo(() => clsx(classes.avatar, { [classes.closedAvatar]: !open }), [open])
  const usernameClassName = useMemo(() => clsx(classes.name, { [classes.closedSidebarUserInfo]: !open }), [open])
  const userRoleClassName = useMemo(() => clsx(classes.role, { [classes.closedSidebarUserInfo]: !open }), [open])
  const { pathname } = useLocation()
  const { loaded, permissions } = usePermissions()

  return (
    <Fade in={loaded}>
      <Box height="100%" display="flex" flexDirection="column" displayPrint="none" mr={10}>
        <Box className={classes.userContainer} alignItems="center" display="flex" p={2}>
          <AccountMoreMenu logout={logout} />
          <Avatar
            variant="square"
            className={avatarClassName}
            src={'http://localhost:3000/static/images/avatars/avatar_6.png'}
          />
          <Box display="flex" flexDirection="column" ml={3} width="100%">
            <Typography className={clsx(usernameClassName, classes.truncatedText)} color="primary" variant="h5">
              {permissions && permissions.user && `${permissions.user.firstName} ${permissions.user.lastName}`}
            </Typography>

            <Typography color="textSecondary" variant="body2" className={userRoleClassName}>
              {permissions &&
                permissions.user &&
                permissions.user.roles &&
                permissions.user.roles.length > 0 &&
                `${permissions.user.roles[0].name}`}
            </Typography>
          </Box>
        </Box>
        {/* <Divider /> */}
        <Typography variant="h5" className={classes.menuTitle}>
          Menu
        </Typography>
        <Box className={classes.menuContainer}>
          <List>
            <MenuItemLink
              to="/"
              // primaryText={translate('menu.Dashboard', { smart_count: 2 })}
              primaryText="Dashboard"
              leftIcon={FaChartLine}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              isExactMatch
              // selected={pathname === '/Organization'}
            />

            <MenuItemLink
              to={'/Reservation'}
              // primaryText={translate('resources..name', { smart_count: 2 })}
              primaryText="Prenotazioni"
              leftIcon={FaFileAlt}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              showJobsQueueAlert
            />
            <MenuItemLink
              to={'/ProductReservation'}
              // primaryText={translate('resources..name', { smart_count: 2 })}
              primaryText="Prodotti Prenotati"
              leftIcon={FaFileAlt}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              showJobsQueueAlert
            />
            {hasPermission(permissions) ? (
              <MenuItemLink
                to={'/ProductStock'}
                primaryText="Giacenze"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaBox}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                showJobsQueueAlert
              />
            ) : (
              <MenuItemLink
                to={'/OperatorProductStock'}
                primaryText="Giacenze"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaBox}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {hasPermission(permissions) && (
              <MenuItemLink
                to={'/ShortCollection'}
                primaryText="Short Collections"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaGifts}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {hasPermission(permissions) && (
              <MenuItemLink
                to={'/SalesPoint'}
                primaryText="Punti Vendita"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaStore}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {hasPermission(permissions) && (
              <MenuItemLink
                to={'/Product'}
                primaryText="Prodotti"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaShoppingBasket}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {hasPermission(permissions) && (
              <MenuItemLink
                to={'/User'}
                primaryText="Utenti"
                // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                leftIcon={FaUsers}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}
            {hasPermission(permissions) && (
              <SubMenu sidebarIsOpen={open} name="menu.categories.settings" icon={IoMdSettings} dense={dense}>
                <MenuItemLink
                  to={'/CoopArea'}
                  primaryText="Aree"
                  // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                  leftIcon={BsSquareFill}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
                <MenuItemLink
                  to={'/CoopZone'}
                  primaryText="Zone"
                  // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                  leftIcon={BsCircleFill}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
                <MenuItemLink
                  to={'/logs-console'}
                  primaryText="Logs"
                  // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
                  leftIcon={BsCircleFill}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              </SubMenu>
            )}
            {/* <MenuItemLink
              to={'/Customer'}
              primaryText="Clienti"
              // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
              leftIcon={FaUser}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            /> */}
            {/* REMOVED MENU SETTINGS  
            
              <MenuItemLink
              to={'/settings'}
              primaryText="Impostazioni"
              // primaryText={translate('resources.PlannedEvent.name', { smart_count: 2 })}
              leftIcon={FaCogs}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
          /> */}
          </List>
        </Box>
      </Box>
    </Fade>
  )
}

const MenuAuthWrapper: FC<Props> = (props) => {
  const location = useLocation()

  if (location.pathname === '/callback') {
    return null
  }

  return <Menu {...props} />
}

export default MenuAuthWrapper
