import { Box, Card, CircularProgress, Container, Fade, makeStyles, Paper } from '@material-ui/core'
import React, { FC } from 'react'
import { MdSettings } from 'react-icons/md'
import ReservationList from '../../resources/Reservation/ReservationList'
import StatCard from '../StatCard'
import Title from '../layout/Title'
import classnames from 'classnames'
import { FaCog, FaCogs, FaEnvelope, FaGifts, FaChartLine, FaFileAlt } from 'react-icons/fa'
import BaseButton from '../button/BaseButton'
import ListHeader from '../list/ListHeader'

type Props = {
  //
}

const Settings: FC<Props> = () => {
  const classes = useStyles()
  return (
    <Fade in={true}>
      <Container maxWidth={false} className={'settings-page'}>
        <Box pt={11} pl={3}>
          <Title title="Impostazioni" icon={<FaCogs />} />
          <Box display="flex" textAlign="center" mt={5}>
            <Box flex="1">
              <Paper>Settings</Paper>
            </Box>
            <Box width="240px" pl={3} display="flex" flexDirection="column">
              <Paper>Info contatti</Paper>
              <Box mb={2} />
              <BaseButton startIcon={<FaEnvelope size="1rem" />} variant="contained" color="primary" size="large">
                Contatta
              </BaseButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Fade>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
}))

export default Settings
