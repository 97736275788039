import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import Typography from '@material-ui/core/Typography'
import PermissionItem from './PermissionItem'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useNotify } from 'ra-core'
import Loading from '../../components/Loading'
import { Avatar, Box, Card, CardContent, Divider, Grid } from '@material-ui/core'
import { FaUsers } from 'react-icons/fa'
import { MdSave } from 'react-icons/md'

const MUTATION_UPDATE_PERMISSION = gql`
  mutation UpdatePermission($id: ID!, $data: PermissionUpdateInput!) {
    updatePermission(id: $id, data: $data) {
      id
    }
  }
`

export const QUERY_GET_PERMISSION_ROLES = gql`
  query GetRoleDetails {
    permissions(pagination: { disabled: true }, sort: { name: "ASC" }) {
      total
      data {
        id
        name
        roles {
          id
          name
        }
        category {
          id
          name
        }
      }
    }

    permissionCategories(pagination: { disabled: true }, sort: { name: "ASC" }) {
      data {
        id
        name
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  saveButton: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  userAvatar: {
    backgroundColor: '#eeeeee',
    color: 'rgba(51,51,51,0.5)',
    width: '60px',
    height: '60px',
    boxShadow: '0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05)',
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stickyColumn: {
    // width: '40%', // TODO: DELETE ME
    flexBasis: '50%',
    maxWidth: 375,
    position: 'sticky',
    top: 0,
  },
  rolesWrapper: {
    flex: '50%',
    marginLeft: theme.spacing(3),
    height: '100%',
  },
}))

type Props = {
  record?: any
}

const RoleDetails: FC<Props> = (props) => {
  const classes = useStyles(props)
  const { loading, data, error, refetch } = useQuery(QUERY_GET_PERMISSION_ROLES, {
    fetchPolicy: 'network-only',
  })
  const client = useApolloClient()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const notify = useNotify()
  const [activePermissions, setActivePermissions] = useState<Record<string, boolean> | undefined>(undefined)

  const permissionHashTable = useMemo(() => {
    if (!loading && data && data.permissions && data.permissions.data) {
      return data.permissions.data.reduce((acc: any, item: any) => {
        return {
          ...acc,
          [item.id]: item,
        }
      }, {})
    }

    return {}
  }, [data, loading])

  const categoriesPermissions = useMemo(() => {
    if (!loading && data?.permissionCategories?.data && data?.permissions?.data) {
      const orphanedPermissions = data.permissions.data.filter(
        (permission: any) => !permission.category || permission.category.name.length === 0
      )

      return [
        ...data.permissionCategories.data.map((permissionCategory: any) => {
          return {
            ...permissionCategory,
            permissions: data.permissions.data.filter((permission: any) => {
              return !!(permission.category.id === permissionCategory.id)
            }),
          }
        }),
        {
          id: 'NO_CATEGORY',
          name: 'Senza Categoria',
          permissions: orphanedPermissions,
        },
      ]
    }
    return []
  }, [data, loading])

  const handlePermissionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setActivePermissions({
        ...activePermissions,
        [event.target.name]: event.target.checked,
      })
    },
    [activePermissions]
  )

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true)
      if (!activePermissions) {
        throw new Error('Active permissions not set')
      }

      const results = await Promise.all(
        Object.keys(activePermissions).map((item: any) => {
          const roleIds = permissionHashTable[item].roles
            .map((r: any) => r.id)
            .filter((r: any) => r !== props.record.id)

          if (activePermissions[item]) {
            roleIds.push(props.record.id)
          } else {
            console.log('dovrebbe togliere', item, roleIds)
          }

          return client.mutate({
            mutation: MUTATION_UPDATE_PERMISSION,
            variables: {
              id: item,
              data: {
                roleIds,
              },
            },
          })
        })
      )
      refetch()
    } catch (e) {
      notify('ra.message.error', 'warning')
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }, [permissionHashTable, activePermissions, props.record])

  useEffect(() => {
    if (!loading && data.permissions && data.permissions.data && !activePermissions && props.record) {
      const activePermissions = data.permissions.data.filter((permission: any) => {
        return permission.roles && permission?.roles?.find((pR: any) => pR.id === props.record.id)
      })

      setActivePermissions(
        activePermissions.reduce((acc: any, item: any) => {
          return {
            ...acc,
            [item.id]: true,
          }
        }, {})
      )
    }
  }, [loading, data, activePermissions, props.record])

  if (loading || !activePermissions) {
    return <Loading />
  }

  return (
    <Box display="flex" height="100%" alignItems="flex-start">
      <div className={classes.stickyColumn}>
        <RoleDetailCard record={props.record} />
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            disabled={submitting}
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="large"
            className={classes.saveButton}
            startIcon={<MdSave />}
          >
            Salva
          </Button>
        </Box>
      </div>

      <div className={classes.rolesWrapper}>
        {categoriesPermissions.map((categoryPermission: any, index) => {
          return (
            <Box boxShadow={3} mb={3} p={8} bgcolor="background.paper" borderRadius={4}>
              <div key={`category-permission-${categoryPermission.id}`}>
                <Typography variant="h2">{categoryPermission.name}</Typography>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    {categoryPermission.permissions.map((permission: any, index: any) => {
                      return (
                        <Grid item xs={12} md={4}>
                          <PermissionItem
                            disabled={submitting}
                            key={`permission-item-${permission.id}`}
                            permission={permission}
                            name={permission.id}
                            checked={activePermissions && activePermissions[permission.id]}
                            onChange={handlePermissionChange}
                            variant="body2"
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </div>
            </Box>
          )
        })}
      </div>
    </Box>
  )
}

const RoleDetailCard: FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <Card>
      <CardContent className={classes.row}>
        <Avatar variant="rounded" className={classes.userAvatar}>
          <FaUsers size={30} />
        </Avatar>
        <div>
          <Typography variant="body1">Role:</Typography>
          <Typography variant="h4">{`${props.record.name}`}</Typography>
        </div>
      </CardContent>
      <Divider />
      <CardContent className={classes.rowSpaceBetween}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Is admin:</Typography>
          <Typography variant="body2">{props.record.isAdmin ? 'Yes' : 'No'}</Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardContent className={classes.row}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Is blocked</Typography>
          <Typography variant="body2">{props.record.isBlocked ? 'Yes' : 'No'}</Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardContent className={classes.row}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Is Public</Typography>
          <Typography variant="body2">{props.record.isPublic ? 'Yes' : 'No'}</Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default RoleDetails
