import React, { FC, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AutocompleteInput, ReferenceInput } from 'ra-ui-materialui'
import { useForm, useFormState } from 'react-final-form'
import { useApolloClient } from '@apollo/client'
import { QUERY_GET_COOP_ZONE } from '../queries'

const filterToQuery = (name: string): Record<string, any> => ({ name })

export const SalesPointZoneInput: FC<any> = (props) => {
  const classes = useStyles()
  const client = useApolloClient()
  const { values } = useFormState()
  const { change } = useForm()

  useEffect(() => {
    const manageNewZone = async (): Promise<void> => {
      try {
        if (values.coopZoneId) {
          const { data } = await client.query({
            query: QUERY_GET_COOP_ZONE,
            variables: {
              id: values.coopZoneId,
            },
          })

          if (data?.coopZone?.coopArea?.id) {
            change('coopAreaId', data.coopZone.coopArea.id)
          }
        }
      } catch (error) {
        console.log('error managing new zone', error)
      }
    }

    manageNewZone()
  }, [values?.coopZoneId])

  return (
    <ReferenceInput {...props} label="Zona" filterToQuery={filterToQuery} source="coopZoneId" reference="CoopZone">
      <AutocompleteInput dense={false} optionText="name" fullWidth />
    </ReferenceInput>
  )
}

const useStyles = makeStyles((theme) => ({}))
