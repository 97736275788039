import React, { FC, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Form, FormRenderProps, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { mergeInitialValuesWithDefaultValues } from 'ra-ui-materialui/lib/list/filter/FilterForm'
import clsx from 'clsx'
import BaseButton from '../../button/BaseButton'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    form: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    clearFix: { clear: 'right' },
    setFiltersButton: {
      marginTop: theme.spacing(8),
    },
  }),
  { name: 'HyInlineFilterForm' }
)

const DrawerFilterForm: FC<{ [x: string]: any }> = ({
  classes = {},
  className,
  resource,
  margin,
  variant,
  filters,
  displayedFilters = {},
  hideFilter,
  initialValues,
  onCloseDrawer,
  ...rest
}) => {
  const hiddenFilters = useMemo(() => {
    return filters.filter((item: any) => !item.props.alwaysOn)
  }, [filters])

  const handleSetFilters = useCallback(() => {
    if (rest.setFilters) {
      rest.setFilters(rest.values)
      if (onCloseDrawer) {
        onCloseDrawer()
      }
    }
  }, [rest.values])

  return (
    <form className={clsx(className, classes.form)} {...sanitizeRestProps(rest)} onSubmit={handleSubmit}>
      {hiddenFilters.map((filterElement: any, index: number) => {
        return (
          <Box display="flex" flexDirection="column" width="100%" mb={2} key={`filter-elem-${index}`}>
            {React.cloneElement(filterElement, {
              allowEmpty: filterElement.props.allowEmpty === undefined ? true : filterElement.props.allowEmpty,
              resource,
              record: emptyRecord,
              variant,
              margin,
              helperText: false,
              fullWidth: true,
              // ignore defaultValue in Field because it was already set in Form (via mergedInitialValuesWithDefaultValues)
              defaultValue: undefined,
            })}
          </Box>
        )
      })}
      <Box display="flex" flexDirection="column" width="100%">
        <BaseButton
          onClick={handleSetFilters}
          className={classes.setFiltersButton}
          color="primary"
          size="large"
          fullWidth
          variant="contained"
        >
          Salva Filtri
        </BaseButton>
      </Box>
    </form>
  )
}

const EnhancedDrawerFilterForm: FC<{ [x: string]: any }> = (props) => {
  const classes = useStyles(props)
  const mergedInitialValuesWithDefaultValues = mergeInitialValuesWithDefaultValues(props as any)

  return (
    <Form
      onSubmit={handleFinalFormSubmit}
      initialValues={mergedInitialValuesWithDefaultValues}
      mutators={{ ...arrayMutators }}
      render={(formProps: FormRenderProps): React.ReactNode => (
        <DrawerFilterForm classes={classes} {...formProps} {...props} />
      )}
    />
  )
}

const emptyRecord = {}

const handleSubmit = (event: any): boolean => {
  event.preventDefault()
  return false
}

const handleFinalFormSubmit = (): void => {
  //
}

const sanitizeRestProps = ({
  active,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  error,
  errors,
  filterValues,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  invalid,
  modified,
  modifiedSinceLastSubmit,
  pristine,
  setFilters,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touched,
  valid,
  validating,
  values,
  visited,
  ...props
}: any): any => props

export default EnhancedDrawerFilterForm
