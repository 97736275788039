import React, { FC } from 'react'
import RAList from '../../components/list/List'
import { TextField, NumberField } from 'react-admin'

import Datagrid from '../../components/Datagrid'
import CreateButton from '../../components/button/CreateButton'
import { FaStore } from 'react-icons/fa'

import GenericMoreMenu from '../../components/GenericMoreMenu'
import FilterTextInput from '../../components/FilterTextInput'
import Filter from '../../components/list/filter/Filter'
import SalesPointAreaField from '../../components/CustomFields/SalesPointAreaField'
import { Pagination } from '../../components/list/pagination'
import SalesPointAreaInput from '../../components/SalesPointAreaInput'
import { ReferenceField } from 'ra-ui-materialui'

type Props = {
  //
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SalesPointFilters: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <FilterTextInput label="Cerca" source="name" alwaysOn />
      <SalesPointAreaInput label="Seleziona l'area" />
    </Filter>
  )
}

const SalesPointList: FC<Props> = (props) => {
  return (
    <RAList
      {...props}
      title="Punti Vendita"
      titleIcon={<FaStore />}
      actions={<CreateButton label="Nuovo P.V." />}
      filters={<SalesPointFilters variant="outlined" />}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="name" />
        <ReferenceField link={false} label="Zona" source="coopZoneId" reference="CoopZone">
          <TextField source="name" />
        </ReferenceField>
        <SalesPointAreaField source="coopAreaId" />
        <GenericMoreMenu />
      </Datagrid>
    </RAList>
  )
}

export default SalesPointList
