import React, { FC } from 'react'
import { AutocompleteInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Box, TextField, Typography } from '@material-ui/core'
import { FaSearch } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
  helper: {
    width: '50%',
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
  },
  root: {
    borderWidth: 0,
    borderColor: 'transparent',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    paddingLeft: theme.spacing(3),
    height: '45px',
    backgroundColor: theme.palette.grey[200],
    '& .MuiFormControl-root': {
      margin: 0,
    },
    '& .MuiFormHelperText-root ': {
      display: 'none',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent !important',
      width: '230px',
    },
    '& .MuiFilledInput-input': {
      height: '16px',
      paddingTop: '20px',
      paddingRight: 0,
    },
    '& .MuiInputLabel-filled': {
      top: '-3px',
    },
    '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
      display: 'none',
    },
  },
  textInput: {
    margin: 0,
  },
  inputRoot: {
    backgroundColor: 'black !important',
    borderColor: 'transparent',
    borderWidth: 0,
    height: '45px',
    margin: 0,
    '& input': {
      padding: '18px 12px 10px',
    },
    '&::before,&::after': {
      display: 'none !important',
    },
  },
  input: {
    backgroundColor: '#eeeeee !important',

    '&  input': {
      display: 'none',
    },
    '& + fieldset': {
      backgroundColor: 'transparent !important',
      borderWidth: 0,
    },
  },
  label: {
    top: '-5px',
  },
  helperText: {
    display: 'none',
  },
  iconWrapper: {
    display: 'flex',
  },
  icon: {
    display: 'flex',
    color: theme.palette.text.primary,
    fontSize: '1rem',
  },
  focused: {},
  disabled: {},
}))

type Props = {
  [x: string]: any
}

const SearchAutoCompleteInput: FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <Box display="flex" className={classes.root}>
      <Box className={classes.iconWrapper} alignItems="center" justifyContent="center">
        <FaSearch className={classes.icon} />
      </Box>
      <AutocompleteInput
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.textInput,
            formControl: classes.inputRoot,
          },
        }}
        InputLabelProps={{ classes: { root: classes.label } }}
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        className={classes.input}
        helperText={<></>}
        {...props}
        variant="filled"
      />
    </Box>
  )
}

export default SearchAutoCompleteInput
