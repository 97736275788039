import { FC } from 'react'
import { Alert, AlertIcon, CloseButton, useDisclosure } from '@chakra-ui/react'
import { useShortCollections } from '../hooks/useShortCollections'

export const ShortCollectionWarning: FC = () => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })

  const { isEmpty } = useShortCollections()

  if (!isEmpty || !isVisible) {
    return null
  }

  return (
    <Alert status="error" variant="solid" justifyContent="center">
      <AlertIcon />
      Al momento non sono presenti short collection attive, siete pregati di non aprire ticket, ma di chiedere
      informazioni all’ufficio fidelizzazione
      <CloseButton onClick={onClose} ml="4" />
    </Alert>
  )
}
