import React, { FC, useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { FaCircle } from 'react-icons/fa'
import { ProductReservationStatus } from '../types'
import { StatusInfoLabelDefaultColors } from './StatusInfoLabel'
import { useDataProvider, useNotify, usePermissions, useRefresh } from 'ra-core'
import { hasPermission } from '../utils/hasPermission'
import { ProductReservationStatusColors, ProductReservationStatusEnum } from '../customEnums'

const useStyles = makeStyles((theme) => ({
  iconMore: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 32,
    height: 32,
  },
}))

type Props = {
  resource?: string
  record?: any
  source?: string
}

const ProductReservationUpdateStatusField: FC<Props> = ({ resource, record, source = 'productReservationStatus' }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const { loaded, permissions } = usePermissions()
  const isOperatorUser = useMemo(() => {
    if (permissions) {
      return !hasPermission(permissions)
    }
    return false
  }, [permissions])
  const currentStatus = useMemo(() => (record && source && record[source] ? record[source] : undefined), [
    record,
    source,
  ])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleUpdateStatus = useCallback(
    async (newStatus: string) => {
      try {
        const result = await dataProvider.update('ProductReservation', {
          id: record.id,
          previousData: {
            ...record,
          },
          data: {
            productReservationStatus: newStatus,
          },
        })
        refresh()
      } catch (error) {
        console.error('Error in handleUpdateStatus()', error)
        notify('ra.message.error', 'error')
      } finally {
        setAnchorEl(null)
      }
    },
    [record]
  )

  const handleSetWaitingStatus = useCallback(() => handleUpdateStatus(ProductReservationStatus.WAITING.toString()), [
    handleUpdateStatus,
  ])

  const handleSetWaitingForAvailableNotificationStatus = useCallback(
    () => handleUpdateStatus(ProductReservationStatus.WAITING_FOR_AVAILABLE_NOTIFICATION.toString()),
    [handleUpdateStatus]
  )

  const handleSetCustomerNotifiedStatus = useCallback(
    () => handleUpdateStatus(ProductReservationStatus.CUSTOMER_NOTIFIED.toString()),
    [handleUpdateStatus]
  )

  const handleSetDeliveredStatus = useCallback(
    () => handleUpdateStatus(ProductReservationStatus.DELIVERED.toString()),
    [handleUpdateStatus]
  )

  const handleSetWaitingForSorryNotificationStatus = useCallback(
    () => handleUpdateStatus(ProductReservationStatus.WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION.toString()),
    [handleUpdateStatus]
  )

  const onMoreClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const onMoreClose = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  return (
    <>
      <IconButton
        disabled={
          isOperatorUser &&
          currentStatus !== ProductReservationStatus.CUSTOMER_NOTIFIED &&
          currentStatus !== ProductReservationStatus.WAITING_FOR_AVAILABLE_NOTIFICATION
        }
        className={classes.iconMore}
        edge="start"
        onClick={onMoreClick}
      >
        <KeyboardArrowDownIcon />
      </IconButton>

      <Menu
        id={`update-${resource}-${record.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
      >
        {isOperatorUser ? (
          currentStatus === ProductReservationStatus.CUSTOMER_NOTIFIED ||
          currentStatus === ProductReservationStatus.WAITING_FOR_AVAILABLE_NOTIFICATION ? (
            <MenuItem button onClick={handleSetDeliveredStatus}>
              <ListItemIcon>
                <FaCircle size="0.6rem" color={ProductReservationStatusColors.DELIVERED} />
              </ListItemIcon>
              {ProductReservationStatusEnum.DELIVERED}
            </MenuItem>
          ) : (
            <MenuItem>
              <ListItemIcon>
                <FaCircle size="0.6rem" color={StatusInfoLabelDefaultColors.INACTIVE} />
              </ListItemIcon>
              Nessuna operazione disponibile
            </MenuItem>
          )
        ) : (
          <>
            <MenuItem button onClick={handleSetWaitingStatus}>
              <ListItemIcon>
                <FaCircle size="0.6rem" color={ProductReservationStatusColors.WAITING} />
              </ListItemIcon>
              {ProductReservationStatusEnum.WAITING}
            </MenuItem>
            <MenuItem button onClick={handleSetWaitingForAvailableNotificationStatus}>
              <ListItemIcon>
                <FaCircle size="0.6rem" color={ProductReservationStatusColors.WAITING_FOR_AVAILABLE_NOTIFICATION} />
              </ListItemIcon>
              {ProductReservationStatusEnum.WAITING_FOR_AVAILABLE_NOTIFICATION}
            </MenuItem>
            <MenuItem button onClick={handleSetCustomerNotifiedStatus}>
              <ListItemIcon>
                <FaCircle size="0.6rem" color={ProductReservationStatusColors.CUSTOMER_NOTIFIED} />
              </ListItemIcon>
              {ProductReservationStatusEnum.CUSTOMER_NOTIFIED}
            </MenuItem>
            <MenuItem
              button
              onClick={handleSetDeliveredStatus}
              disabled={
                currentStatus !== ProductReservationStatus.CUSTOMER_NOTIFIED &&
                currentStatus !== ProductReservationStatus.WAITING_FOR_AVAILABLE_NOTIFICATION
              }
            >
              <ListItemIcon>
                <FaCircle size="0.6rem" color={ProductReservationStatusColors.DELIVERED} />
              </ListItemIcon>
              {ProductReservationStatusEnum.DELIVERED}
            </MenuItem>
            <MenuItem button onClick={handleSetWaitingForSorryNotificationStatus}>
              <ListItemIcon>
                <FaCircle
                  size="0.6rem"
                  color={ProductReservationStatusColors.WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION}
                />
              </ListItemIcon>
              {ProductReservationStatusEnum.WAITING_FOR_NOT_MORE_AVAILABLE_NOTIFICATION}
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  )
}

export default ProductReservationUpdateStatusField
