import { Tooltip } from '@material-ui/core'
import { motion } from 'framer-motion'
import React, { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { jobsQueueCount } from '../../utils/jobs-queue-state'

export const JobsQueueInfoAlert: FC = (props) => {
  const queueLength = useRecoilValue(jobsQueueCount)

  if (queueLength === 0) {
    return null
  }

  return (
    <Tooltip title={'Assegnazione dei premi in corso'} placement="right">
      <motion.div
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          content: '',
          backgroundColor: '#ed1d23',
          position: 'absolute',
          right: '12px',
        }}
        animate={{
          opacity: [1, 0.2],
        }}
        transition={{
          duration: 0.7,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />
    </Tooltip>
  )
}
