import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'

const { REACT_APP_API_GATEWAY_URI } = process.env

export const client = new ApolloClient({
  link: ApolloLink.from([
    // httpLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),

    createUploadLink({
      uri: REACT_APP_API_GATEWAY_URI,
      credentials: 'include',
      // headers: {
      //   'Apollo-Require-Preflight': 'true',
      // },
    }),
  ]),
  // link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
