import React, { FC, useMemo } from 'react'
import { Chip, createStyles, makeStyles, Typography } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import { useQuery } from '@apollo/client'
import { QUERY_GET_COOP_ZONES } from '../../../queries'
import delve from 'dlv'

type Props = {
  title?: string
  source: string
  areaSource: string
  color?: string
}

export const PerimeterZoneSummary: FC<Props> = ({ title = 'Zone selezionate:', areaSource, source, ...props }) => {
  const classes = useStyles(props)
  const { values } = useFormState()
  const parsedSource = useMemo(() => source.replaceAll('[', '.').replaceAll(']', ''), [source])

  const selectedIds = useMemo(() => {
    const foundedValue = delve(values, parsedSource)
    if (foundedValue?.length > 0) {
      return foundedValue
    }

    return []
  }, [values, parsedSource])

  const slicedIds = useMemo(() => selectedIds.slice(0, 5) || [], [selectedIds])

  const { loading, data } = useQuery(QUERY_GET_COOP_ZONES, {
    variables: {
      pagination: {
        disabled: true,
      },
      filters: {
        ids: slicedIds,
      },
      sort: {
        name: 'ASC',
      },
    },
    skip: !slicedIds || slicedIds.length <= 0,
  })

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>

      <div className={classes.chipsContainer}>
        {selectedIds.length <= 0 && (
          <Typography variant="body2" className={classes.emptyLabel}>
            Nessuna Zona selezionata
          </Typography>
        )}
        {data?.coopZones?.data?.map((item: any, index: number) => {
          return <Chip size="small" key={`coop-area-badge-${item.id}`} label={item.name} className={classes.chip} />
        })}
        {slicedIds.length < selectedIds.length && (
          <Chip
            className={classes.andMoreChip}
            size="small"
            label={`...e altri ${selectedIds.length - slicedIds.length}`}
          />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
      marginRight: theme.spacing(20),
      marginTop: theme.spacing(4),
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    chipsContainer: {
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    chip: (props: any): any => ({
      color: theme.palette.common.black,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: 12,
      textTransform: 'none',
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.divider,
      border: '1px solid',
      marginRight: theme.spacing(2),
    }),
    andMoreChip: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: 12,
      textTransform: 'none',
      borderColor: theme.palette.divider,
      border: '1px solid',
      marginRight: theme.spacing(2),
    },
    emptyLabel: {
      opacity: 0.6,
    },
  })
)
