import React, { Children, cloneElement, FC, isValidElement } from 'react'
import PropTypes from 'prop-types'
import { CreateControllerProps, useCreateContext } from 'ra-core'
import { Card, Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { CreateProps } from 'ra-ui-materialui'

export const CreateView: FC<CreateViewProps> = (props) => {
  const { actions, aside, children, classes: classesOverride, className, component, ...rest } = props
  const Content = component || Card
  const classes = useStyles(props)

  const { basePath, hasList, record, redirect, resource, save, saving, version, loaded } = useCreateContext(props)

  return (
    <Fade in={loaded}>
      <div className={classnames('create-page', classes.root, className)} {...sanitizeRestProps(rest)}>
        {/* <TitleForRecord title={title} record={record} defaultTitle={defaultTitle} /> */}
        {actions &&
          cloneElement(actions, {
            basePath,
            resource,
            hasList,
            //  Ensure we don't override any user provided props
            ...actions.props,
          })}
        <div
          className={classnames(classes.main, {
            [classes.noActions]: !actions,
          })}
        >
          <Content className={classes.card}>
            {children &&
              cloneElement(Children.only(children as any), {
                basePath,
                record,
                redirect:
                  typeof (children as any).props.redirect === 'undefined' ? redirect : (children as any).props.redirect,
                resource,
                save,
                saving,
                version,
              })}
          </Content>
          {aside &&
            cloneElement(aside, {
              basePath,
              record,
              resource,
              save,
              saving,
              version,
            })}
        </div>
      </div>
    </Fade>
  )
}

interface CreateViewProps extends CreateProps, Omit<CreateControllerProps, 'resource'> {}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(10),
    },
    main: {
      display: 'flex',
    },
    noActions: {},
    card: {
      flex: '1 1 auto',
      maxWidth: '100%',
    },
  }),
  { name: 'RaCreate' }
)

const sanitizeRestProps = ({
  basePath = null,
  defaultTitle = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  history = null,
  loaded = null,
  loading = null,
  location = null,
  match = null,
  onFailure = null,
  onSuccess = null,
  options = null,
  permissions = null,
  save = null,
  saving = null,
  setOnFailure = null,
  setOnSuccess = null,
  setTransform = null,
  transform = null,
  ...rest
}: any): any => rest
