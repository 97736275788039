import React, { FC, useCallback, useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useFormState } from 'react-final-form'
import { useNotify, useRedirect } from 'ra-core'
import { Error, ReferenceField } from 'ra-ui-materialui'
import { WizardStepProps } from '../../components/forms/WizardSectionedForm/WizardStep'
import ChipField from '../../components/ChipField'
import { createPortal } from 'react-dom'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import ProductStockTableQuantityChooser from './ProductStockTableQuantityChooser'
import { Box, Card, makeStyles, Typography } from '@material-ui/core'
import Loading from '../../components/Loading'
import SaveButton from '../../components/button/SaveButton'
import { QUERY_GET_SHORT_COLLECTION } from '../../queries'
import Empty from '../../components/list/Empty'
import ProductGalleryField from '../../components/ProductGalleryField'

type Props = WizardStepProps & {
  //
}

const MUTATION_CREATE_INCOMING_STOCK = gql`
  mutation CreateIncomingStock($data: IncomingStockCreateInput!) {
    createIncomingStock(data: $data) {
      id
    }
  }
`

const QUERY_GET_STOCK_PRODUCTS_FROM_SHORT_COLLECTION = gql`
  query GetStockProductsFromShortCollection($filters: ProductFilterInput) {
    products(filters: $filters, pagination: { disabled: true }) {
      total
      offset
      data {
        id
        name
      }
    }
  }
`

const TitleBar: FC<{ currentStep?: string | number; [x: string]: any }> = ({
  currentStep,
  shortCollectionData,
  ...props
}) => {
  const { values } = useFormState()
  const element = document.getElementById('incoming-stock-title-bar')

  return element
    ? createPortal(
        <>
          <ChipField source="name" color="primary" record={shortCollectionData} />
          <ReferenceField
            {...props}
            basePath={'/ProductStock'}
            record={{ id: '', salesPointId: values.salesPointId }}
            source="salesPointId"
            reference="SalesPoint"
            link={false}
          >
            <ChipField source="name" />
          </ReferenceField>
        </>,
        element
      )
    : null
}

const CreateIncomingStockButton: FC<any> = (props) => {
  const element = document.getElementById('send-new-incoming-stock')
  const [saving, setSaving] = useState<boolean>(false)
  const client = useApolloClient()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const handleSave = useCallback(
    async (values, redirect) => {
      try {
        setSaving(true)
        const result = await client.mutate({
          mutation: MUTATION_CREATE_INCOMING_STOCK,
          variables: {
            data: {
              salesPointId: values.salesPointId,
              organizationId: values.organizationId,
              transportDocumentDate: values.transportDocumentDate,
              transportDocumentNumber: values.transportDocumentNumber,
              productStocks: Object.keys(props.productsQuantities)
                .filter((key) => props.productsQuantities[key] > 0)
                .map((key) => ({
                  productId: key,
                  quantity: props.productsQuantities[key],
                  shortCollectionId: props.shortCollectionId,
                })),
            },
          },
        })
        localStorage.removeItem(`_wizard_${props.resource}_create`)
        redirectTo('list', `/${props.resource}`, result.data.createIncomingStock.id)
      } catch (e) {
        notify((e as any).message, 'warning')
      } finally {
        setSaving(false)
      }
    },
    [props.productsQuantities]
  )

  if (element) {
    return createPortal(
      <SaveButton {...props} variant="contained" saving={saving} disabled={saving} onSave={handleSave} />,
      element
    )
  }

  return null
}

const useStyles = makeStyles((theme) => ({
  root: {},
  rowEven: {
    backgroundColor: '#eeeeee',
  },
  rowOdd: {},
}))

const ProductsStockTable: FC<Props> = ({
  goToStep = (): void => {
    console.error('Method not implemented')
  },
  ...rest
}) => {
  const classes = useStyles(rest)
  const client = useApolloClient()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>(undefined)
  const [shortCollectionData, setShortCollectionData] = useState<any>(undefined)
  const [productsData, setProductsData] = useState<any>(undefined)
  const [productsQuantities, setProductQuantities] = useState<Record<string, number>>({})
  const { values } = useFormState()

  const handleProductQuantityChange = useCallback(
    (productId: string) => (quantity: number): void => {
      setProductQuantities({
        ...productsQuantities,
        [productId]: quantity,
      })
    },
    [productsQuantities]
  )

  useEffect(() => {
    const fetchShortCollections = async (): Promise<void> => {
      if (values.shortCollectionId) {
        try {
          setLoading(true)
          const shortCollectionResult = await client.query({
            query: QUERY_GET_SHORT_COLLECTION,
            variables: {
              id: values.shortCollectionId,
            },
          })
          setShortCollectionData(shortCollectionResult.data.shortCollection)

          const productsResult = await client.query({
            query: QUERY_GET_STOCK_PRODUCTS_FROM_SHORT_COLLECTION,
            variables: {
              filters: {
                shortCollectionId: values.shortCollectionId,
              },
            },
          })
          setProductsData(productsResult.data.products.data)
          setProductQuantities(
            productsResult.data.products.data.reduce((acc: any, item: any) => {
              return {
                ...acc,
                [item.id]: 0,
              }
            }, {})
          )
        } catch (e) {
          console.error(e)
          setError(e)
          // notify('ra.message.error', 'error')
        } finally {
          setLoading(false)
        }
      } else {
        goToStep(1, true)
      }
    }

    fetchShortCollections()
  }, [values])

  if (loading || !values.shortCollectionId) return <Loading />

  if (error) return <Error error={error} />

  return (
    <>
      <TitleBar shortCollectionData={shortCollectionData} />
      <CreateIncomingStockButton
        {...rest}
        shortCollectionId={shortCollectionData.id}
        productsQuantities={productsQuantities}
      />
      {!productsData || (productsData && !productsData.length) ? (
        <Box>
          <Empty
            component={Card}
            buttonComponent={undefined}
            messageComponent={<Typography>La Short Collection selezionata non ha prodotti</Typography>}
          />
        </Box>
      ) : (
        <Card>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Foto</TableCell>
                  <TableCell>Prodotto</TableCell>
                  <TableCell>Quantità</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {productsData &&
                  productsData.length > 0 &&
                  productsData.map((item: any, index: any) => {
                    return (
                      <TableRow
                        className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                        key={`incoming-stock-product-${item.id}`}
                        hover
                      >
                        <TableCell>
                          <ProductGalleryField label="Immagini" record={item} />
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          <ProductStockTableQuantityChooser
                            value={productsQuantities[item.id]}
                            onChange={handleProductQuantityChange(item.id)}
                          />
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <pre>{JSON.stringify({ productsData, shortCollectionData }, null, 2)}</pre> */}
        </Card>
      )}
    </>
  )
}

export default ProductsStockTable
